import React, { useEffect, useState, useCallback } from "react";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const RequestMedicine = () => {
  const [u, setU] = useState([]);
  const { userInfo } = AuthUser();
  const initialFormData = {
    medicineName: "",
    strength: "",
    medicineFrom: "",
    companyName: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
      );
      const data = await response.json();
      setU(data?.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching user data");
      setLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${apiUrl}/RequestMedicine/addRequestMedicines`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, user: u }),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      setData(responseData);
      setError(null);
      resetForm();
    } catch (error) {
      setError("Error submitting request");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  return (
    <div className="pb-8 px-10 shadow-xl p-5 rounded-md">
      <div>
        <h1 className="text-xl text-primary font-thin">
          Request Your Medicine
        </h1>
      </div>
      <form className="mt-5" onSubmit={handleSubmit}>
        <input
          className="p-5 border w-full border-gray-400 rounded hover:border-black duration-300 outline-none "
          type="text"
          name="medicineName"
          placeholder="Medicine Name"
          value={formData.medicineName}
          onChange={handleChange}
        />
        <input
          className="p-5 border w-full border-gray-400 rounded hover:border-black duration-300 my-5 outline-none  "
          type="text"
          name="strength"
          placeholder="Strength"
          value={formData.strength}
          onChange={handleChange}
        />
        <input
          className="p-5 border w-full border-gray-400 rounded hover:border-black duration-300 outline-none  "
          type="text"
          name="medicineFrom"
          placeholder="Medicine From"
          value={formData.medicineFrom}
          onChange={handleChange}
        />
        <input
          className="p-5 border w-full border-gray-400 rounded hover:border-black duration-300 my-5 outline-none  "
          type="text"
          name="companyName"
          placeholder="Company Name"
          value={formData.companyName}
          onChange={handleChange}
        />
        <button
          className="uppercase border w-full p-2 font-semibold text-white bg-primary rounded text-lg outline-none"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit Request"}
        </button>
      </form>

      {error && <p className="text-red-500 mt-2">{error}</p>}

      {data && (
        <div className="mt-2">
          <p className="text-green-500">Request successful</p>
        </div>
      )}
    </div>
  );
};

export default RequestMedicine;
