import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import DeleteHook from "../../../Hooks/DeleteHook";
import { toast } from "react-toastify";
import PostHooks from "../../../Hooks/PostHooks";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyPrescription = () => {
  let date = moment().format("YYYY-MM-D");
  const [refetch, setRefetch] = useState(false);
  const { userInfo } = AuthUser();
  const [data, setData] = useState([]);
  const [img, setImg] = useState("");
  const [prescription, setPrescription] = useState([]);

  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    fetch(
      `${apiUrl}/prescription/specific/?fieldName=${"userId"}&&fieldValue=${
        data?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setPrescription(data?.data);
      });
  }, [userInfo, data, refetch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!img) {
      toast.error("please select a prescription");
    } else {
      await PostHooks(
        `${apiUrl}/prescription/addPrescriptions`,
        {
          prescription: img,
          userId: data?._id,
          userPhone: userInfo?.phone,
          district: data?.district,
          division: data?.division,
          upazila: data?.upazila,
          address: data?.address,
          user: data,
          date: date,
        },
        "prescription added !"
      );
      setImg("");
      setRefetch(!refetch);
    }
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setImg);
  };

  return (
    <div className="shadow-xl p-5 rounded-md">
      <div className="w-fit border-2 border-dotted rounded-md p-4 mx-auto">
        <div className="text-center">
          {img ? (
            <img className="w-14 rounded-md mx-auto" src={img} alt="img" />
          ) : (
            <Icon
              className="text-6xl text-center mx-auto cursor-pointer"
              icon="line-md:upload-loop"
            />
          )}
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            <label htmlFor="file-upload" className="relative cursor-pointer">
              <span>Drag and drop</span>
              <span className="text-primary"> or browse</span>
              <span> to upload</span>
              <input
                id="file-upload"
                name="prescription"
                onChange={handleChangeUploadImage}
                type="file"
                className="sr-only"
              />
            </label>
          </h3>
          <p className="mt-1 text-xs">PNG, JPG, GIF up to 10MB</p>
        </div>

        {img && (
          <button
            onClick={handleSubmit}
            className="bg-primary px-4 py-1 rounded-md hover:scale-105 duration-300 mt-5 block mx-auto text-white hover:cursor-pointer"
          >
            Submit
          </button>
        )}
      </div>

      <div className="grid grid-cols-3 gap-5 mt-5">
        {prescription.length === 0
          ? Array(6)
              .fill()
              .map((_, index) => (
                <div key={index}>
                  <Skeleton height={150} />
                </div>
              ))
          : prescription.map((p) => (
              <div
                key={p?._id}
                className="border-2 border-dotted w-full rounded-md relative"
              >
                <button
                  onClick={(e) => {
                    DeleteHook({
                      refetch,
                      setRefetch,
                      url: `${apiUrl}/prescription/deletePrescriptions/${p?._id}`,
                    });
                  }}
                  className="bg-red-500 rounded-full text-white absolute top-2 right-2 px-3 py-1 hover:scale-105 duration-300 hover:bg-red-400"
                >
                  X
                </button>
                <img className="p-2 w-full" src={p?.prescription} alt="img" />
              </div>
            ))}
      </div>

      {prescription.length === 0 && (
        <div className="flex justify-center gap-4 items-center mt-5">
          <Icon className="text-primary" icon="octicon:no-entry-24" />
          <p>No more saved prescription found.</p>
        </div>
      )}
    </div>
  );
};

export default MyPrescription;
