import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import DOMPurify from "dompurify";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LinearLoader from "../../../Components/Loader/LinearLoader";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Ratings = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
const AgeRanges = ["Child", "Adults", "Everyone"];

const AdminUpdateProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [img, setImg] = useState("");
  const editorRefs = {
    ingredients: useRef(null),
    usageInstructions: useRef(null),
    safetyInformation: useRef(null),
    nutritionalInformation: useRef(null),
    benefits: useRef(null),
    sideEffects: useRef(null),
    description: useRef(null),
    dosageForm: useRef(null),
  };

  const [formData, setFormData] = useState({
    productName: "",
    category: "",
    brandName: "",
    description: "",
    price: 0,
    stock: 0,
    countryOfOrigin: "",
    discount: 0,
    manufacturerAddress: "",
    ingredients: "",
    usageInstructions: "",
    safetyInformation: "",
    ageRange: "",
    nutritionalInformation: "",
    benefits: "",
    dosageForm: "",
    prescriptionRequired: false,
    sideEffects: "",
    rating: 0,
  });

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    fetch(`${apiUrl}/category/getCategorys`)
      .then((res) => res.json())
      .then((data) => setCategory(data?.data));
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${apiUrl}/products/specific-products?_id=${id}`
        );
        const data = await response.json();
        if (data.total > 0) {
          setFormData({
            ...data.products[0],
            discount: data.products[0].discount || 0,
            stock: data.products[0].stock || 0,
            price: data.products[0].price || 0,
            rating: data.products[0].rating || 0,
          });
          setImg(data.products[0].image || "");
        }
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: sanitizedValue,
    });
  };

  const handleJoditChange = (field, content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    setFormData({
      ...formData,
      [field]: sanitizedContent,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `${apiUrl}/products/products/${id}`;
    const data = { ...formData, image: img };
    try {
      setUpdating(true);
      await UpdateHooks(BASE_URL, data, true, "Product info Updated");
      toast.success("Product info Updated Successfully");
      setTimeout(() => {
        navigate(-1); // Go back to the previous page
      }, 1000);
    } catch (error) {
      toast.error("Failed to update product");
    } finally {
      setUpdating(false);
    }
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setImg);
  };

  return (
    <div>
      <ToastContainer />
      <div className="">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Update Product
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Update essential product for health. Act now to secure the latest items.
        </p>
      </div>
      {loading ? (
        <LinearLoader />
      ) : (
        <form
          onSubmit={handleUpdate}
          className="shadow-lg shadow-gray-300 px-8 py-10 rounded flex flex-col gap-4 text-left"
        >
          <div className="md:flex items-center">
            <div className="mb-1 w-full mr-0 md:mr-2">
              <label className="block mb-2 text-[13px] font-normal text-gray-900">
                Product Name
              </label>
              {loading ? <Skeleton height={35} /> : (
                <input
                  type="text"
                  name="productName"
                  value={formData?.productName}
                  onChange={handleChange}
                  className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                  placeholder="Product Name"
                />
              )}
            </div>
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Category
            </label>
            {loading ? <Skeleton height={35} /> : (
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 focus:border-blue-500"
              >
                <option value="" disabled>
                  Choose a category
                </option>
                {category?.map((cat) => (
                  <option key={cat?._id} value={cat?.name}>
                    {cat?.name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Brand Name
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="text"
                name="brandName"
                value={formData?.brandName}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Brand Name"
              />
            )}
          </div>

          <div className="mb-1 flex gap-3 items-center w-full">
            <div className="w-full">
              <label className="block mb-2 text-[13px] font-normal text-gray-900">
                Image
              </label>
              {loading ? <Skeleton height={35} /> : (
                <input
                  onChange={handleChangeUploadImage}
                  className="block w-full text-sm text-gray-900 rounded-lg cursor-pointer bg-[#F0FDF4] focus:outline-none p-2"
                  id="file_input"
                  type="file"
                />
              )}
            </div>
            {loading ? <Skeleton height={55} width={55} /> : (
              <img className="w-14 rounded-md" src={img} alt="product img"></img>
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Price
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="number"
                name="price"
                value={formData?.price}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Price"
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Stock
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="number"
                name="stock"
                value={formData?.stock}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Stock"
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Country of Origin
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="text"
                name="countryOfOrigin"
                value={formData?.countryOfOrigin}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Country of Origin"
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Discount (%)
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="number"
                name="discount"
                value={formData?.discount}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Discount percentage"
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Manufacturer Address
            </label>
            {loading ? <Skeleton height={35} /> : (
              <input
                type="text"
                name="manufacturerAddress"
                value={formData?.manufacturerAddress}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
                placeholder="Manufacturer Address"
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Ingredients
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.ingredients}
                value={formData.ingredients}
                onChange={(content) => handleJoditChange("ingredients", content)}
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Usage Instructions
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.usageInstructions}
                value={formData.usageInstructions}
                onChange={(content) =>
                  handleJoditChange("usageInstructions", content)
                }
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Safety Information
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.safetyInformation}
                value={formData.safetyInformation}
                onChange={(content) =>
                  handleJoditChange("safetyInformation", content)
                }
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Age Range
            </label>
            {loading ? <Skeleton height={35} /> : (
              <select
                name="ageRange"
                value={formData.ageRange}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 focus:border-blue-500"
              >
                <option value="" disabled>
                  Choose age range
                </option>
                {AgeRanges.map((ageRange) => (
                  <option key={ageRange} value={ageRange}>
                    {ageRange}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Nutritional Information
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.nutritionalInformation}
                value={formData.nutritionalInformation}
                onChange={(content) =>
                  handleJoditChange("nutritionalInformation", content)
                }
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Benefits
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.benefits}
                value={formData.benefits}
                onChange={(content) => handleJoditChange("benefits", content)}
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Dosage Form
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.dosageForm}
                value={formData.dosageForm}
                onChange={(content) => handleJoditChange("dosageForm", content)}
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Description
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.description}
                value={formData.description}
                onChange={(content) => handleJoditChange("description", content)}
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Prescription Required
            </label>
            {loading ? <Skeleton height={35} /> : (
              <select
                name="prescriptionRequired"
                value={formData.prescriptionRequired ? "Yes" : "No"}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    prescriptionRequired: e.target.value === "Yes",
                  })
                }
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 focus:border-blue-500"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Side Effects
            </label>
            {loading ? <Skeleton height={100} /> : (
              <JoditEditor
                ref={editorRefs.sideEffects}
                value={formData.sideEffects}
                onChange={(content) => handleJoditChange("sideEffects", content)}
              />
            )}
          </div>

          <div className="mb-1 w-full">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Rating
            </label>
            {loading ? <Skeleton height={35} /> : (
              <select
                name="rating"
                value={formData.rating}
                onChange={handleChange}
                className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 focus:border-blue-500"
              >
                <option value="" disabled>
                  Choose rating
                </option>
                {Ratings.map((rating) => (
                  <option key={rating} value={rating}>
                    {rating}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="text-center pt-3">
            <button
              className="bg-primary hover:bg-lightPrimary text-white py-2 rounded-lg text-lg w-fit px-8"
              type="submit"
            >
              {updating ? <LinearLoader /> : "Update Product!"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminUpdateProduct;
