import CatagoriesCarousel from "../Components/CatagoriesCarousel/CatagoriesCarousel";
import PharmacyRegister from "../Components/Pharmacry/PharmacyRegister";
import SpecialOffer from "../Components/Special Offer/SpecialOffer";
import Pharmaceutical from "../Components/UserDashboard/Pharmaceutical/Pharmaceutical";
import PharmaceuticalMedecineDetails from "../Components/UserDashboard/Pharmaceutical/PharmaceuticalMedecineDetails";
import AboutUs from "../Pages/AboutUs/AboutUs";
import BlogDetails from "../Pages/Blog/BlogDetails";
import BlogPage from "../Pages/Blog/BlogPage";
import Career from "../Pages/Career/Career";
import JobDetails from "../Pages/Career/JobDetails";
import Category from "../Pages/Category/Category";
import Chat from "../Pages/Chat/Chat";
import MyDoctor from "../Pages/Doctor/MyDoctor";
import Faq from "../Pages/Faq/Faq";
import Home from "../Pages/Home/Home";
import Privacy from "../Pages/Privacy/Privacy";
import NewProductDetails from "../Pages/ProductDetails/NewProductDetails";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import ReturnPolicy from "../Pages/ReturnPolicy/ReturnPolicy";
import Terms from "../Pages/Terms/Terms";
import Error from "../Payment/Error";
import Failed from "../Payment/Failed";
import Success from "../Payment/Success";

const PublicRoutes = [
  { path: "", Component: Home },
  { path: "home", Component: Home },
  { path: "aboutUs", Component: AboutUs },
  { path: "pharmacyregister", Component: PharmacyRegister },
  { path: "career", Component: Career },
  { path: "returnPolicy", Component: ReturnPolicy },
  { path: "terms", Component: Terms },
  { path: "faq", Component: Faq },
  { path: "special-offer", Component: SpecialOffer },

  { path: "privacy", Component: Privacy },
  { path: "productDetails/:id", Component: ProductDetails },
  { path: "product-details/:id", Component: NewProductDetails },

  { path: "product/:id", Component: CatagoriesCarousel },

  { path: "pharmaceutical", Component: Pharmaceutical },
  {
    path: "pharmaceutical-details/:id",
    Component: PharmaceuticalMedecineDetails,
  },

  // for payment
  { path: "payment/success", Component: Success },
  { path: "payment/error", Component: Error },
  { path: "payment/failed", Component: Failed },

  { path: "job-details/:id", Component: JobDetails },

  { path: "blogs", Component: BlogPage },
  { path: "/our-blog/:id", Component: BlogDetails },

  { path: "categories", Component: Category },
  
  { path: "doctor", Component: MyDoctor },
  { path: "chat", Component: Chat },
];

export default PublicRoutes;
