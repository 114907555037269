import React, { useEffect, useState } from "react";
import img1 from "../../Assets/dasboard-icon/total-order.png";
import img2 from "../../Assets/dasboard-icon/pending-order.png";
import img3 from "../../Assets/dasboard-icon/complete-order.png";
import img4 from "../../Assets/dasboard-icon/total-selling.png";
import axios from "axios";
import { toast } from "react-toastify";
import AuthUser from "../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerDashboardIndex = () => {
  const {userInfo}=AuthUser();
  const [u, setU] = useState([]);
  const [prescription, setPrescription] = useState([]); 
  const [filterOfflineOrder, setOfflineOrder] = useState([]); 
  const [order ,setOrder] = useState([]);


  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);


  useEffect(() => {
    async function fetchOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/order/specific?fieldName=sellerId&&fieldValue=${encodeURIComponent(
            u?._id
          )}`
        );

        setOrder(response?.data?.data);

      } catch (err) {
  toast.error(err.message);
      }
    }

    fetchOrder();
  }, [ u]);

  useEffect(() => {
    async function fetchOnlineOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/offlineOrders/specific?fieldName=seller._id&&fieldValue=${encodeURIComponent(
            u?._id
          )}&&fieldName2=orderType&&fieldValue2=online`
        );

        setPrescription(response?.data?.data);

      } catch (err) {
  toast.error(err.message);
      }
    }

    fetchOnlineOrder();
  }, [ u]);

  useEffect(() => {
    async function fetchOfflineOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/offlineOrders/specific?fieldName=seller._id&&fieldValue=${encodeURIComponent(
            u?._id
          )}&&fieldName2=orderType&&fieldValue2=offline`
        );

        setOfflineOrder(response?.data?.data);
     
      } catch (err) {
        toast.error(err.message);
        
      }
    }

    fetchOfflineOrder();
  }, [ u]);


  const TotalSellingGeneralOrder  = order.reduce((total,order)=> total + parseFloat(order.totalAmount),0)

  const TotalOfflineSelling = filterOfflineOrder.reduce(
    (total, order) => total + parseFloat(order.payableAmount),
    0
  );
  const TotalOnlineSelling = prescription.reduce(
    (total, order) => total + parseFloat(order.payableAmount),
    0
  );
  const TotalSelling = TotalOfflineSelling + TotalOnlineSelling + TotalSellingGeneralOrder;

  const OfflineTotalOrder = filterOfflineOrder?.length;
  const OnlineTotalOrder = prescription?.length;
  const TotalOrder = OfflineTotalOrder + OnlineTotalOrder + order?.length;

  return (
    <div className="">
      <div className=" ">
        <div>
          <h1 className="text-2xl font-semibold">
            Welcome to the Seller Dashboard
          </h1>
          <p className="mt-4 text-gray-500">
            Here is where you can manage all the functionalities of the Seller
            Dashboard.
          </p>
        </div>
      </div>

      <div className=" w-full md:max-w-6xl px-8">
        <div className="flex items-center flex-wrap  ">
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F0FDF4] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#B0D6D0]">
                <img className=" w-[25px] h-[25px] " src={img1} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Order
                </h3>
                <span className="text-sm text-lightTextColor">
                  {TotalOrder}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F0FDF4] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#B0D6D0]">
                <img className=" w-[25px] h-[25px] " src={img1} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total General Order
                </h3>
                <span className="text-sm text-lightTextColor">
                {order?.length}

                  
                </span>
              </div>
            </div>
          </div>

        
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F8FFEC] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#D9F99D]">
                <img className=" w-[25px] h-[25px] " src={img2} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Prescription Order
                </h3>
                <span className="text-sm text-lightTextColor">
                  {prescription?.length}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0  shadow-lg shadow-gray-300 gap-5  bg-[#F4FFEF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#BBF7D0]">
                <img className=" w-[25px] h-[25px] " src={img3} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Offline Order
                </h3>
                <span className="text-sm text-lightTextColor">
                  {OfflineTotalOrder}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F8FFEC] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#D9F99D]">
                <img className=" w-[25px] h-[25px] " src={img2} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Selling General Order
                </h3>
                <span className="text-sm text-lightTextColor">
                  {TotalSellingGeneralOrder?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]">
                <img className=" w-[25px] h-[25px] " src={img4} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Offline Selling
                </h3>
                <span className="text-sm text-lightTextColor">
                  {TotalOfflineSelling?.toFixed(2)} BDT
                </span>
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]">
                <img className=" w-[25px] h-[25px] " src={img4} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Prescription Selling
                </h3>
                <span className="text-sm text-lightTextColor">
                  {TotalOnlineSelling?.toFixed(2)} BDT
                </span>
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="md:mx-3 my-3 m-0   shadow-lg shadow-gray-300 gap-5  bg-[#F6F3FF] rounded-lg flex  items-center px-5 py-9">
              <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-[#DDD6FE]">
                <img className=" w-[25px] h-[25px] " src={img4} alt="" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-textColor ">
                  Total Selling
                </h3>
                <span className="text-sm text-lightTextColor">
                  {TotalSelling?.toFixed(2)} BDT
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDashboardIndex;
