import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const UserOrderOverview = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/order/getOrdersById/${id}`);
        const result = await response.json();
        setData(result?.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [id]);

  const delivered = data?.status === "delivered";
  const delivering = delivered || data?.status === "delivering";
  const confirm = delivered || delivering || data?.status === "confirm";
  const pending = delivered || delivering || confirm || data?.status === "pending";

  return (
    <>
      <div className="w-fit mb-2">
        <h2 className="text-lg font-semibold">Timeline</h2>
        <div className="h-1 w-[60%] border-2 border-dashed border-green-300"></div>
      </div>
      <div className="p-2">
        <div>
          <ol className="relative border-s-8 border-gray-100">
            <li className="mb-10 ms-8">
              <span className={`${pending ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {pending ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Order Placed" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? `Your order is successfully placed to Nirog Health Plus. Order id #${data?._id}` : <Skeleton count={2} />}
              </p>
            </li>
            <li className="mb-10 ms-8">
              <span className={`${pending ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {pending ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Processing" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? "We have received your order, our pharmacist will check and confirm shortly." : <Skeleton count={2} />}
              </p>
            </li>
            <li className="mb-10 ms-8">
              <span className={`${pending ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {pending ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Pending" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? "We have received your order, our pharmacist will check and confirm shortly." : <Skeleton count={2} />}
              </p>
            </li>
            <li className="mb-10 ms-8">
              <span className={`${confirm ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {confirm ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Confirm" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? "We have confirmed your order." : <Skeleton count={2} />}
              </p>
            </li>
            <li className="mb-10 ms-8">
              <span className={`${delivering ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {delivering ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Delivering" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? "Our delivery partner has picked up your order for delivering. It generally takes 1-5 days to deliver outside Dhaka." : <Skeleton count={2} />}
              </p>
            </li>
            <li className="mb-10 ms-8">
              <span className={`${delivered ? "bg-green-200" : "bg-red-100"} absolute flex items-center justify-center w-10 h-10 rounded-full -start-6 ring-white`}>
                {delivered ? <IoIosCheckmarkCircle className="text-green-600 text-2xl" /> : <MdOutlineDoNotDisturbOn className="text-red-600 text-2xl" />}
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                {data ? "Delivered" : <Skeleton width={100} />}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500">
                {data ? "You have received your order." : <Skeleton count={2} />}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default UserOrderOverview;
