import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import saveAs from "file-saver";
import Loading from "../../../Shared/Loading";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function SellerPendingPrescription() {
  const { userInfo } = AuthUser();
  const [prescription, setPrescription] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredPrescription, setFilteredPrescription] = useState([]); // State for filtered prescription

  // Fetch prescription data
  useEffect(() => {
    async function fetchPrescription() {
      try {
        const response = await axios.get(
          `${apiUrl}/prescription/specific?fieldName=${"district"}&&fieldValue=${userInfo?.district}&&fieldName2=${"division"}&&fieldValue2=${userInfo?.division}&&fieldName3=${"upazila"}&&fieldValue3=${userInfo?.upazila}&&fieldName4=${"status"}&&fieldValue4=${"pending"}`
        );
        setPrescription(response?.data?.data);
        setFilteredPrescription(response?.data?.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    if (userInfo?.district && userInfo?.division && userInfo?.upazila) {
      fetchPrescription();
    }
  }, [userInfo]);

  // Handle search term change
  const handleSearch = useCallback(() => {
    setFilteredPrescription(
      prescription?.filter((prescription) =>
        prescription?.user?.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [prescription, searchTerm]);

  // Run handleSearch whenever searchTerm changes
  useEffect(() => {
    handleSearch();
  }, [searchTerm, handleSearch]);

  if (loading) {
    return <Loading />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All prescription
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential prescription for health. Act now to secure the latest items
        </p>
      </div>
      
      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter prescription name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>
      
      <div className="mb-3">
        <span className="text-gray-700">
          Showing {filteredPrescription.length} Results
        </span>
      </div>
      
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">#</th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">Prescription</th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">Customer Details</th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">Customer Phone</th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">Date</th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">Action</th>
            </tr>
            {filteredPrescription.map((prescription, index) => (
              <tr key={prescription._id} className="shadow">
                <td className="h-16 px-6 text-sm text-slate-500">{index + 1}</td>
                <td className="px-6 text-sm flex items-center gap-5 text-slate-500">
                  <img
                    className="w-14 rounded-md shadow mt-5"
                    src={prescription?.prescription}
                    alt="img"
                  />
                  <Icon
                    onClick={() => saveAs(prescription?.prescription)}
                    className="text-4xl text-green-500 mt-5 cursor-pointer"
                    icon="line-md:download-outline-loop"
                  />
                </td>
                <td className="px-6 text-sm text-slate-500">
                  {prescription?.user?.name}
                  <br />
                  {prescription?.division}
                  <br />
                  {prescription?.district}
                  <br />
                  {prescription?.upazila}
                  <br />
                  {prescription?.address}
                </td>
                <td className="h-16 px-6 text-sm text-slate-500">{prescription?.userPhone}</td>
                <td className="h-16 px-6 text-sm text-slate-500">{prescription?.date}</td>
                <td className="h-16 px-6 text-lg text-secondary flex gap-2 items-center cursor-pointer">
                  <Link to={`/sellerDashboard/seler-view-prescription/${prescription._id}`}>
                    <button className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
                      View
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SellerPendingPrescription;
