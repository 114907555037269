import React, { useContext, useEffect, useState } from "react";
import MyContext from "../Context/MyContext";

const ItemsCard = ({ item, refresh, setRefresh }) => {
  const { refetch, setRefetch } = useContext(MyContext);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(item?.quantity);
  }, [item]);

  const handleQuantity = (action) => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder"));
    if (existingOrder) {
      const exist = existingOrder.find((i) => i?._id === item?._id);

      if (exist) {
        const filterOrder = existingOrder.filter(
          (item) => item._id !== exist?._id
        );

        if (action === "odd") {
          if (exist?.quantity === 1) {
            localStorage.setItem(
              "nirogOrder",
              JSON.stringify([...filterOrder])
            );
          } else {
            exist.quantity = exist.quantity - 1;
            localStorage.setItem(
              "nirogOrder",
              JSON.stringify([exist, ...filterOrder])
            );
            setQuantity(quantity - 1);
          }
        } else {
          exist.quantity = exist.quantity + 1;
          localStorage.setItem(
            "nirogOrder",
            JSON.stringify([exist, ...filterOrder])
          );
          setQuantity(quantity + 1);
        }
      }
    }
    setRefresh(!refresh);
  };

  const handelDelete = () => {
    const items = JSON.parse(localStorage.getItem("nirogOrder"));
    const findOrder = items?.find((i) => i._id === item?._id);
    const filterOrder = items?.filter((item) => item._id !== findOrder?._id);
    localStorage.setItem("nirogOrder", JSON.stringify([...filterOrder]));
    setRefetch(!refetch);
  };
  return (
    <div className="bg-white shadow m-2 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between">
      <img
        className="w-16 border border-primary rounded-full"
        src={item?.image}
        alt="item"
      ></img>

      <div className="flex justify-between w-full">
        <div className="space-y-0">
          <p className="text-xs -mb-1">{item?.productName?.slice(0, 15)}...</p>
          <p className="text-[11px] -mb-1">
            {item?.brandName?.slice(0, 15)}...
          </p>
          <div className="flex items-center text-xs">
            {Array.from({ length: Math.floor(item?.rating) }, (_, i) => (
              <span key={i} className="text-yellow-500">
                &#9733;
              </span>
            ))}
            <span className="text-slate-500">{item?.rating}</span>
          </div>
        </div>

        <div className="flex flex-col justify-between w-fit ">
          <div className="flex items-center gap-4 justify-end ">
            <p className="text-primary font-medium text-right text-sm">
              ৳ {item?.onePrice.toFixed(2)}
            </p>
            <button
              onClick={handelDelete}
              className="px-2.5 rounded-full bg-red-100"
            >
              x
            </button>
          </div>
          <div class="flex items-center  ">
            <button
              onClick={() => handleQuantity("odd")}
              class="border rounded-md px-2 mr-2"
            >
              -
            </button>
            <span class="text-center text-xs">{quantity}</span>
            <button
              onClick={() => handleQuantity("even")}
              class="border rounded-md  px-2 ml-2"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemsCard;
