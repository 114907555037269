import React, { lazy, Suspense, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import MyContext from "./Context/MyContext";
import { LoadingProvider } from "./Context/LoadingContext";
import LinearLoader from "./Components/Loader/LinearLoader";
import Bot from "./Components/Bot/Bot";
import Cart from "./Components/Cart/Cart";
import FloatingCart from "./Components/Cart/FloatingCart";

import PublicRoutes from "./Routes/PublicRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import UserRoutes from "./Routes/UserRoutes";
import SellerRoutes from "./Routes/SellerRoutes";

const Notfound = lazy(() => import("./Shared/Notfound"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Main = lazy(() => import("./Pages/Main/Main"));
const AdminDashboard = lazy(() => import("./Pages/AdminDashboard/AdminDashboard"));
const UserDashboard = lazy(() => import("./Pages/UserDashboard/UserDashboard"));
const UserDashboardIndex = lazy(() => import("./Components/UserDashboard/UserDashboardIndex"));
const Dashboard = lazy(() => import("./Pages/AdminDashboard/Dashboard/Dashboard"));
const SellerDashboard = lazy(() => import("./Pages/SellerDashboard/SellerDashboard"));
const SellerDashboardIndex = lazy(() => import("./Components/SellerDashboard/SellerDashboardIndex"));

function App() {
  const [openCart, setOpenCart] = useState(false);
  const [refetch, setRefetch] = useState(false);

  return (
    <LoadingProvider>
      <MyContext.Provider value={{ refetch, setRefetch, openCart, setOpenCart }}>
        <div className="main-navigation">
          <Bot />

          {/* Global Loader */}
          <Suspense fallback={<LinearLoader />}>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Main />}>
                <Route index element={<Home />} />
                {PublicRoutes.map(({ path, Component }, index) => (
                  <Route key={index} path={path} element={<Component />} />
                ))}
              </Route>

              {/* User dashboard routes */}
              <Route path="/userDashboard" element={<UserDashboard />}>
                <Route index element={<UserDashboardIndex />} />
                {UserRoutes.map(({ path, Component }, index) => (
                  <Route key={index} path={path} element={<Component />} />
                ))}
              </Route>

              {/* Seller routes */}
              <Route path="/sellerDashboard" element={<SellerDashboard />}>
                <Route index element={<SellerDashboardIndex />} />
                {SellerRoutes.map(({ path, Component }, index) => (
                  <Route key={index} path={path} element={<Component />} />
                ))}
              </Route>

              {/* Admin routes */}
              <Route path="/adminDashboard" element={<AdminDashboard />}>
                <Route index element={<Dashboard />} />
                {AdminRoutes.map(({ path, Component }, index) => (
                  <Route key={index} path={path} element={<Component />} />
                ))}
              </Route>

              <Route path="*" element={<Notfound />} />
            </Routes>
          </Suspense>

          {/* for toast 👇 */}
          <ToastContainer />

          {openCart ? (
            <FloatingCart openCart={openCart} setOpenCart={setOpenCart} />
          ) : (
            <Cart openCart={openCart} setOpenCart={setOpenCart} />
          )}
        </div>
      </MyContext.Provider>
    </LoadingProvider>
  );
}

export default App;
