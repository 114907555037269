import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UserOrderCard from "./UserOrderCard";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyOrders = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const { userInfo } = AuthUser();
  const [u, setU] = useState([]);

  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    if (u?._id) {
      const url = `${apiUrl}/order/specific?fieldName=userId&&fieldValue=${u?._id}`;
      try {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setData(data?.data);
            setFilterData(data?.data);
          });
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  }, [u]);

  return (
    <div className="min-h-screen">
      <div className="pt-5">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          {data.length === 0
            ? Array(6)
                .fill()
                .map((_, index) => (
                  <div key={index}>
                    <Skeleton height={200} />
                  </div>
                ))
            : filterData.map((order) => (
                <UserOrderCard key={order?._id} order={order} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
