import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const UserOrders = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${apiUrl}/order/getOrdersById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to fetch data", err);
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl md:text-3xl uppercase font-semibold mb-6 text-center md:text-left">
        Invoice
      </h2>
      {loading ? (
        <div className="bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <Skeleton height={30} width={200} className="mb-4" />
          <Skeleton height={20} width={300} className="mb-2" />
          <Skeleton height={20} width={150} className="mb-2" />
          <Skeleton height={20} width={250} className="mb-6" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={40} width="100%" className="mb-6" />
          <Skeleton height={20} width={200} className="mb-2" />
          <Skeleton height={20} width={150} className="mb-2" />
          <Skeleton height={20} width={100} className="mb-2" />
          <Skeleton height={20} width={150} className="mb-2" />
        </div>
      ) : data ? (
        <div className="bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-2xl font-bold">Brand Name</h1>
              <p className="text-sm text-gray-600">Tagline Space Here</p>
              <div className="mt-2">
                <p className="text-sm">Address: Dhaka, Bangladesh</p>
                <p className="text-sm">Phone: +8801884-442022</p>
                <p className="text-sm">Email: info@niroghealthplus.com</p>
              </div>
            </div>
            <div className="text-right">
              <h3 className="text-2xl font-semibold mb-4">INVOICE</h3>
              <div className="mb-2 text-sm">
                <p>
                  <strong>Invoice #:</strong> {data.trackingId}
                </p>
                <p>
                  <strong>Date:</strong> {data.date}
                </p>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-xl md:text-2xl font-semibold mb-4">Invoice to:</h3>
            <div className="text-sm md:text-base">
              <p className="mb-2">
                <strong>Name:</strong> {data.user.name}
              </p>
              <p className="mb-2">
                <strong>Address:</strong> {data.user.address}
              </p>
              <p className="mb-2">
                <strong>Division:</strong> {data.user.division}
              </p>
              <p className="mb-2">
                <strong>District:</strong> {data.user.district}
              </p>
              <p className="mb-2">
                <strong>Upazila:</strong> {data.user.upazila}
              </p>
              <p className="mb-2">
                <strong>Phone:</strong> {data.user.phone}
              </p>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-xl md:text-2xl font-semibold mb-4">Products</h3>
            <table className="min-w-full bg-white border">
              <thead>
                <tr className="bg-gray-200 border-b">
                  <th className="py-2 px-4 border-r">Sl.</th>
                  <th className="py-2 px-4 border-r">Item Description</th>
                  <th className="py-2 px-4 border-r">Price</th>
                  <th className="py-2 px-4 border-r">Qty.</th>
                  <th className="py-2 px-4">Total</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.map((product, index) => (
                  <tr key={product._id} className="text-center border-b">
                    <td className="py-2 px-4 border-r">{index + 1}</td>
                    <td className="py-2 px-4 border-r">{product.productName}</td>
                    <td className="py-2 px-4 border-r">{product.discountPrice} BDT</td>
                    <td className="py-2 px-4 border-r">{product.quantity}</td>
                    <td className="py-2 px-4">
                      {(product.discountPrice * product.quantity).toFixed(2)} BDT
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mb-6">
            <div className="text-sm md:text-base">
              <h3 className="text-lg font-semibold mb-2">Terms & Conditions</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dignissim pretium consectetur.</p>
              <h3 className="text-lg font-semibold mt-4 mb-2">Payment Info:</h3>
              <p>Account #: 1234 5678 9012</p>
              <p>ACC Name: Nirog Health</p>
              <p>Bank Details: Add your bank details</p>
            </div>
            <div className="text-right text-sm md:text-base">
              <p className="mb-2">
                <strong>Sub Total:</strong> {data.totalAmount.toFixed(2)} BDT
              </p>
              <p className="mb-2">
                <strong>Tax:</strong> 0.00%
              </p>
              <p className="text-xl font-semibold mb-2">
                <strong>Total:</strong> {data.totalAmount.toFixed(2)} BDT
              </p>
              <p className="mb-2">
                <strong>Payment Status:</strong> {data.paidStatus ? "Paid" : "Unpaid"}
              </p>
              <p className="mb-2">
                <strong>Payment Method:</strong> {data.onlinePay ? "Online" : "Cash on Delivery"}
              </p>
              <p className="mb-2">
                <strong>Transaction ID:</strong> {data.tran_id}
              </p>
            </div>
          </div>

          <div className="text-center mt-6 border-t pt-4">
            <p className="text-sm">Thank you for your business</p>
          </div>

          <div className="text-center mt-6">
            <p className="text-sm">
              <strong>Phone # | Address | Website</strong>
            </p>
          </div>
        </div>
      ) : (
        <Skeleton count={5} />
      )}
    </div>
  );
};

export default UserOrders;
