import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import DeleteHook from "../../../Hooks/DeleteHook";
import Loading from "../../../Shared/Loading";
import AllrequestMedicineWithDetails from "./AllrequestMedicineWithDetails";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function RequestMedicineTable() {
  const [requestMedicines, setRequestMedicines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [filteredRequestMedicines, setFilteredRequestMedicines] = useState([]);

  useEffect(() => {
    async function fetchRequestMedicines() {
      try {
        const response = await axios.get(
          `${apiUrl}/RequestMedicine/getRequestMedicines`
        );
        setRequestMedicines(response?.data?.data);
        setFilteredRequestMedicines(response?.data?.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchRequestMedicines();
  }, [refetch]);

  const handleSearch = useCallback(() => {
    setFilteredRequestMedicines(
      requestMedicines?.filter((requestMedicine) =>
        requestMedicine?.medicineName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, requestMedicines]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredRequestMedicines(requestMedicines);
    } else {
      handleSearch();
    }
  }, [searchTerm, requestMedicines, handleSearch]);

  if (loading) {
    return <Loading />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Request Medicine
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential Request Medicine for health. Act now to secure the latest items.
        </p>
      </div>

      <AllrequestMedicineWithDetails totalRequestMedicines={requestMedicines.length}></AllrequestMedicineWithDetails>
      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter requestMedicine code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>

      <div className=" mb-3">
        <span className=" text-gray-700">
          Showing {filteredRequestMedicines.length} Results
        </span>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                #
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Medicine Name
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Strength
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Medicine From
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Company Name
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Action
              </th>
            </tr>
            {filteredRequestMedicines.map((requestMedicine, index) => (
              <tr key={requestMedicine._id} className="shadow">
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {index + 1}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {requestMedicine?.medicineName}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {requestMedicine?.strength}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {requestMedicine?.medicineFrom}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {requestMedicine?.companyName}
                </td>
                <td className="h-16 px-6 transition duration-300 border-slate-200 text-secondary text-lg flex gap-2 items-center cursor-pointer">
                  <div
                    onClick={() => {
                      DeleteHook({
                        refetch,
                        setRefetch,
                        url: `${apiUrl}/RequestMedicine/deleteRequestMedicines/${requestMedicine?._id}`,
                      });
                    }}
                    className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
                  >
                    <Icon icon="material-symbols:delete-outline" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RequestMedicineTable;
