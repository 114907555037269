import React from 'react';

const ReturnPolicy = () => {
  return (
    <div className='p-5'>
      <h1 className='text-4xl font-semibold'>Return Policy</h1>
      <div className='mt-8'>
        <p>Return and Refund Policy</p>
        <p className='my-3'>
          Nirog Health Plus offers a flexible return policy for items ordered with us. Under this policy, unopened and unused items must be returned within 7 days from the date of delivery. The return window will be listed in the returns section of the order, once delivered.
        </p>
        <p>Items are not eligible for return under the following circumstances:</p>
        <ul className='list-disc ml-5'>
          <li className='my-3'>If items have been opened, partially used or disfigured. Please check the package carefully at the time of delivery before opening and using.</li>
          <li>If the item’s packaging/box or seal has been tampered with. Do not accept the delivery if the package appears to be tampered with.</li>
          <li className='my-3'>If it is mentioned on the product details page that the item is non-returnable.</li>
          <li>If the return window for items in an order has expired. No items can be returned after 7 days from the delivery date.</li>
          <li className='my-3'>If any accessories supplied with the items are missing.</li>
          <li>If the item does not have the original serial number/UPC number/barcode affixed, which was present at the time of delivery.</li>
          <li className='my-3'>If there is any damage/defect which is not covered under the manufacturer's warranty.</li>
          <li>If the item is damaged due to visible misuse.</li>
          <li className='my-3'>Any refrigerated items like insulin or products that are heat sensitive are non-returnable.</li>
          <li>Items related to baby care, food & nutrition, healthcare devices and sexual wellness such as but not limited to diapers, health drinks, health supplements, glucometers, glucometer strips/lancets, health monitors, condoms, pregnancy/fertility kits, etc.</li>
        </ul>
        <p className='font-bold mt-3 text-stone-600'>Do you sell medicine strips in full or can it be single units too?</p>
        <p>
          We sell in single units to give customers flexibility in selecting specific amounts of medicine required. We provide single units of medicine as our pharmacist can cut strips.
        </p>
        <p className='font-bold mt-3 text-stone-600'>When can I expect delivery?</p>
        <p className='my-3'>
          Currently, we offer 12-48 hours delivery time for inside Dhaka and 1-5 days delivery time for outside Dhaka.
        </p>
        <p>
          We are constantly working to improve delivery time. Hopefully, in the future, we can offer a 2-hour expedite delivery service.
        </p>
        <p className='font-bold mt-3 text-stone-600'>I have broken the seal, can I return it?</p>
        <p>No, you cannot return any items with a broken seal.</p>
        <p className='font-bold mt-3 text-stone-600'>Can I return medicine that is partially consumed?</p>
        <p>No, you cannot return partially consumed items. Only unopened items that have not been used can be returned.</p>
        <p className='font-bold mt-3 text-stone-600'>Can I ask for a return if the strip is cut?</p>
        <p className='leading-loose'>
          We provide customers with the option of purchasing medicines as single units. Even if ordering a single tablet of paracetamol, we can deliver that. It is common to have medicines in your order with some strips that are cut. If you want to get a full strip in your order, please order a full strip amount and you will get it accordingly. If you do not order a full strip, you will get cut pieces. If you have ordered 4 single units which are cut pieces and want to return, all 4 pieces must be returned. We do not allow partial return of 1 or 2 pieces.
        </p>
        <p className='font-bold mt-3 text-stone-600'>When can I expect to get a refund?</p>
        <p>If eligible for a refund, your refund will be disbursed within 1-7 days.</p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
