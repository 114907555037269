import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function SuperAdminProductTable() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [query, setQuery] = useState("");

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/products/search-products`, {
        params: {
          query,
          page,
          limit,
        },
      });
      const fetchedProducts = response.data.products;
      setProducts(fetchedProducts);
      setTotalItems(response.data.total);
    } catch (err) {
      setError(err.message);
    }
  }, [query, page, limit]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    setPage(1);
    fetchProducts();
  };

  const handleDelete = async (productId) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(`${apiUrl}/products/products/${productId}`);
        await Swal.fire("Deleted!", "Your product has been deleted.", "success");
        fetchProducts(); // Fetch updated product list
      } catch (err) {
        await Swal.fire("Error!", "Failed to delete the product.", "error");
      }
    }
  };


  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-2">
      <div>
        <h1 className="text-xs font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Products
        </h1>
        <p className="text-xs text-gray-800 mb-2">
          Explore essential products for health. Act now to secure the latest
          items.
        </p>
      </div>

      <div className="flex items-center relative rounded-md w-full mt-2 mb-2 gap-2">
        <input
          type="text"
          placeholder="Enter product name, category, or brand"
          value={query}
          onChange={handleSearchChange}
          className="w-full p-2 rounded-md border border-gray-300 focus:outline-none text-xs"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-xs font-semibold py-2 px-4 rounded-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span>
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>

      <div className="mb-2">
        <span className="text-gray-700 text-xs">Showing {products.length} of {totalItems} Results</span>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <thead>
            <tr>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                No
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Image
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Name
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Price
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Discount Price
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Stock
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Categories
              </th>
              <th className="h-10 px-2 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0
              ? products.map((product, index) => (
                  <tr key={product._id} className="shadow">
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      {index + 1 + (page - 1) * limit}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <img
                        className="w-12 border p-1 rounded-md shadow"
                        src={product.image}
                        alt="img"
                      />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      {product.productName}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 line-through">
                      ৳ {product.price}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      ৳ {product.discountPrice.toFixed(2)}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      {product.stock}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      {product.category}
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 text-secondary flex gap-1 items-center cursor-pointer">
                      <div
                        onClick={() => handleDelete(product._id)}
                        className="border border-secondary py-1 px-2 rounded-md hover:bg-secondary/10 duration-300"
                      >
                        <Icon icon="material-symbols:delete-outline" />
                      </div>
                      <Link to={`/adminDashboard/updateProducts/${product._id}`}>
                        <div className="border border-secondary py-1 px-2 rounded-md hover:bg-secondary/10 duration-300">
                          <Icon icon="uil:edit" />
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))
              : Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index} className="shadow">
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton circle height={40} width={40} />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                    <td className="h-10 px-2 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                      <Skeleton />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center mt-4">
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={totalItems}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
          innerClass="flex list-none"
          itemClass="mx-1"
          linkClass="px-2 py-1 bg-gray-700 text-white rounded hover:bg-primary"
          activeLinkClass="bg-primary"
          disabledClass="opacity-50 cursor-not-allowed"
        />
      </div>
    </div>
  );
}

export default SuperAdminProductTable;
