import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../Hooks/UpdateHooks";
import icon from "../../src/Assets/location.png";
import AuthUser from "../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const LocationModal = ({ isOpen, setIsOpen }) => {
  const { userInfo } = AuthUser();
  const [formData, setFormData] = useState({
    name: "",
    division: "Barishal",
    upazila: "",
    district: "",
    address: "",
    number: "",
  });
  const [location, setLocation] = useState([]);

  useEffect(() => {
    fetch("../location.json")
      .then((res) => res.json())
      .then((data) => setLocation(data));
  }, [isOpen]);

  useEffect(() => {
    if (userInfo?.phone) {
      fetch(`${apiUrl}/user/getUsersByNum/${userInfo.phone}`)
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [isOpen, userInfo]);

  const division = location?.divisions?.filter(
    (data) => data?.name === formData?.division
  );
  const district = location?.districts?.filter(
    (data) => data?.division_id === division[0]?.id
  );
  const selectedDistrict = district?.filter(
    (data) => data?.name === formData?.district
  );
  const upazila = location?.upazilas?.filter(
    (data) => data?.district_id === selectedDistrict[0]?.id
  );

  const handelSubmit = async () => {
    if (userInfo?.phone) {
      await UpdateHooks(
        `${apiUrl}/user/updateByNum/${userInfo?.phone}`,
        formData
      );
      toast.success("Data updated!");
    } else {
      toast.error("Please login first");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:w-8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">
                    Update Your Location
                  </h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                {formData?.address && (
                  <div className="bg-white my-2 mx-5 mt-3 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between border-2 border-dotted border-primary">
                    <img className="w-14" src={icon} alt="item" />
                    <div className="flex justify-between w-full items-center">
                      <div className="space-y-0">
                        <p className="text-[14px]">{formData?.name}</p>
                        <p className="text-[12px]">{formData?.phone}</p>
                        <p className="text-[13px]">
                          {formData?.division} - {formData?.district} -{" "}
                          {formData?.upazila} - {formData?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="m-3 p-3">
                  <div>
                    <label className="text-sm">Full Name</label>
                    <input
                      onChange={handleChange}
                      name="name"
                      required
                      value={formData?.name}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    />
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Number</label>
                    <input
                      disabled
                      required
                      value={userInfo?.phone}
                      onChange={handleChange}
                      name="number"
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    />
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Division</label>
                    <select
                      name="division"
                      required
                      onChange={handleChange}
                      className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      {location?.divisions?.map((d) => (
                        <option
                          key={d?.id}
                          value={d?.name}
                          selected={d?.name === formData?.division}
                        >
                          {d?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">District</label>
                    <select
                      name="district"
                      required
                      onChange={handleChange}
                      className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      {district?.map((d) => (
                        <option
                          key={d?.id}
                          value={d?.name}
                          selected={d?.name === formData?.district}
                        >
                          {d?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">Upazila</label>
                    <select
                      name="upazila"
                      required
                      onChange={handleChange}
                      className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      {upazila?.map((d) => (
                        <option
                          key={d?.id}
                          value={d?.name}
                          selected={d?.name === formData?.upazila}
                        >
                          {d?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">Address</label>
                    <input
                      onChange={handleChange}
                      value={formData?.address}
                      name="address"
                      required
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    />
                  </div>

                  <button
                    onClick={handelSubmit}
                    className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white font-semibold hover:scale-105 duration-300"
                  >
                    Save Address!
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LocationModal;
