import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerHistory = () => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  const [prescription, setPrescription] = useState([]);
  const [filterOfflineOrder, setOfflineOrder] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.get(
          `${apiUrl}/user/getUsersById/${id}`
        );
        setUsers(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchUsers();
  }, [id]);

  useEffect(() => {
    async function fetchOnlineOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/offlineOrders/specific?fieldName=seller._id&&fieldValue=${encodeURIComponent(
            id
          )}&&fieldName2=orderType&&fieldValue2=online`
        );

        setPrescription(response?.data?.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchOnlineOrder();
  }, [id]);

  useEffect(() => {
    async function fetchOfflineOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/offlineOrders/specific?fieldName=seller._id&&fieldValue=${encodeURIComponent(
            id
          )}&&fieldName2=orderType&&fieldValue2=offline`
        );

        setOfflineOrder(response?.data?.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchOfflineOrder();
  }, [id]);

  useEffect(() => {
    async function fetchOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/order/specific?fieldName=sellerId&&fieldValue=${encodeURIComponent(
            id
          )}`
        );

        setData(response?.data?.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchOrder();
  }, [id]);

  const TotalOfflineSelling = filterOfflineOrder.reduce(
    (total, order) => total + parseFloat(order.payableAmount),
    0
  );
  const TotalOnlineSelling = prescription.reduce(
    (total, order) => total + parseFloat(order.payableAmount),
    0
  );
  const TotalSellingGeneralOrder = data.reduce(
    (total, order) => total + parseFloat(order.totalAmount),
    0
  );

  const TotalSelling = TotalOfflineSelling + TotalOnlineSelling;

  const OfflineTotalOrder = filterOfflineOrder?.length;
  const OnlineTotalOrder = prescription?.length;
  const order = data?.length;
  const TotalOrder = OfflineTotalOrder + OnlineTotalOrder + order;

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Seller All History
        </h1>
        <span className=" text-primary">
          {users?.name} || {users?.phone} || {users?.division} ||{" "}
          {users?.district} || {users?.upazila}{" "}
        </span>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential History for health .
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 px-4  md:grid-cols-3 sm:px-8">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon
              className=" h-12 w-12 text-white"
              icon="carbon:product"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Total Order</h3>
            <p className="text-3xl">{TotalOrder}</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon
              className=" h-12 w-12 text-white"
              icon="carbon:product"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">General Order</h3>
            <p className="text-3xl">{order}</p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon
              className=" h-12 w-12 text-white"
              icon="carbon:product"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Prescription's Order</h3>
            <p className="text-3xl">{OnlineTotalOrder}</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon
              className=" h-12 w-12 text-white"
              icon="carbon:product"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Offline Order</h3>
            <p className="text-3xl">{OfflineTotalOrder}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 px-4  md:grid-cols-3 sm:px-8 mt-5">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-[#006400]">
            <Icon
              className=" h-12 w-12 text-white "
              icon="la:sort-amount-up-alt"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Total Order Amount</h3>
            <p className="text-xl">{TotalSelling?.toFixed(2)} BDT</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-[#008000]">
            <Icon
              className=" h-12 w-12 text-white"
              icon="la:sort-amount-up-alt"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">General Order Amount</h3>
            <p className="text-xl">{TotalSellingGeneralOrder.toFixed(2)} BDT</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-[#008000]">
            <Icon
              className=" h-12 w-12 text-white"
              icon="la:sort-amount-up-alt"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Prescription's Order Amount</h3>
            <p className="text-xl">{TotalOnlineSelling.toFixed(2)} BDT</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-[#228B22]">
            <Icon
              className=" h-12 w-12 text-white"
              icon="la:sort-amount-up-alt"
            ></Icon>
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Offline Order Amount</h3>
            <p className="text-xl">{TotalOfflineSelling?.toFixed(2)} BDT</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerHistory;
