import React from 'react';

const AboutUs = () => {
    return (
        <div>
            <h2 className='text-4xl text-center'>About us page</h2>
        </div>
    );
};

export default AboutUs;