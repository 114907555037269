import React from "react";

const Privacy = () => {
  return (
    <div className="p-5">
      <h1 className="text-4xl font-semibold mb-8">Privacy Policy</h1>
      <div>
        Please read this privacy policy carefully. By accessing or using the
        website, you agree to be bound by the terms described herein and all the
        terms incorporated by reference. If you do not agree to all of these
        terms, do not use the website.
        <br />
        <br />
        Content & Purpose
        <br />
        <br />
        This privacy policy (“Privacy Policy”) applies to your use of the domain
        name www.NirogHealthPlus.com, an internet based portal, and Nirog Health
        Plus, a mobile application, owned and operated by Nirog Health Plus
        Limited, a company duly incorporated under the provisions of the
        Companies Act, 1994 (hereinafter referred to as “Nirog Health Plus” or
        “We” or “Our” or “Us” or “Company”). The domain name and the mobile
        application are collectively referred to as “Website”.
        <br />
        <br />
        The Website is a platform that facilitates the purchase of
        pharmaceutical products for sale by various relevant pharmacies
        (hereinafter referred to as “Services”, with the relevant pharmacies
        referred to as “Sellers”). The arrangement between the Sellers and Nirog
        Health Plus shall be governed in accordance with this Privacy Policy and
        the Terms of Use. The Services would be made available to such natural
        persons who have agreed to become buyers on the Website after obtaining
        due registration, in accordance with the procedure as determined by
        Nirog Health Plus, from time to time (referred to as “You” or “Your” or
        “Yourself” or “User”, which terms shall also include natural persons who
        are accessing the Website merely as visitors). The Services are offered
        to the Users through various modes which shall include the issue of
        discount coupons and vouchers that can be redeemed for various goods/
        services offered for sale by the Sellers.
        <br />
        <br />
        We have implemented reasonable security practices and procedures that
        are commensurate with the information assets being protected and with
        the nature of our business. While we try our best to provide security
        that is commensurate with the industry standards, because of the
        inherent vulnerabilities of the internet, we cannot ensure or warrant
        complete security of all information that is being transmitted to us by
        you.
        <br />
        <br />
        For the purpose of providing the Services and for other purposes
        identified in this Privacy Policy, Nirog Health Plus will be required to
        collect and host certain data and information of the Users. Nirog Health
        Plus is committed to protecting the Personal Information of the Users
        and takes all reasonable precautions for maintaining confidentiality of
        the User’s Personal Information. This Privacy Policy has been designed
        and developed to help you understand the following:
        <br />
        <br />
        <span className="font-semibold">
          The type of Personal Information (including Sensitive Personal Data or
          Information) that Nirog Health Plus collects from the Users;
        </span>
        <br />
        <br />
        <span className="font-semibold">
          The purpose of collection, means, and modes of usage of such Personal
          Information by Nirog Health Plus;
        </span>
        <br />
        <br />
        <span className="font-semibold">
          How and to whom Nirog Health Plus will disclose such information;
        </span>
        <br />
        <br />
        <span className="font-semibold">
          How Nirog Health Plus will protect the Personal Information including
          Sensitive Personal Data or Information that is collected from the
          Users; and
        </span>
        <br />
        <br />
        <span className="font-semibold">
          How Users may access and/ or modify their Personal Information.
        </span>
        <br />
        <br />
        This Privacy Policy shall apply to the use of the Website by all Users/
        Sellers. Accordingly, a condition of each User's use of and access to
        the Website and to the other services provided by Nirog Health Plus to
        Users is their acceptance of this Privacy Policy. Any User is required
        to read and understand the provisions set out herein prior to submitting
        any Sensitive Personal Data or Information to Nirog Health Plus, failing
        which they are required to leave the Website immediately.
        <br />
        <br />
        What is personal information?
        <br />
        <br />
        “Personal Information” means any information that relates to a natural
        person, which, either directly or indirectly, in combination with other
        information available with Nirog Health Plus, is capable of identifying
        the person concerned.
        <br />
        <br />
        “Sensitive Personal Data or Information” means Personal Information of
        any individual relating to password; financial information such as bank
        account or credit card or debit card or other payment instrument
        details; physical, physiological and mental health condition; sexual
        orientation; medical records and history; biometric information; any
        detail relating to the above as provided to or received by Nirog Health
        Plus for processing or storage. However, any data/ information relating
        to an individual that is freely available or accessible in the public
        domain under any other law shall not qualify as Sensitive Personal Data
        or Information.
        <br />
        <br />
        Types of personal information collected by Nirog Health Plus: A User may
        have limited access to the Website and utilize some of the Services
        provided by Nirog Health Plus without creating an account on the
        Website. Unregistered Users can access some of the information and
        details available on the Website. In order to have access to all the
        features and benefits on our Website, a User may be required to first
        create an account on our Website. As part of the registration process,
        Nirog Health Plus may collect the following categories of Personal
        Information from the Users (hereinafter collectively referred to as
        “User Information”):
        <br />
        <br />
        <span className="font-semibold">
          Name; User ID;
          <br />
          <br />
          Email address;
          <br />
          <br />
          Address (including country and ZIP/postal code);
          <br />
          <br />
          Gender;
          <br />
          <br />
          Age;
          <br />
          <br />
          Phone Number;
          <br />
          <br />
          Password chosen by the User;
          <br />
          <br />
          Valid financial account information; and
          <br />
          <br />
          Other details as the User may volunteer.
          <br />
          <br />
        </span>
        In order to avail the Services, the Users may be required to upload
        copies of their prescriptions, on the Website and/ or e-mail the same to
        Nirog Health Plus in accordance with the Terms of Use and the
        prescriptions will be stored/ disclosed by Nirog Health Plus only in the
        manner specified in this Privacy Policy and the Terms of Use. The term
        “User Information” shall also include any such prescriptions uploaded or
        otherwise provided by Users.
        <br />
        <br />
        Nirog Health Plus may keep records of telephone calls received and made
        for making inquiries, orders, or other purposes for the purpose of
        administration of Services.
        <br />
        <br />
        Internet use: Nirog Health Plus may also receive and/or hold information
        about the User’s browsing history including the URL of the site that the
        User visited prior to visiting the website as well as the Internet
        Protocol (IP) address of each User's computer (or the proxy server a
        User used to access the World Wide Web), User's computer operating
        system and type of web browser the User is using as well as the name of
        User's ISP. The Website uses temporary cookies to store certain data
        (that is not Sensitive Personal Data or Information) that is used by
        Nirog Health Plus and its service providers for the technical
        administration of the Website, research and development, and for User
        administration. The Website may enable Users to communicate with other
        Users or to post information to be accessed by others, whereupon other
        Users may collect such data. Nirog Health Plus hereby expressly
        disclaims any liability for any misuse of such information that is made
        available by visitors in such a manner.
        <br />
        <br />
        Nirog Health Plus does not knowingly collect Personal Information from
        children.
        <br />
        <br />
        Nirog Health Plus may in the future include other optional requests for
        information from the User including through user surveys in order to
        help Nirog Health Plus customize the Website to deliver personalized
        information to the User and for other purposes mentioned herein. Such
        information may also be collected in the course of contests conducted by
        Nirog Health Plus. Any such additional Personal Information will also be
        processed in accordance with this Privacy Policy.
        <br />
        <br />
        Purposes for which your information may be used by Nirog Health Plus:
        <br />
        <br />
        Nirog Health Plus will retain User Information only to the extent it is
        necessary to provide Services to the Users. The information which Nirog
        Health Plus collects from you may be utilized for various business
        and/or regulatory purposes including for the following purposes:
        <br />
        <br />
        <span className="font-semibold">
          Registration of the User on the Website;
          <br />
          <br />
          Processing the User’s orders / requests and provision of Services
          (including provision of safe Services);
          <br />
          <br />
          Completing transactions with Users effectively and billing for the
          products/ Services provided;
          <br />
          <br />
          Technical administration and customization of the Website;
          <br />
          <br />
          Ensuring that the Website content is presented to the Users in an
          effective manner;
          <br />
          <br />
          Delivery of personalized information and target advertisements to the
          User;
          <br />
          <br />
          Improvement of Services, features, and functionality of the Website;
          <br />
          <br />
          Research and development and for User administration (including
          conducting user surveys);
          <br />
          <br />
          Non-personally identifiable information, exclusively owned by Nirog
          Health Plus may be used in an aggregated or non-personally
          identifiable form for internal research, statistical analysis and
          business intelligence purposes including those for the purposes of
          determining the number of visitors and transactional details, and
          Nirog Health Plus may sell or otherwise transfer such research,
          statistical or intelligence data in an aggregated or non-personally
          identifiable form to third parties and affiliates;
          <br />
          <br />
          Dealing with requests, enquiries, complaints or disputes and other
          customer care related activities including those arising out of the
          Users’ request of the Services and all other general administrative
          and business purposes;
          <br />
          <br />
          In case of any contests conducted by Nirog Health Plus in which the
          User participates, the User Information may be used for prize
          fulfillment and other aspects of any contest or similar offering;
          <br />
          <br />
          Communicate any changes in our Services or this Privacy Policy or the
          Terms of Use to the Users;
          <br />
          <br />
          Verification of identity of Users and perform checks to prevent
          frauds; and
          <br />
          <br />
          Investigating, enforcing, resolving disputes and applying our Terms of
          Use and Privacy Policy, either ourselves or through third-party
          service providers.
        </span>
      </div>
    </div>
  );
};

export default Privacy;
