import React, { useEffect, useState } from "react";
import ProductCard from "../../../src/Pages/Home/ProductCard";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import LinearLoader from "../Loader/LinearLoader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CatagoriesCarousel = () => {
  const [category, setCategory] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await fetch(
          `${apiUrl}/category/specific/?fieldName=name&fieldValue=${id}`
        );
        const result = await res.json();
        if (result?.data?.length) {
          setCategory(result?.data[0]);
        } else {
          setCategory({});
        }
      } catch (err) {
        console.error("Error fetching category:", err);
      }
    };
    fetchCategory();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `${apiUrl}/products/products?page=${page}&limit=10&category=${id}`
        );
        const result = await res.json();
        setData(result?.products);
        setTotalItems(result.total);
        setLoading(false);
      } catch (err) {
        toast.error("Something went wrong while fetching products");
        setLoading(false);
      }
    };
    fetchProducts();
  }, [id, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <div className="">
      {loading && <LinearLoader />}
      <div className="w-full mb-5">
        {loading ? (
          <Skeleton height={200} />
        ) : (
          <img
            className="w-full lg:mt-0 object-contain rounded-lg"
            src={category?.banner}
            alt="Category Banner"
            width="1200"
            height="300"
            loading="lazy"
          />
        )}
      </div>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold">
          {loading ? <Skeleton width={200} /> : category?.name}
        </h1>
      </div>
      <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-7 gap-4">
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className="h-full w-full">
                <Skeleton height={320} />
              </div>
            ))
          : data?.map((item) => <ProductCard key={item._id} product={item} />)}
      </div>
      <div className="flex justify-center mt-5">
        <Pagination
          activePage={page}
          itemsCountPerPage={20}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          innerClass="flex list-none"
          itemClass="mx-1"
          linkClass="px-3 py-1 bg-gray-700 text-white rounded hover:bg-primary"
          activeLinkClass="bg-primary"
          disabledClass="opacity-50 cursor-not-allowed"
        />
      </div>
    </div>
  );
};

export default CatagoriesCarousel;
