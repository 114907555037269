import MyAccount from "../Components/UserDashboard/MyAccount/MyAccount";
import MyInvoice from "../Components/UserDashboard/MyOrder/MyInvoice";
import MyOrders from "../Components/UserDashboard/MyOrder/MyOrders";
import UserOrderOverview from "../Components/UserDashboard/MyOrder/UserOrderOverview";
// import UserOrders from "../Components/UserDashboard/MyOrder/UserOrders/UserOrders";
import MyPrescription from "../Components/UserDashboard/MyPrescription/MyPrescription";
import Notification from "../Components/UserDashboard/Notification/Notification";
import PrintInvoice from "../Components/UserDashboard/Notification/PrintInvoice";
import RequestMedicine from "../Components/UserDashboard/RequestMedicine/RequestMedicine";
import UserDashboardIndex from "../Components/UserDashboard/UserDashboardIndex";

const UserRoutes = [
  { path: "", Component: UserDashboardIndex },
  {path: "myOrders", Component: MyOrders},
  {path: "myOrders/order/:id", Component: UserOrderOverview},
  {path: "myAccount", Component: MyAccount},
  {path: "myPrescription", Component: MyPrescription},
  {path: "requestMedicin", Component: RequestMedicine},
  {path: "notification", Component: Notification},
  {path: "print-invoice/:id", Component: PrintInvoice},
  {path: "my-invoice/:id", Component: MyInvoice},




];

export default UserRoutes;
