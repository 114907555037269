import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import logo from "../../../Assets/fevicon.png";
import Loading from "../../../Shared/Loading";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PrintInvoice = () => {
  const { id } = useParams();
  const componentRef = React.useRef();
  const [loading, setLoading] = useState(true);
  const [orderView, setOrderView] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/offlineOrders/getOfflineOrderById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrderView(data?.data);
        setLoading(false);
      });
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-end mt-4">
        <button
          onClick={handlePrint}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
        >
          Print
        </button>
        {/* <button
          onClick={handleDownloadPDF}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Download PDF
        </button> */}
      </div>

      <div className="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto" ref={componentRef}>
        <div className="flex items-center justify-between  mb-8">
          <div className="flex items-center">
            <img className="lg:w-[120px] w-[50px] mr-2" src={logo} alt="Logo" />
            <div className="text-gray-700 font-semibold text-lg">Nirog Health+ </div>
          </div>
          <div className="text-gray-700 ">
            <div className="font-bold lg:text-xl text-sm mb-2">INVOICE</div>
            <div className="text-xs">
              {(() => {
                const orderDate = new Date(orderView.createdAt);
                return `${orderDate.toLocaleString()}`;
              })()}
            </div>
            <div className="text-sm">Invoice #: {orderView.invoiceId}</div>
          </div>
        </div>

        <div className="border-b-2 border-gray-300 pb-8 mb-8 ">
          <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
          <div className="text-gray-700 mb-2">{orderView?.user?.name}</div>
          <div className="text-gray-700 mb-2">{orderView?.user?.phone}</div>
          {orderView?.user?.division && (
            <div className="text-gray-700 mb-2">
              {orderView?.user?.division} - {orderView?.user?.district} -{" "}
              {orderView?.user?.upazila} - {orderView?.user?.address}
            </div>
          )}
        </div>

        {orderView?.items && orderView.items.length > 0 && (
          <table className="w-full text-left mb-8">
            <thead>
              <tr>
                <th className="text-gray-700 font-bold uppercase py-2">Description</th>
                <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
                <th className="text-gray-700 font-bold uppercase py-2">Price</th>
                <th className="text-gray-700 font-bold uppercase py-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {orderView.items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                  <td className="py-4 text-gray-700">{item?.itemName}</td>
                  <td className="py-4 text-gray-700">{item?.quantity}</td>
                  <td className="py-4 text-gray-700">৳ {item?.itemPrice}</td>
                  <td className="py-4 text-gray-700">৳ {item?.totalPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="border-t border-gray-300 my-5"></div>

        <div className="flex justify-end mb-2">
          <div className="text-gray-700 mr-2">Subtotal:</div>
          <div className="text-gray-700">{orderView?.totalOrderPrice} BDT</div>
        </div>

        <div className="flex justify-end mb-2">
          <div className="text-gray-700 mr-2">Discount:</div>
          <div className="text-gray-700">{orderView?.discount} %</div>
        </div>

        <div className="border-t border-gray-300 my-5"></div>

        <div className="flex justify-end mb-2">
          <div className="text-gray-700 mr-2">Total:</div>
          <div className="text-gray-700">{orderView?.payableAmount} BDT</div>
        </div>

        <div className="flex justify-end mb-5">
          <div className="text-gray-700 mr-2 font-bold text-xl">Pay Amount:</div>
          <div className="text-gray-700 font-bold text-xl">{orderView?.payableAmount} BDT</div>
        </div>

        <div className="border-t-2 border-gray-300 pt-8 mb-8">
          <div className="text-gray-700 mb-2">Nirog Health + </div>
          <div className="text-gray-700 mb-2">
            www.niroghealth.com || support@niroghealth.com || +09912365478975
          </div>
          <div className="text-gray-700">Dhaka, Bangladesh</div>
        </div>
      </div>
    </div>
  );
};

export default PrintInvoice;
