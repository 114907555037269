import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import InnerImageZoom from "react-inner-image-zoom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import MyContext from "../../Context/MyContext";
import "react-loading-skeleton/dist/skeleton.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import ProductInformation from "./ProductInformations/ProductInformation";
import LoadingContext from "../../Context/LoadingContext";
import { CircleChevronLeft } from "lucide-react";
import LinearLoader from "../../Components/Loader/LinearLoader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const NewProductDetails = () => {
  const { id } = useParams();
  const { refetch, setRefetch } = useContext(MyContext);
  const { setLoading, loading } = useContext(LoadingContext);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isInCart, setIsInCart] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${apiUrl}/products/specific-products?_id=${id}`
        );
        const data = await response.json();
        if (data.total > 0) {
          setProduct(data.products[0]);
        } else {
          setProduct(null);
        }
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkIfProductIsInCart = () => {
      const existingOrder =
        JSON.parse(localStorage.getItem("nirogOrder")) || [];
      const exist = existingOrder.find((item) => item._id === id);
      setIsInCart(!!exist);
    };

    fetchProduct();
    checkIfProductIsInCart();
  }, [id, refetch, setLoading]);

  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleQuantityChange = (increment) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + increment));
  };

  const totalDiscountPrice = product ? product.discountPrice * quantity : 0;

  const addOrderInLocalStorage = () => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder")) || [];
    const exist = existingOrder.find((item) => item._id === id);

    if (exist) {
      toast.warning("Product already in cart. Remove it first to add again.");
      return;
    }

    const newOrders = [
      ...existingOrder,
      { ...product, quantity: quantity, onePrice: totalDiscountPrice },
    ];
    localStorage.setItem("nirogOrder", JSON.stringify(newOrders));
    toast.success("Product added to cart");
    setIsInCart(true);
    setRefetch(!refetch);
  };

  return (
    <div ref={topRef}>
      {loading && <LinearLoader />}
      <div className="px-6">
        <button
          className="md:hidden text-white px-3 gap-1 bg-primary items-center flex py-1 rounded mb-4 text-xs"
          onClick={() => window.history.back()}
        >
          <CircleChevronLeft size={18} /> Back
        </button>
      </div>
      <div className="min-h-screen mx-auto px-6 flex flex-col gap-5">
        <div className="flex flex-col md:flex-row gap-0 md:gap-8">
          <div className="flex-1 relative">
            {product ? (
              <InnerImageZoom
                src={product.image || "/placeholder-image.png"}
                zoomSrc={product.image || "/placeholder-image.png"}
                alt={product.productName}
                className="w-full h-auto"
              />
            ) : (
              <Skeleton height={400} />
            )}
            {product && (
              <div className="text-gray-600 absolute top-2 left-2 border-primary border h-auto w-16">
                <img
                  src={product.image || "/placeholder-image.png"}
                  alt={product.productName}
                />
              </div>
            )}

            {product && (
              <p className="hidden md:block text-white bg-primary p-2 rounded mt-2 text-center text-sm font-semibold">
                সহজে কেনাকাটা করুন এবং বাংলাদেশ জুড়ে আমাদের দ্রুত এবং
                নির্ভরযোগ্য ডেলিভারি উপভোগ করুন। এখনই অর্ডার করুন!
              </p>
            )}
          </div>

          <div className="flex-1">
            <div className="mb-4">
              <h1 className="text-2xl font-bold">
                {product ? product.productName : <Skeleton width={200} />}
              </h1>
              <p className="text-gray-600">
                {product ? product.category : <Skeleton width={150} />}
              </p>
            </div>
            <div className="mb-4">
              <p className="text-gray-600">
                {product ? product.brandName : <Skeleton count={3} />}
              </p>
            </div>
            <div className="mb-4 flex items-center gap-4">
              <label htmlFor="quantity" className="text-gray-600">
                Quantity:
              </label>
              <div className="flex items-center border rounded">
                <button
                  onClick={() => handleQuantityChange(-1)}
                  className="bg-primary text-white px-4 py-2 rounded-l focus:outline-none"
                >
                  -
                </button>
                <p className="text-gray-700 px-4 py-2 focus:outline-none">
                  {quantity}
                </p>
                <button
                  onClick={() => handleQuantityChange(1)}
                  className="bg-primary text-white px-4 py-2 rounded-r focus:outline-none"
                >
                  +
                </button>
              </div>
            </div>
            <div className="mb-4">
              <p className="text-xl font-bold text-orange-600">
                {product ? (
                  `৳${totalDiscountPrice.toFixed(2)}`
                ) : (
                  <Skeleton width={100} />
                )}
              </p>
              {product && (
                <>
                  <p className="text-sm text-gray-500 line-through">
                    ৳{product.price}
                  </p>
                  <p className="text-sm text-red-500">
                    {product.discount}% OFF
                  </p>
                </>
              )}
            </div>
            <div className="mb-4">
              {isInCart ? (
                <button className="bg-red-500 text-white px-6 py-2 rounded w-full md:w-auto mb-2">
                  Already added
                </button>
              ) : (
                <button
                  onClick={addOrderInLocalStorage}
                  className="bg-primary text-white px-6 py-2 rounded w-full md:w-auto"
                >
                  Add to Cart
                </button>
              )}
            </div>
            <div className="bg-gray-100 p-4 rounded-md">
              <h3 className="text-lg font-semibold mb-2">
                Exclusive Offers Just for You
              </h3>
              <p className="mb-4 text-gray-600">
                {product ? (
                  "Unlock amazing deals and maximize your savings with our special offers!"
                ) : (
                  <Skeleton count={2} />
                )}
              </p>
              {product && (
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="bg-purple-100 p-4 rounded text-purple-700">
                    <p className="text-sm mt-2">
                      Treat yourself to more without worrying about the cost!
                    </p>
                  </div>
                  <div className="bg-teal-100 p-4 rounded text-teal-700">
                    <p className="text-sm mt-2">
                      Save more and shop more with this fantastic deal!
                    </p>
                  </div>
                </div>
              )}

              {product && (
                <p className="block md:hidden text-white bg-primary p-2 rounded mt-2 text-center text-xs font-semibold">
                  সহজে কেনাকাটা করুন এবং বাংলাদেশ জুড়ে আমাদের দ্রুত এবং
                  নির্ভরযোগ্য ডেলিভারি উপভোগ করুন। এখনই অর্ডার করুন! 🚚
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <ProductInformation product={product} />
        </div>
      </div>
    </div>
  );
};

export default NewProductDetails;
