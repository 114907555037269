import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { Icon } from "@iconify/react";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AdminShipping = () => {
  const [formData, setFormData] = useState({
    insideDhaka: 0,
    outsideDhaka: 0,
    tax: 0,
  });

  useEffect(() => {
    fetch(`${apiUrl}/shipping/getShippings`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setFormData(data?.data[0]);
        }
      });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData?._id) {
      await UpdateHooks(
        `${apiUrl}/shipping/updateShippings/${formData?._id}`,
        formData
      );
      toast.success("Shipping data Updated!");
    } else {
      try {
        await axios.post(`${apiUrl}/shipping/addShippings`, formData);
        toast.success("Shipping posted!");
      } catch (error) {
        toast.error("Error posting shipping data: " + error.message);
      }
    }
  };

  const percentages = Array.from({ length: 100 }, (_, i) => i + 1);

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Update Shipping
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Update essential shipping details. Act now to secure the latest items.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-4 px-4 sm:grid-cols-4 sm:px-8">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon className="h-12 w-12 text-white" icon="fa-solid:shipping-fast" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Inside Dhaka</h3>
            <p className="text-3xl">{formData?.insideDhaka} BDT</p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon className="h-12 w-12 text-white" icon="fa-solid:shipping-fast" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Outside Dhaka</h3>
            <p className="text-3xl">{formData?.outsideDhaka} BDT</p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-400">
            <Icon className="h-12 w-12 text-white" icon="tabler:receipt-tax" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Tax %</h3>
            <p className="text-3xl">{formData?.tax} %</p>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="shadow-lg shadow-gray-300 px-8 py-10 rounded flex flex-col gap-4 text-left"
      >
        <div className="md:flex items-center">
          <div className="mb-1 w-full md:w-[50%] mr-0 md:mr-2">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Inside Dhaka
            </label>
            <input
              type="number"
              name="insideDhaka"
              value={formData?.insideDhaka}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
            />
          </div>
          <div className="mb-1 w-full md:w-[50%] mr-0 md:mr-2">
            <label className="block mb-2 text-[13px] font-normal text-gray-900">
              Outside Dhaka
            </label>
            <input
              type="number"
              name="outsideDhaka"
              value={formData?.outsideDhaka}
              onChange={handleChange}
              className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 focus:border-none"
            />
          </div>
        </div>

        <div className="mb-1 w-full mr-0 md:mr-2">
          <label className="block mb-2 text-[13px] font-normal text-gray-900">
            Tax (%)
          </label>
          <select
            name="tax"
            value={formData?.tax}
            onChange={handleChange}
            className="bg-[#F0FDF4] text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 focus:border-blue-500"
          >
            <option value="" disabled>
              Select
            </option>
            {percentages.map((percent, index) => (
              <option key={index} value={percent}>
                {percent}%
              </option>
            ))}
          </select>
        </div>

        <div className="text-center pt-3">
          <button
            className="bg-primary2 hover:bg-lightPrimary text-white py-2 rounded-lg text-lg w-fit px-8"
            type="submit"
          >
            Update Shipping
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminShipping;
