import React, { useRef } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
import JoditEditor from "jodit-react";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AdminHowToOrder = () => {
  const editor = useRef(null);
  const [des,setDes]=useState("");
  const [formData, setFormData] = useState({
    title: "",
    videoUrl:"",
    playStoreUrl:"",
    appStoreUrl:"",
  });

  //   load data
  useEffect(() => {
    fetch(`${apiUrl}/howToOrder/getHowToOrders`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setFormData(data?.data[0]);
          setDes(data?.data[0]?.description)
        }
      });
  }, []);

  // update and post data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `${apiUrl}/howToOrder/updateHowToOrders/${formData?._id}`,
          {...formData,description:des}
        );
        toast?.success(`howToOrder section Updated !`);
      } else {
        await PostHooks(
          `${apiUrl}/howToOrder/addHowToOrders`,
          {...formData,description:des},
          `howToOrder data posted`
        );
      }
    } catch (error) {
      toast?.success(error);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update HowToOrders Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto"
      >
        <div className="mb-4"></div>
        
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              required
              onChange={handleChange}
              value={formData.title}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
          <div className="mb-4">
          <label
            for="repeat-password"
            class="block mb-2 text-[13px] font-normal text-gray-900 "
          >
            Dosage From
          </label>

          <JoditEditor
          
            ref={editor}
            required
              onChange={(newContent)=>setDes(newContent)}
              value={des}
          />
        </div>

        <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              
            >
              Video Url
            </label>
            <input
              type="text"
              name="videoUrl"
              required
              onChange={handleChange}
              value={formData.videoUrl}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
        <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              
            >
              PlayStore Url
            </label>
            <input
              type="text"
              name="playStoreUrl"
              required
              onChange={handleChange}
              value={formData.playStoreUrl}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
        <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-600"
              
            >
              AppStore Url
            </label>
            <input
              type="text"
              name="appStoreUrl"
              required
              onChange={handleChange}
              value={formData.appStoreUrl}
              className="w-full px-3 py-2 border rounded-md focus:outline-none"
            />
          </div>
     
     

        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update Why Medmyne !
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminHowToOrder;
