import React, { useEffect, useState } from "react";
import Loading from "../../../Shared/Loading";
import { Icon } from "@iconify/react";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerStock = () => {
  const { userInfo } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data?.stocks);
        setFilterData(data?.data?.stocks);
        setLoading(false);
      });
  }, [userInfo]);

  const handleSearch = () => {
    if (searchTerm === "") {
      setFilterData(data);
    } else {
      setFilterData(
        data?.filter(
          (product) =>
            product?.productName
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            product?.productId === searchTerm ||
            product?.stock === Number(searchTerm)
        )
      );
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <h2 className="text-xl uppercase font-semibold mb-4">All Stocks</h2>
      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter product name or category"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />

        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                No
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Name
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Stock
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Product Id
              </th>
            </tr>
            {/* Map through the filtered products instead of all products */}
            {filterData?.map((stock, index) => (
              <tr key={stock._id} className="shadow">
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {index + 1}
                </td>

                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {stock?.productName}
                </td>

                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {stock?.stock}
                </td>

                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {stock?.productId}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SellerStock;
