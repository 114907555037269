import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AddSeller = () => {
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    division: "Barishal",
    upazila: "",
    district: "",
    address: "",
    role: "seller",
  });
  const [location, setLocation] = useState([]);
  useEffect(() => {
    fetch("../location.json")
      .then((res) => res.json())
      .then((data) => setLocation(data));
  }, []);

  const division = location?.divisions?.filter(
    (data) => data?.name === formData?.division
  );
  const district = location?.districts?.filter(
    (data) => data?.division_id === division[0]?.id
  );
  const selectedDistrict = district?.filter(
    (data) => data?.name === formData?.district
  );
  const upazila = location?.upazilas?.filter(
    (data) => data?.district_id === selectedDistrict[0]?.id
  );
  const ph = "+" + phone;

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (phone) {
      fetch(`${apiUrl}/user/addUsers`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ...formData, phone: ph }),
      })
        .then((response) => response.json())
        .then((data) => {
          e.target.reset();
          toast.success("Your are successfully create an account !");
        });
    } else {
      toast.error("Please give a number!");
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <div className="px-3 text-2xl font-semibold">ADD SELLER</div>
      <form onSubmit={handelSubmit} className="p-3">
        <div className="">
          <label className="text-sm">Full Name</label>
          <input
            onChange={handleChange}
            name="name"
            required
            value={formData?.name}
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>
        <div className="mt-1">
          <label className="text-sm">Number</label>

          <PhoneInput country={"bd"} value={phone} onChange={setPhone} />
        </div>
        <div className="mt-1">
          <label className="text-sm">Division</label>
          <select
            name="division"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option selected disabled value="">
              Choose One
            </option>
            {location?.divisions?.map((d) => (
              <option selected={d?.name === formData?.division} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-1">
          <label className="text-sm">District</label>
          <select
            name="district"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option selected disabled value="">
              Choose One
            </option>
            {district?.map((d) => (
              <option selected={d?.name === formData?.district} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-1">
          <label className="text-sm">Upazila</label>
          <select
            name="upazila"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option selected disabled value="">
              Choose One
            </option>
            {upazila?.map((d) => (
              <option selected={d?.name === formData?.upazila} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-1">
          <label className="text-sm">Address</label>
          <input
            onChange={handleChange}
            value={formData?.address}
            name="address"
            required
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          ></input>
        </div>

        <button
          type="submit"
          className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
        >
          Save Address !
        </button>
      </form>
    </div>
  );
};

export default AddSeller;
