import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import axios from "axios";
import AuthUser from "../../Hooks/authUser";
import DOMPurify from "dompurify";
import "react-phone-input-2/lib/style.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const LoginRegisterModal = ({ openLogin, setOpenLogin }) => {
  const { setToken } = AuthUser();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [timer, setTimer] = useState(60); // Timer state

  useEffect(() => {
    let interval;
    if (showOTP && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      toast.error("OTP expired. Please request a new OTP.");
      setShowOTP(false);
      setOtp("");
    }
    return () => clearInterval(interval);
  }, [showOTP, timer]);

  async function onSignup() {
    setLoading(true);
    const sanitizedPh = DOMPurify.sanitize(ph); // Sanitize the phone number input
    const formattedPh = sanitizedPh.startsWith("+880") ? sanitizedPh : `+880${sanitizedPh.slice(-10)}`;
    try {
      const response = await fetch(`${apiUrl}/sms/sendotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: formattedPh }),
      });

      const data = await response.json();

      if (response.ok) {
        window.confirmationResult = { token: data.token, phoneNumber: formattedPh };
        setLoading(false);
        setShowOTP(true);
        setTimer(60); // Reset timer
        toast.success("OTP sent successfully!");
      } else {
        setLoading(false);
        throw new Error(data.message || "Failed to send OTP");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
      setLoading(false);
    }
  }

  async function onOTPVerify() {
    setLoading(true);
    const confirmationResult = window.confirmationResult;

    if (confirmationResult?.phoneNumber) {
      const sanitizedOtp = DOMPurify.sanitize(otp); // Sanitize the OTP input
      try {
        const response = await axios.post(`${apiUrl}/sms/verifyotp`, {
          phoneNumber: confirmationResult.phoneNumber,
          otp: sanitizedOtp,
        });

        if (response.data.success) {
          const getUserResponse = await fetch(
            `${apiUrl}/user/getUsersByNum/${confirmationResult.phoneNumber}`
          );
          const userData = await getUserResponse.json();

          if (!userData?.data?._id) {
            const addUserResponse = await fetch(`${apiUrl}/user/addUsers`, {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ phone: confirmationResult.phoneNumber }),
            });

            const addUserData = await addUserResponse.json();
            if (addUserData.success) {
              setLoading(false);
              setToken(
                addUserData?.data?.phone,
                addUserData?.data?.role,
                addUserData?.data
              );
              setOpenLogin(!openLogin);
              toast.success("You have successfully created an account!");
            } else {
              throw new Error(
                addUserData.message || "Failed to create an account"
              );
            }
          } else {
            setLoading(false);
            setToken(
              userData?.data?.phone,
              userData?.data?.role,
              userData?.data
            );
            setOpenLogin(!openLogin);
            toast.success("You have successfully logged in!");
          }
        } else {
          setLoading(false);
          toast.error("Invalid OTP");
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
        setLoading(false);
      }
    } else {
      toast.error("Phone number not found");
    }
  }

  return (
    <Transition appear show={openLogin} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setOpenLogin(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-gray-50 text-left align-middle shadow-xl transition-all">
                <div className="bg-gray-50 p-6">
                  <button
                    onClick={() => setOpenLogin(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md ml-auto mb-4 block"
                  >
                    X
                  </button>
                  <div>
                    <div id="recaptcha-container"></div>

                    <div className="w-full flex flex-col gap-4 rounded-lg p-4">
                      {showOTP ? (
                        <>
                          <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                            <BsFillShieldLockFill size={30} />
                          </div>
                          <label
                            htmlFor="otp"
                            className="font-bold text-xl text-primary text-center"
                          >
                            Enter your OTP
                          </label>
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            autoFocus
                            className="opt-container"
                          />
                          <button
                            onClick={onOTPVerify}
                            className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                          >
                            {loading && (
                              <CgSpinner
                                size={20}
                                className="mt-1 animate-spin"
                              />
                            )}
                            <span>Verify OTP</span>
                          </button>
                          <p className="text-center text-sm text-gray-500">
                            OTP will expire in {timer} seconds
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="border-b border-dashed pb-4 mb-4">
                            <h1 className="text-2xl font-bold">Login</h1>
                            <p className="text-sm opacity-40 mt-2">
                              Login to make an order, access your orders,
                              special offers, health tips, and more!
                            </p>
                          </div>
                          <PhoneInput
                            country={"bd"}
                            value={ph}
                            onChange={setPh}
                            placeholder="+8801234567890"
                            countryCodeEditable={false}
                          />
                          <button
                            onClick={onSignup}
                            className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                          >
                            {loading && (
                              <CgSpinner
                                size={20}
                                className="mt-1 animate-spin"
                              />
                            )}
                            <span>Send code via SMS</span>
                          </button>

                          <p className="text-sm mt-4">
                            By continuing you agree to{" "}
                            <span className="text-primary">
                              Terms & Conditions, Privacy Policy
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginRegisterModal;
