import { Icon } from "@iconify/react";
import React from "react";

const OfflineOrderWithDetails = ({totalPayableAmount,totalOrders,currentMonth}) => {
  return (
    <div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 px-4  sm:px-8">
        <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div class="p-4 bg-green-400">
           <Icon className=" h-12 w-12 text-white" icon="carbon:product"></Icon>
          </div>
          <div class="px-4 text-gray-700">
            <h3 class="text-xl tracking-wider">Total Order</h3>
            <p class="text-xl">{totalOrders}</p>
          </div>
        </div>
        <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div class="p-4 bg-green-400">
           <Icon className=" h-12 w-12 text-white" icon="icon-park-outline:sales-report"></Icon>
          </div>
          <div class="px-4 text-gray-700">
            <h3 class="text-xl tracking-wider">Current Month</h3>
            <p class="text-xl">{currentMonth.toFixed(2)} BDT</p>
          </div>
        </div>
        <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div class="p-4 bg-green-400">
           <Icon className=" h-12 w-12 text-white" icon="icon-park-outline:sales-report"></Icon>
          </div>
          <div class="px-4 text-gray-700">
            <h3 class="text-xl tracking-wider">Total Sales</h3>
            <p class="text-xl">{totalPayableAmount?.toFixed(2)} BDT</p>
          </div>
        </div>
       
       
      </div>
    </div>
  );
};

export default OfflineOrderWithDetails;
