import React from "react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { GrView } from "react-icons/gr";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerOrderTable = ({ item, i, u, refetch, setRefetch }) => {
  let st = true;
  let copy = u?.stocks;

  item?.products?.forEach((i) => {
    const exist = copy?.find((s) => s.productId === i?._id);
    if (exist) {
      let pice =
        i?.type === "Pice"
          ? i?.quantity
          : i?.type === "Strip"
          ? i?.quantity * i?.oneStrip
          : i?.quantity * i?.oneBox;
      let newQuantity = exist?.stock - pice;
      if (newQuantity < 0) {
        st = false;
      }
      let uniq = copy?.filter((s) => s.productId !== i?._id);
      copy = [
        ...uniq,
        {
          productId: exist?.productId,
          stock: newQuantity,
          productName: i?.name,
          seller: u?.name,
        },
      ];
    } else {
      st = false;
    }
  });

  const handleUpdate = async (status, id) => {
    if (st) {
      await UpdateHooks(`${apiUrl}/order/updateOrders/${id}`, {
        status: status,
        sellerId: u?._id,
      });
      await UpdateHooks(`${apiUrl}/user/updateUsers/${u?._id}`, {
        stocks: copy,
      });
      toast.success(`Order status is ${status}`);
      setRefetch(!refetch);
    } else {
      toast.error("Stock Not Available!");
    }
  };

  return (
    <tr className={`${st ? "bg-white" : "bg-red-50"} border-b border-[#D0D2DA]`}>
      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
        {i + 1}
      </th>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.user?.name}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.date}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.division}</p>
        <p>{item?.district}</p>
        <p>{item?.upazila}</p>
        <p>{item?.address}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.totalAmount}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.pay ? "Online payment" : "Cash on Delivery"}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.tran_id}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <p>{item?.products?.length}</p>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="flex items-center gap-3">
          <Link
            to={`/sellerDashboard/orders/${item?._id}`}
            title="view all products"
            className="text-lg text-white bg-green-200 w-7 h-7 rounded-lg flex items-center justify-center"
          >
            <GrView />
          </Link>
          <select
            onChange={(e) => handleUpdate(e?.target?.value, item?._id)}
            className="px-2 py-2 border focus:outline-none rounded-md"
            defaultValue={item?.status}
          >
            {item?.status === "pending" && (
              <option value="pending">Pending</option>
            )}
            <option value="confirm">Confirm</option>
            <option value="delivering">Delivering</option>
            <option value="delivered">Delivered</option>
          </select>
        </span>
      </td>
    </tr>
  );
};

export default SellerOrderTable;
