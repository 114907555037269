import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Icon } from "@iconify/react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PharmaceuticalMedicineDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch the product details based on the id
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/product/getProductsById/${id}`
        );
        // Assuming the response.data contains the product details
        setProduct(response.data.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  return (
    <div className="min-h-screen">
      <div className="flex gap-2 items-center text-2xl text-gray-900">
        <Icon icon="mdi:drugs" />
        <h1>{product ? product.name : <Skeleton width={200} />}</h1>
      </div>

      <div className="text-gray-500 text-md">
        <p>{product ? product.genericName : <Skeleton width={150} />}</p>
        <p>{product ? product.strength : <Skeleton width={100} />}</p>
        <p>{product ? product.companyName : <Skeleton width={150} />}</p>
      </div>

      <div className="mt-5">
        <div className="bg-gray-300 text-black p-2">
          <h1>Dosage Form</h1>
        </div>
        <div>
          {product ? (
            <div dangerouslySetInnerHTML={{ __html: product.dosageForm }} />
          ) : (
            <Skeleton count={3} />
          )}
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-gray-300 text-black p-2">
          <h1>Description</h1>
        </div>
        <div>
          {product ? (
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          ) : (
            <Skeleton count={3} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PharmaceuticalMedicineDetails;
