import React from 'react';
import { Link } from 'react-router-dom';

function Failed() {
  return (
    <div className=' ' >
        <div className="bg-red-500 py-8 px-4 sm:px-6 lg:px-8 ">
      <h1 className="text-2xl font-bold mb-4 text-white">Payment Failed</h1>
      <p className="text-lg text-white">We apologize, but your payment did not go through.</p>
      <p className="text-sm text-white">Please check your card or Mobile Banking details and try again.</p>
     <Link to="/"> <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mt-5">Try Again</button></Link>
    </div>
    </div>
  );
}

export default Failed;
