import SellerDashboardIndex from "../Components/SellerDashboard/SellerDashboardIndex";
import UserOrders from "../Components/UserDashboard/MyOrder/UserOrders/UserOrders";
import CreateOfflineOrder from "../Pages/SellerDashboard/OfflineOrder/CreateOfflineOrder";
import OfflineOrderHistory from "../Pages/SellerDashboard/OfflineOrder/OfflineOrderHistory";
import OfflineOrderView from "../Pages/SellerDashboard/OfflineOrder/OfflineOrderView";
import SellerAccountManage from "../Pages/SellerDashboard/SellerAccountManage/SellerAccountManage";
import SellerOrders from "../Pages/SellerDashboard/SellerOrders/SellerOrders";
import SellerPendingPrescription from "../Pages/SellerDashboard/SellerPendingPrescription/SellerPendingPrescription";
import SellerPrescriptionHistory from "../Pages/SellerDashboard/SellerPendingPrescription/SellerPrescriptionHistory";
import SellerViewPrescription from "../Pages/SellerDashboard/SellerPendingPrescription/SellerViewPrescription";
import SellerStock from "../Pages/SellerDashboard/SellerStock/SellerStock";
import SellerStockHistory from "../Pages/SellerDashboard/Stock/SellerStockHistory";


const SellerRoutes = [
  { path: "seller-dashboard", Component: SellerDashboardIndex },
  { path: "seller-account-manage", Component: SellerAccountManage },
  
  { path: "create-offline-order", Component: CreateOfflineOrder },
  { path: "offline-order-history", Component: OfflineOrderHistory },
  { path: "offline-order-view/:id", Component: OfflineOrderView },


  { path: "Seler-pending-prescription", Component: SellerPendingPrescription },
  { path: "Seler-prescription-history", Component: SellerPrescriptionHistory },
  { path: "seler-view-prescription/:id", Component: SellerViewPrescription },

  { path: "orders", Component: SellerOrders },
  {path: "orders/:id", Component: UserOrders},
  {path: "seller-stock-history", Component: SellerStockHistory},
  {path: "stock", Component: SellerStock},

];

export default SellerRoutes;
