import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CreateOfflineOrder = () => {
  const { userInfo } = AuthUser();
  const [u, setU] = useState([]);
  const generateInvoiceId = () => {
    const timestamp = Date.now();
    return `INV-${timestamp}`;
  };

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  const [form, setForm] = useState({
    items: [{ itemName: "", itemId: "", itemPrice: "", quantity: "" }],
    phoneNumber: "",
    customerName: "", // new field
    orderDescription: "",
    discount: 0, // new field for discount
  });

  const [invoiceId, setInvoiceId] = useState(generateInvoiceId());
  const handleChange = (e, index) => {
    if (index !== undefined) {
      // Handle items
      const newItems = [...form.items];
      newItems[index][e.target.name] = e.target.value;
      setForm({
        ...form,
        items: newItems,
      });
    } else {
      // Handle customer name and discount
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddItem = () => {
    setForm({
      ...form,
      items: [
        ...form.items,
        { itemName: "", itemId: "", itemPrice: "", quantity: "" },
      ],
    });
  };

  const handleRemoveItem = (index) => {
    const newItems = [...form.items];
    newItems.splice(index, 1);
    setForm({
      ...form,
      items: newItems,
    });
  };

  // flag
  let st = true;
  let copy = u?.stocks;
  form?.items?.forEach((i) => {
    const exist = copy?.find((s) => s.productId === i?.itemId);
    if (exist) {
      let newQuantity = exist?.stock - Number(i?.quantity);
      if (exist?.stock < Number(i?.quantity)) {
        st = false;
      }
      let uniq = copy?.filter((s) => s.productId !== i?.itemId);
      copy = [
        ...uniq,
        {
          productId: exist?.productId,
          stock: newQuantity,
          productName: i?.itemName,
          seller: u?.name,
        },
      ];
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (st) {
      const formData = {
        items: form.items.map((item) => ({
          ...item,
          totalPrice: calculateTotal(item),
        })),
        seller: u,
        user: {
          phone: form?.phoneNumber,
          name: form?.customerName,
        },
        orderDescription: form.orderDescription,
        invoiceId: invoiceId,
        discount: form.discount, // include discount in form data
        totalOrderPrice: calculateTotalOrderPrice(),
        payableAmount: calculatePayableAmount(), // Include payable amount in form data
      };

      try {
        const response = await fetch(
          `${apiUrl}/offlineOrders/addOfflineOrder`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          await UpdateHooks(`${apiUrl}/user/updateUsers/${u?._id}`, {
            stocks: copy,
          });

          toast.success("Order Created!");
        } else {
          // Handle errors if the request was not successful
          toast.error("Failed to create order");
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error creating order:", error.message);
      }

      setForm({
        items: [{ itemName: "", itemId: "", itemPrice: "", quantity: "" }],
        phoneNumber: "",
        customerName: "",
        orderDescription: "",
        entryDetails: u,
        invoiceId: invoiceId,
        discount: 0, // reset discount to default value
      });
      setInvoiceId(generateInvoiceId());
    } else {
      toast.error("Stock Not Available!");
    }
  };

  const calculateTotal = (item) => {
    const quantity = parseInt(item.quantity) || 0;
    const itemPrice = parseFloat(item.itemPrice) || 0;
    return quantity * itemPrice;
  };

  const calculateDiscountAmount = () => {
    const totalOrderPrice = calculateTotalOrderPrice();
    const discountPercentage = form.discount || 0;
    return ((discountPercentage / 100) * totalOrderPrice).toFixed(2);
  };

  const calculatePayableAmount = () => {
    const totalOrderPrice = calculateTotalOrderPrice();
    const discountAmount = parseFloat(calculateDiscountAmount()) || 0;
    return (totalOrderPrice - discountAmount).toFixed(2);
  };

  const calculateTotalOrderPrice = () => {
    return form.items.reduce((total, item) => total + calculateTotal(item), 0);
  };

  const renderInvoiceDetails = () => {
    const discountAmount = calculateDiscountAmount();
    const payableAmount = calculatePayableAmount(); // Calculate payable amount

    return (
      <div className="invoice-container">
        <h2 className="text-xl uppercase">Invoice - {invoiceId}</h2>
        <div className="flex-grow border-t mt-2 border-gray-200" />
        <div className="mt-5">
          {form.items.map((item, index) => (
            <div key={index} className="mb-4">
              <p>
                <span className="text-black">Item Name:</span>{" "}
                {item.itemName || "Not provided"}
              </p>
              <p>
                <span className="text-black">Price:</span> ৳{" "}
                {item.itemPrice || "Not provided"}
              </p>
              <p>
                <span className="text-black">Quantity:</span>{" "}
                {item.quantity || "Not provided"}
              </p>
              <p>
                <span className="text-black">Total Price:</span> ৳{" "}
                {calculateTotal(item)}
              </p>
              <button
                type="button"
                className=" bg-red-500 uppercase text-white px-3 rounded-sm py-1 text-xs "
                onClick={() => handleRemoveItem(index)}
              >
                - Remove Item
              </button>
            </div>
          ))}
          <p>
            <span className="text-black">Total Order Price:</span> ৳{" "}
            {calculateTotalOrderPrice()} BDT
          </p>
          <p>
            <span className="text-black">Discount Percentage:</span> (
            {form.discount} %)
          </p>
          <p>
            <span className="text-black">Discount Amount:</span>{" "}
            {discountAmount} BDT
          </p>
          <div className="flex-grow border-t mt-2 border-gray-300" />
          <p>
            <span className="text-black">Payable Amount:</span> ৳{" "}
            {payableAmount} BDT
          </p>
        </div>
      </div>
    );
  };

  const quantityOptions = Array.from({ length: 100 }, (_, index) => index + 1);
  const discountOptions = Array.from({ length: 21 }, (_, index) => index); // Assuming you want values from 0 to 20

  return (
    <div className=" min-h-screen">
      <div className=" flex justify-end  mb-2">
        <Link to="/sellerDashboard/offline-order-history">
          <button className=" uppercase bg-green-500 px-4 py-1 text-white font-bold rounded-sm">
            History
          </button>
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        <div className="rounded-lg bg-gray-50 lg:col-span-2">
          <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Create Order</h1>
            <form onSubmit={handleSubmit}>
              {form.items.map((item, index) => (
                <div key={index} className="mb-2">
                  <label
                    htmlFor={`itemName${index}`}
                    className="block mb-2  font-thin"
                  >
                    Item Name
                  </label>

                  <select
                    name="itemName"
                    value={item.itemName}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    className="border p-2 w-full"
                    required
                  >
                    <option selected disabled value="">
                      select
                    </option>
                    {u?.stocks?.map((s) => (
                      <option value={s?.productName}>
                        {s?.productName}-<span className="">{s?.stock}</span>
                      </option>
                    ))}
                  </select>

                  <label
                    htmlFor={`itemName${index}`}
                    className="block mb-2  font-thin"
                  >
                    Confirm (Item Name)
                  </label>

                  <select
                    name="itemId"
                    value={item.itemId}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    className="border p-2 w-full"
                    required
                  >
                    <option selected disabled value="">
                      select
                    </option>
                    {u?.stocks?.map((s) => (
                      <option value={s?.productId}>
                        {s?.productName}-<span className="">{s?.stock}</span>
                      </option>
                    ))}
                  </select>

                  <label
                    htmlFor={`itemPrice${index}`}
                    className="block mb-2 font-thin"
                  >
                    Item Price BDT
                  </label>
                  <input
                    type="number"
                    id={`itemPrice${index}`}
                    name="itemPrice"
                    value={item.itemPrice}
                    onChange={(e) => handleChange(e, index)}
                    className="border p-2 w-full"
                    required
                  />

                  <label
                    htmlFor={`quantity${index}`}
                    className="block mb-2 font-thin"
                  >
                    Quantity
                  </label>
                  <select
                    id={`quantity${index}`}
                    name="quantity"
                    value={item.quantity}
                    onChange={(e) => handleChange(e, index)}
                    className="border p-2 w-full bg-white"
                    required
                  >
                    <option className="font-thin" value="" disabled>
                      Select Quantity
                    </option>
                    {quantityOptions.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className=" mb-2 ">
                <button
                  className=" bg-green-500 uppercase text-white px-3 rounded-sm py-1 text-xs "
                  type="button"
                  onClick={handleAddItem}
                >
                  Add Item +
                </button>
              </div>
              <div className="mb-4">
                <label htmlFor="customerName" className="block mb-2 font-thin">
                  Customer Name
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <input
                  type="text"
                  id="customerName"
                  name="customerName"
                  value={form.customerName}
                  onChange={(e) => handleChange(e)}
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phoneNumber" className="block mb-2 font-thin">
                  Customer Phone
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={form.phoneNumber}
                  onChange={(e) =>
                    setForm({ ...form, phoneNumber: e.target.value })
                  }
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="orderDescription"
                  className="block mb-2 font-thin"
                >
                  Order Description{" "}
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <textarea
                  id="orderDescription"
                  name="orderDescription"
                  value={form.orderDescription}
                  onChange={(e) =>
                    setForm({ ...form, orderDescription: e.target.value })
                  }
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="discount" className="block mb-2 font-thin">
                  Discount (%) <span className="text-gray-500">(Optional)</span>
                </label>
                <select
                  id="discount"
                  name="discount"
                  value={form.discount}
                  onChange={(e) => handleChange(e)}
                  className="border p-2 w-full bg-white"
                >
                  <option value="" disabled>
                    Select Discount
                  </option>
                  {discountOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}%
                    </option>
                  ))}
                </select>
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Order
              </button>
            </form>
          </div>
        </div>
        <div className="rounded-lg bg-gray-50 p-5">
          {renderInvoiceDetails()}
        </div>
      </div>
    </div>
  );
};

export default CreateOfflineOrder;
