import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React, { Fragment, useContext, useState } from "react";
import location from "../Assets/location.png";
import moment from "moment";
import { toast } from "react-toastify";
import MyContext from "../Context/MyContext";
import cod from "../Assets/cod_icon.webp";
// import card from "../Assets/cards_icon.webp";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CheckOutModal = ({
  openCheckout,
  setOpenCheckout,
  amount,
  products,
  user,
}) => {
  const { refetch, setRefetch } = useContext(MyContext);
  let date = moment().format("YYYY-MM-D");
  const [cashOn, setCashOn] = useState(true); // Default to Cash on Delivery
  const [pay, setPay] = useState(false); // Default to not pay (card payment)

  const data = {
    totalAmount: amount,
    products,
    userId: user?._id,
    userPhone: user?.phone,
    user,
    date,
    onlinePay: pay,
    district: user?.district,
    division: user?.division,
    upazila: user?.upazila,
    address: user?.address,
  };

  const handleSubmit = async () => {
    if (!amount) {
      toast.error("Amount is required");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/order/addOrders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to submit order");
      }

      const responseData = await response.json();

      if (pay && responseData.url) {
        window.location.replace(responseData.url);
      } else if (responseData?.status === "success") {
        localStorage.removeItem("nirogOrder");
        toast.success("Order submitted!");
        setRefetch(!refetch);
        setOpenCheckout(false);
      } else {
        toast.error("Failed to submit order. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting order:", error);
      toast.error("Failed to submit order. Please try again later.");
    }
  };

  return (
    <Transition appear show={openCheckout} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setOpenCheckout(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-4/12 md:7/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Place Order</h2>
                  <button
                    onClick={() => setOpenCheckout(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="p-3 mx-2">
                  {user?.address && (
                    <div className="bg-white my-2 mt-3 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between border-2 border-dotted border-primary">
                      <img className="w-14" src={location} alt="location" />
                      <div className="flex justify-between w-full items-center">
                        <div className="space-y-0">
                          <p className="text-[14px]">{user?.name}</p>
                          <p className="text-[12px]">{user?.phone}</p>
                          <p className="text-[13px]">
                            {user?.division} - {user?.district} -{" "}
                            {user?.upazila} - {user?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="border rounded-md mt-3">
                    <div className="flex items-center justify-between bg-primary/10 font-medium px-2 py-1">
                      <div className="flex gap-2 items-center">
                        <Icon
                          className="text-2xl"
                          icon="noto:money-with-wings"
                        />
                        <p>To be paid</p>
                      </div>
                    </div>

                    {/* Payment method selection */}
                    <fieldset className="flex flex-col gap-3 p-3">
                      <div className="relative flex items-center">
                        <input
                          checked={cashOn}
                          onClick={() => {
                            setCashOn(true);
                            setPay(false);
                          }}
                          className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                          type="radio"
                          value="cashOn"
                          id="cashOn"
                          name="paymentMethod"
                        />
                        <label
                          className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 w-[80%]"
                          htmlFor="cashOn"
                        >
                          <img
                            className="w-fit"
                            src={cod}
                            alt="Cash on Delivery"
                          />
                        </label>
                        <svg
                          className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="title-1 description-1"
                          role="graphics-symbol"
                        >
                          <circle cx="8" cy="8" r="4" />
                        </svg>
                      </div>
                      {/* <div className="relative flex items-center">
                        <input
                          checked={pay}
                          onClick={() => {
                            setPay(true);
                            setCashOn(false);
                          }}
                          className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                          type="radio"
                          value="pay"
                          id="pay"
                          name="paymentMethod"
                        />
                        <label
                          className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 w-[80%]"
                          htmlFor="pay"
                        >
                          <img className="w-fit" src={card} alt="Card Payment" />
                        </label>
                        <svg
                          className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-white peer-checked:scale-100 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="title-2 description-2"
                          role="graphics-symbol"
                        >
                          <circle cx="8" cy="8" r="4" />
                        </svg>
                      </div> */}
                    </fieldset>
                    {/* Payment method selection */}

                    {/* Alert */}
                    <div
                      className="flex w-full gap-4 px-4 py-3 text-sm border rounded border-primary/10 bg-primary/5 text-primary items-center"
                      role="alert"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        role="graphics-symbol"
                        aria-labelledby="title-01 desc-01"
                      >
                        <title id="title-01">Icon title</title>
                        <desc id="desc-01">
                          A more detailed description of the icon
                        </desc>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <p>
                        Cash on delivery option is available for all of
                        Bangladesh
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={handleSubmit}
                    className="py-2 mt-5 px-5 bg-primary rounded-md text-white font-semibold flex items-center hover:scale-105 duration-300 w-fit mx-auto text-sm"
                  >
                    PLACE ORDER
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CheckOutModal;
