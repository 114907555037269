import React, { useState } from "react";
import ProductDescription from "./ProductDescription";
import UsageInstructions from "./UsageInstructions";
import SafetyInformation from "./SafetyInformation";
import DosageForm from "./DosageForm";
import Benefits from "./Benefits";
import SideEffects from "./SideEffects";

const ProductInformation = ({ product }) => {
  const [activeComponent, setActiveComponent] = useState('ProductDescription');

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div className="bg-white shadow-md rounded-md p-4 mb-4">
      <h1 className="text-xl font-semibold mb-4">Product Information</h1>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => handleSetActiveComponent('ProductDescription')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'ProductDescription'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Product Description
        </button>
        <button
          onClick={() => handleSetActiveComponent('UsageInstructions')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'UsageInstructions'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Usage Instructions
        </button>
        <button
          onClick={() => handleSetActiveComponent('SafetyInformation')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'SafetyInformation'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Safety Information
        </button>
        <button
          onClick={() => handleSetActiveComponent('DosageForm')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'DosageForm'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Dosage Form
        </button>
        <button
          onClick={() => handleSetActiveComponent('Benefits')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'Benefits'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Benefits
        </button>
        <button
          onClick={() => handleSetActiveComponent('SideEffects')}
          className={`text-sm px-4 py-2 rounded-md focus:outline-none ${
            activeComponent === 'SideEffects'
              ? 'bg-primary text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Side Effects
        </button>
      </div>
      <div className="mt-4">
        {activeComponent === 'ProductDescription' && <ProductDescription product={product} />}
        {activeComponent === 'UsageInstructions' && <UsageInstructions product={product} />}
        {activeComponent === 'SafetyInformation' && <SafetyInformation product={product} />}
        {activeComponent === 'DosageForm' && <DosageForm product={product} />}
        {activeComponent === 'Benefits' && <Benefits product={product} />}
        {activeComponent === 'SideEffects' && <SideEffects product={product} />}
      </div>
    </div>
  );
};

export default ProductInformation;
