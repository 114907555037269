import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { singleImageUpload } from "../Hooks/ImageUpload";
import moment from "moment";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ApplyJobModal = ({ isOpen, setIsOpen, data }) => {
  const [img, setImg] = useState("");
  let date = moment().format("YYYY-MM-D");
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
  });

  const handelSubmit = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/jobApply/addJobApplys`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          date,
          resume: img,
          jobDetails: data,
        }),
      });

      if (response.ok) {
        toast.success("Your Application has been submitted");
        setIsOpen(false);
      } else {
        // Handle errors if the request was not successful
        toast.error("something wrong");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error creating order:", error.message);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setImg);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative  z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Apply This job</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="m-3 p-3">
                  <div className="">
                    <label className="text-sm">Full Name</label>
                    <input
                      onChange={handleChange}
                      name="name"
                      required
                      value={formData?.name}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Number</label>
                    <input
                      required
                      value={formData?.phone}
                      onChange={handleChange}
                      name="phone"
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Email</label>
                    <input
                      required
                      value={formData?.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">Address</label>
                    <input
                      onChange={handleChange}
                      value={formData?.address}
                      name="address"
                      required
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">CV/Resume</label>
                    <input
                      type="file"
                      onChange={handleChangeUploadImage}
                      class="  w-full h-full border p-2 rounded-md"
                    />
                  </div>

                  <button
                    onClick={handelSubmit}
                    className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
                  >
                    Save Address !
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ApplyJobModal;
