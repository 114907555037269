import React, { useState, useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from "../../Context/MyContext";

const ProductCard = ({ product }) => {
  const { refetch, setRefetch } = useContext(MyContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    const checkIfProductIsInCart = () => {
      const existingOrder =
        JSON.parse(localStorage.getItem("nirogOrder")) || [];
      const exist = existingOrder.find((item) => item._id === product._id);
      setIsInCart(!!exist);
    };

    checkIfProductIsInCart();
  }, [product._id, refetch]);

  const addOrderInLocalStorage = () => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder")) || [];
    const exist = existingOrder.find((item) => item._id === product._id);

    if (exist) {
      toast.warning("Product already in cart. Remove it first to add again.");
      return;
    }

    const newOrders = [
      ...existingOrder,
      { ...product, quantity: 1, onePrice: product.discountPrice },
    ];
    localStorage.setItem("nirogOrder", JSON.stringify(newOrders));
    toast.success("Product added to cart");
    setIsInCart(true);
    setRefetch(!refetch);
  };

  return (
    <div className="border relative max-w-fit mx-auto">
      {!imageLoaded && (
        <div className="w-[154px] h-[154px] md:w-[196px] md:h-[196px]">
          <Skeleton height="100%" width="100%" />
        </div>
      )}
      <div className="absolute top-0 left-0 bg-[#04152E] text-white text-xs font-bold py-1 px-2 rounded-br-lg">
        {product.discount}% OFF
      </div>

      <Link to={`/product-details/${product?._id}`}>
        <div>
          <img
            src={product?.image}
            alt={product.productName}
            className={`w-[154px] h-[154px] md:w-[196px] md:h-[196px] ${
              !imageLoaded ? "hidden" : "block"
            }`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      </Link>
      <div className="p-2">
        <div>
          <div className="flex justify-between items-center">
            <p className="text-md font-bold text-orange-600">
              ৳ {product.discountPrice.toFixed(2)}
            </p>
            <p className="text-sm font-bold text-gray-600 line-through">
              ৳{product.price}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center mt-1">
              <span className="text-xs text-yellow-500">
                {Array.from({ length: Math.floor(product.rating) }, (_, i) => (
                  <span key={i}>&#9733;</span>
                ))}
              </span>
              <span className="text-xs text-gray-600 ml-2">
                ({product.rating})
              </span>
            </div>
            <div className="text-xs text-gray-600">{`Stock: ${product.stock}`}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <h1 className="text-xs font-bold">
              {product.productName.length > 20
                ? `${product.productName.slice(0, 10)}...`
                : product.productName}
            </h1>
          </div>

          <div>
            {isInCart ? (
              <div className="flex justify-center items-center bg-red-500 rounded-md">
                <button
                  className="text-white p-1 text-sm"
                  onClick={addOrderInLocalStorage}
                >
                  Already added
                </button>
              </div>
            ) : (
              <div className="flex justify-center items-center bg-[#04152E] rounded-md">
                <button
                  className="text-white p-1 text-sm"
                  onClick={addOrderInLocalStorage}
                >
                  Add To Cart
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
