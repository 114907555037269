import React from "react";

const Terms = () => {
  return (
    <div className="px-10">
      <h1 className="text-4xl font-semibold">Terms and Conditions</h1>
      <div className="mt-5">
        <p>Terms and Conditions</p>
        <p>
          BY CONTINUING TO ACCESS AND USE THIS WEBSITE/MOBILE APPLICATION YOU
          CONFIRM THAT YOU ACCEPT OUR TERMS AND CONDITIONS OF USE SET OUT BELOW.
          IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS, YOU MUST LEAVE THIS
          WEBSITE/MOBILE APPLICATION.
        </p>
        <ul className="list-decimal">
          <li>
            The Nirog Health Plus website and the mobile application are owned
            and provided by Nirog Health Plus Limited, a company incorporated
            under the Companies Act, 1994. If you need any information or have a
            complaint about this website/mobile application or any of our
            services, please contact us by one of the following methods:
            <p>Phone: +880 1884-442022</p>
          </li>
          <li className="my-3">
            Nirog Health Plus services are rendered either from its own licensed
            premises or from the licensed premises of its associates / network
            pharmacies which are undertaken under the strict supervision of
            qualified and registered pharmacists. All pharmacists are bound by
            codes of professional ethics and conduct.
          </li>
          <li>
            Your privacy and that of any other person whose information you
            provide to us is important to us. Please see our privacy policy for
            details of what information we collect and how we will use and
            protect it.
          </li>
          <li className="my-3">
            By registering and using the services, you agree that you are
            eighteen years or above and you are not debarred by any law to
            contract, and you agree to have read and accepted the terms and
            conditions provided herein. Visitors to this website/mobile
            application are required to register in order to use its facilities/
            services. We are not under any obligation to accept a request for
            registration and reserve the right to suspend or terminate access at
            any time if your continued use is believed to prejudice us or other
            users. By registering to use this website/mobile application, you
            confirm that the information you provide during the registration
            process is accurate and complete. You agree to update your
            registration details promptly if there are any changes. All
            registration information you provide will be kept secure and
            processed in accordance with our privacy policy.
          </li>
          <li>
            During the registration process, you may be allocated, or invited to
            choose, your own username and provide a phone number. You are
            responsible for keeping your username and phone number confidential
            and for ensuring that they are not used by any other person.
          </li>
          <li className="my-3">
            Nirog Health Plus and other third parties with whom we have a
            business relationship may occasionally promote their goods or
            services on the website or mobile application or through other
            direct marketing initiatives or may make software and other
            materials available for you to purchase or download. Whilst we try
            to encourage third parties to offer good quality products, services,
            and materials at competitive prices, we have no control over them or
            other third parties. We do not endorse the products or services they
            offer, nor give you any assurance that they will be suitable for
            your needs. It is your responsibility to satisfy yourself in this
            regard, and we have no liability in connection with the same. All
            promotions are for a limited period and subject to special terms and
            conditions, which are in addition to and not part of the terms and
            conditions stated herein.
          </li>
          <li>
            This website or mobile application, the materials, and software on
            it, or provided to you through it are protected by copyright,
            trademark, and other intellectual property rights and laws
            throughout the world and are owned by, or are licensed to Nirog
            Health Plus and/or third parties. You are permitted to display the
            materials on this mobile application on a computer screen/mobile
            screen and, save for restricted access documents, to download and
            print a hard copy for your personal use or for obtaining products or
            services from us provided you do not alter or remove any of the
            content or any part of the website/mobile application without our
            express permission to do so and that you do not change or delete any
            copyright, trademark, or other proprietary notices.
          </li>
          <li className="my-3">
            You may not copy, reproduce, store (in any medium or format),
            distribute, transmit, modify, create derivative works from all or
            any part of this website/mobile application or the materials or
            software on it, or provided to you through it without our prior
            written consent (which may be given or withheld in our absolute
            discretion).
            <p>
              Use this website/mobile application or any of the materials or
              software on it, or provided to you through it, for:
            </p>
            <ul className="list-disc px-10 my-3">
              <li>any unlawful purpose or in contravention of applicable law</li>
              <li className="my-3">
                commercial exploitation without our prior written consent
              </li>
              <li>
                any purpose or in any manner that may give a false or misleading
                impression of us, our staff, or our services use, upload or
                transmit
              </li>
              <li className="my-3">
                any material that is defamatory, offensive, obscene, or
                otherwise unlawful, or which may cause offense or distress, or
                which may affect or infringe the rights of any other person
              </li>
              <li>
                any device, software, file, or mechanism which may interfere
                with the proper operation of this website or our systems
              </li>
            </ul>
            establish a link to this mobile application from any other website,
            intranet, or extranet site without our prior written consent.
            Decompile, disassemble, or reverse engineer (or attempt to do any of
            them) any of the software or other materials provided on or through
            this website/mobile application. Do anything that may interfere with
            or disrupt this website/mobile application or our service. Encourage
            or permit others to do any of the above. In the event that you do
            not comply with the above restrictions, any person affected by your
            actions may bring a claim against you and/or Nirog Health Plus. We
            will pursue a claim against you for any losses and costs (including
            legal costs) we may suffer as a result of your actions.
          </li>
          <li className="my-3">
            We do not give any assurance that the materials provided or
            available to you on or through this website/mobile application are
            suitable for your requirements or that they will be secure, error,
            or virus-free, and we will have no liability in respect of those
            materials.
          </li>
          <li>
            This mobile application is provided free of charge and we make no
            guarantee that it will be uninterrupted or error-free. We reserve
            the right to modify, suspend, or withdraw the whole or any part of
            the website/mobile application or any of its content at any time
            without notice and without incurring any liability.
          </li>
          <li className="mt-3">
            We may, from time to time, provide links from this mobile
            application to other websites that are owned and controlled by third
            parties. These links are provided only for your convenience and we
            have no control over and will have no liability in respect of those
            websites.
          </li>
          <li className="my-3">
            From time to time our site requests information from users via
            surveys or contests. Participation in these surveys, contests, or
            referrals programs is completely voluntary, and the user therefore
            has a choice whether or not to disclose any information requested.
            Information requested may include contact information (such as name
            and delivery address) and demographic information (such as postcode,
            age level). Contact information will be used to notify the winners
            and award prizes. Survey information will be used for purposes of
            monitoring or improving the functionality of the site. The terms and
            conditions for each survey and contest may differ or otherwise be
            amended and canceled at the sole discretion of Nirog Health Plus.
          </li>
          <li>
            We may monitor activity and content on this website/mobile
            application and may take any action we consider appropriate if we
            suspect you may be in breach of these Terms and Conditions including
            suspending, attaching conditions to, or terminating your access
            and/or notifying the authorities or relevant regulators of your
            activities.
          </li>
          <li className="my-3">
            We employ security technology as detailed in our privacy policy.
            However, Internet transmissions are never completely private or
            secure and there is a risk, therefore, that any message or
            information you send to us from this website may be intercepted and
            potentially read by others. We will have no liability in respect of
            any transmissions you send to us and you do so entirely at your own
            risk.
          </li>
          <li>
            We take care to ensure that all information available on our
            website/mobile application about our business, services, and any
            products mentioned is accurate. However, these are continually
            developing and, occasionally, the information may be out of date.
            Medical, commercial, and legal practice change frequently and the
            content on this website/mobile application, in any newsletters and
            in other items offering guidance have been prepared for general
            interest only and are not a substitute for specific medical, legal,
            or other professional advice and should not be read or used as such.
            For accurate up-to-date information you should contact us and/or
            your doctor directly.
          </li>
          <li className="my-3">
            NIROG HEALTH PLUS DOES NOT WARRANT OR REPRESENT THAT THE MATERIAL ON
            THIS WEBSITE/MOBILE APPLICATION IS ACCURATE, COMPLETE OR CURRENT OR
            THAT THE WEBSITE WILL BE FREE OF DEFECTS OR VIRUSES. NOTHING
            CONTAINED IN THE PAGES OF THIS WEBSITE/MOBILE APPLICATION SHOULD BE
            CONSTRUED AS MEDICAL, COMMERCIAL, LEGAL OR OTHER PROFESSIONAL
            ADVICE. DETAILED PROFESSIONAL ADVICE SHOULD BE OBTAINED BEFORE
            TAKING OR REFRAINING FROM ANY ACTION BASED ON ANY OF THE INFORMATION
            OR MATERIAL CONTAINED IN THIS WEBSITE/MOBILE APPLICATION OR ANY
            COMMUNICATIONS PROVIDED TO YOU AS A RESULT OF YOUR REGISTRATION.
          </li>
          <li className="my-3">
            WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE (IN CONTRACT,
            NEGLIGENCE OR OTHERWISE) WHERE:
            <ul className="list-disc px-10">
              <li>THERE IS NO BREACH OF A LEGAL DUTY OF CARE OWED TO YOU BY US;</li>
              <li>
                THE LOSS OR DAMAGE IS NOT A REASONABLY FORESEEABLE RESULT OF ANY
                SUCH BREACH; OR
              </li>
              <li>
                ANY LOSS OR DAMAGE OR INCREASE IN LOSS OR DAMAGE RESULTS FROM A
                BREACH BY YOU OF THESE TERMS AND CONDITIONS. THE MAXIMUM
                LIABILITY OF NIROG HEALTH PLUS SHALL BE LIMITED TO THE COST OF
                THE PRODUCTS PURCHASED OR SERVICES AVAILED BY THE CUSTOMER.
                NIROG HEALTH PLUS OR ITS DIRECTORS SHALL NOT BE LIABLE FOR ANY
                INDIRECT, CONSEQUENTIAL OR OTHER DAMAGES CAUSED TO THE USER.
              </li>
            </ul>
          </li>
          <li>
            Nothing in these Terms and Conditions is intended to nor shall it
            confer a benefit on any third party under the Contracts and a person
            who is not a party to these Terms and Conditions has no rights to
            enforce them.
          </li>
          <li className="my-3">
            No delay or decision not to enforce rights under these terms and
            conditions will constitute a waiver of the right to do so and will
            not affect rights in relation to any subsequent breach.
          </li>
          <li>
            WE RESERVE THE RIGHT TO CHANGE THESE TERMS AND CONDITIONS AT ANY
            TIME. THE NEW VERSION WILL BE POSTED ON THIS WEBSITE/MOBILE
            APPLICATION AND WILL TAKE EFFECT IMMEDIATELY UPON POSTING. IF YOU
            USE THE WEBSITE/MOBILE APPLICATION AFTER THE NEW TERMS AND
            CONDITIONS HAVE COME INTO EFFECT, YOU WILL BE INDICATING YOUR
            AGREEMENT TO BE BOUND BY THE NEW TERMS AND CONDITIONS.
          </li>
          <li className="my-3">
            THE FOLLOWING TERMS APPLY TO YOUR USE OF THE NIROG HEALTH PLUS
            PRESCRIPTION ORDERING SERVICE. THESE ARE ADDITIONAL TO AND
            SUPPLEMENT OUR GENERAL WEBSITE/MOBILE APPLICATION TERMS AND
            CONDITIONS WHICH ALSO APPLY.
            <span className="font-bold">
              IF YOU HAVE ANY QUESTIONS CONCERNING THE SERVICE, PLEASE CONTACT
              US.
            </span>
          </li>
          <li>
            If the prescription is of a person other than yourself, you will
            need to have the authority of the person whose prescription it is to
            have it dispensed by us. By placing an order to dispense another
            person’s prescription you confirm that you have their authority to
            do so.
          </li>
          <li className="my-3">
            We only accept Prescription Orders from customers who are 18 years
            old or over although they may order prescriptions for persons who
            are under 18. By placing an order, you confirm that you are at least
            18 and legally eligible to contract.
          </li>
          <li>
            We accept Orders from and dispatch orders to addresses in selected
            locations in Bangladesh.
          </li>
          <li className="my-3">
            No Order placed by you will be binding on us until we have confirmed
            the order to you by phone, email or in writing. We reserve the right
            to reject any order. All orders are delivered subject to receipt of
            a valid prescription and availability except which does not require
            prescription according to Bangladesh Government law.
          </li>
          <li>
            When we receive an original prescription from you, our pharmacist
            will verify it against the information provided to us at the time
            the order was placed. In the event that the information does not
            match with your original order as per the prescription, we may try
            to contact you using the information in your registration. If we
            cannot contact you and we are unable to dispense the item(s) on your
            Prescription Order, we reserve the right to return your prescription
            to you.
          </li>
          <li className="my-3">
            If you place a Prescription Order and we do not receive the relevant
            original prescription, we will not have any liability to you and it
            will be your responsibility to contact us within 7 days regarding
            your prescription order.
          </li>
          <li className="my-3">
            Validation of prescription services through our partner Registered
            Medical Practitioner shall be made available to the User only upon
            request of the User and in the following situations:
            <ul className="list-disc pl-10">
              <li>
                The existing prescription has expired and the User is unable to
                get an appointment with his Registered Medical Practitioner; or
              </li>
              <li>
                The prescription is not legible and the prescribing Registered
                Medical Practitioner is unavailable to validate the
                prescription; or
              </li>
              <li>
                If the prescription contains schedule X drugs / unusual
                quantities.
              </li>
            </ul>
            The User understands and agrees that the validation of prescription
            services provided herein are only on request and are intended for
            general purpose only and should not be used in case of medical
            emergencies and serious illness issue where physical consultation is
            recommended and at no point these services are intended to replace
            the physical consultation. The User also agrees and understands that
            for providing the validation of prescription service Nirog Health
            Plus will have to share the prescription with its partner Registered
            Medical Practitioner and by requesting the services the User
            confirms its consent to share his/her prescription with Nirog Health
            Plus's partner Registered Medical Practitioner. Upon the request of
            the User, the prescription of the User shall be shared with Nirog
            Health Plus's partner Registered Medical Practitioner and post
            consultation or review of the prescription, if the Registered
            Medical Practitioner deems fit, he/she may validate the prescription
            shared with him/her of the User. However, at all time the Registered
            Medical Practitioner has the discretion to refuse consultation or
            validation of the prescription if the Registered Medical
            Practitioner is of the opinion that a physical consultation is
            required before a prescription is validated. User agrees that the
            Registered Medical Practitioner will use the technology of Nirog
            Health Plus for rendering the above services and they share the
            information with Nirog Health Plus of its affiliated partners for
            rendering the services.
          </li>
          <li>
            By downloading, registering and accepting the terms and conditions,
            User can book an appointment with a registered Medical practitioner
            (Physician) and discuss their health-related issues, opinion on the
            diagnostic reports, obtain e-prescription for their medical needs
            and avail other healthcare services. E-Consultation can be provided
            by teleconsultation, video conferencing or otherwise in other media
            that Nirog Health Plus may at its sole discretion provide the
            options to the User based on the availability of the Physicians.
            Please note that E-Consultation services are provided at the express
            consent by the User and the same shall not be construed as a
            replacement for physical consultation. User is warned not to use
            E-Consultation Services in times of emergency and any medical
            Services that require further diagnostics and physical inspection.
          </li>
          <li className="my-3">
            User agrees that E-Consultation is not a substitute for physical
            consultation and the Services are meant for general consultation
            only. If after the E-consultation, the Physician recommends any
            diagnostic tests to be undertaken or issues a Prescription, the same
            are provided based on the information and preliminary examination by
            the Physician, hence the same shall not be treated as accurate,
            final and conclusive. Physician reserves their rights to modify the
            prescription or recommended diagnostic tests if the User provides
            any additional information in future consultation. While rendering
            E-Consultation, Physician will require to know the age, sex,
            previous and existing health conditions, symptoms, past medical
            history, physical examination (if directed by the Physician) etc.
            All the information with respect to the User History, consultation
            records, medical record and prescriptions issued by the Physician
            will be saved with Nirog Health Plus and the same may be used for
            rendering Services as may be required by the User from time to time.
            Services offered by Nirog Health Plus are intended for direct use of
            the User or their family members, but the same cannot be used for
            rendering Services to third parties. Nirog Health Plus acts as an
            intermediary to provide technology and applications to connect the
            User and the Physician on one platform and does not endorse,
            recommend or authorize the action of any Physician. Any claims or
            liabilities arising from the use of the Services between the User
            and the Physician shall be resolved directly by User and the
            Physician. Nirog Health Plus disclaims all liabilities arising from
            use of the Services by the User and the maximum liability of Nirog
            Health Plus to the User shall be limited to the Service Fees paid by
            the User to Nirog Health Plus.
          </li>
          <li>
            Before booking a consultation, User shall ensure that the language
            in which the User prefers the consultation is available in the
            Services. User does not have the ability to choose a Physician of
            his/her choice. Nirog Health Plus will direct the request for
            E-consultation to the Physician available in the specialized
            subject. Nirog Health Plus does not endorse or refer any doctor for
            E-consultation. During the process, User has the choice to either
            proceed with the Consultation or withdraw from the Services.
            Acceptance of the Terms and Condition and booking an appointment by
            the User entitles Nirog Health Plus to unconditionally store and
            share all the information of User with the Physician and store such
            information and/or conversation of the User with Nirog Health Plus
            or the Physician. All the information stored by Nirog Health Plus
            will be secured and maintained in compliance with the laws
            applicable in Bangladesh.
          </li>
          <li className="my-3">
            In any event, if the User is unable to avail the E-Consultation
            Services from the Physician or if the User wishes to cancel the
            appointment, User shall be entitled to do so by choosing the
            respective option in the App. If User does not avail the Services or
            cancels the E-Consultation, the Services Fees will be refunded to
            the User as per the Refund Policy in the same manner of payment in
            which the User has paid the Service Fees. However, if the User
            refuses to take the call when the Physician calls the User or
            cancels the appointment one hour before the scheduled time, the User
            will not be entitled to any refund. Any issues with regard to
            payments made, refunds claimed, or otherwise errors in the
            transactions shall be reported to info@Nirog.com or call the
            customer care at +880 1884-442022.
          </li>
          <li>
            User shall make the necessary payments using the various payment
            options as per the fees applicable and charged by the respective
            Physician. Upon receipt of the payment from the User, Nirog Health
            Plus will schedule the appointment with a qualified Physician and
            send a confirmation by app notification, SMS, or email.
          </li>
          <li className="my-3">
            Under the subscription model, User can subscribe for his/her/its
            medicine requirement by choosing a subscription plan that best suits
            the User's need and the User can customize the same according to the
            User's requirement. All orders under the subscription plan shall be
            automatically placed in accordance with the chosen subscription
            plan. All orders under the subscription model shall be subject to
            all the other applicable terms and conditions mentioned herein along
            with all statutory requirements for processing an order.
          </li>
          <li>
            Any ongoing offer or promotional scheme cannot be clubbed with the
            orders placed under the subscription plan. All orders under the
            subscription plan shall be processed based on the standard discount
            and promotional scheme available at the time of processing any order
            under the subscription plan. Nirog Health Plus shall have the sole
            discretion to decide with regard to the offers or scheme that shall
            be applicable to the orders placed under the subscription plans.
          </li>
          <li className="my-3">
            If any of the items on your prescription are not available or are
            not suitable for dispensing through this service, we will try to
            contact you using the information in your registration to inform you
            about the same.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Terms;
