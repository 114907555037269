import React from "react";
import icon from "../../../Assets/location.png";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const UserOrderCard = ({ order }) => {
  return (
    <Link to={`/userDashboard/myOrders/order/${order?._id}`}>
      <div className="shadow p-2 rounded-md hover:shadow-primary lg:px-5 px-3 hover:cursor-pointer">
        {order?.address ? (
          <div className="border-b pb-1 flex items-center gap-4 justify-between">
            <img className="w-8" src={icon} alt="item" />
            <div className="flex justify-between w-full items-center">
              <p className="text-[13px]">
                {order?.division} - {order?.district} - {order?.upazila} - {order?.address}
              </p>
            </div>
          </div>
        ) : (
          <Skeleton height={20} />
        )}

        <div className="flex justify-between gap-5 py-3 md:text-base text-sm">
          <div className="space-y-1">
            <p>Order Id</p>
            <p>Date</p>
            <p>Status</p>
            <p className="font-semibold">Amount Payable</p>
          </div>
          <div className="space-y-1">
            <p className="font-semibold">
              {order?._id ? `# ${order?._id}` : <Skeleton width={100} />}
            </p>
            <p>{order?.date ? order?.date : <Skeleton width={100} />}</p>
            <button className="bg-primary text-white px-3 py-1 rounded-lg">
              {order?.status ? order?.status : <Skeleton width={100} />}
            </button>
            <p className="font-semibold">
              {order?.totalAmount ? order?.totalAmount : <Skeleton width={100} />}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default UserOrderCard;
