import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import icon from "../../../Assets/location.png";
import AuthUser from "../../../Hooks/authUser";
import UpdateHooks from "../../../Hooks/UpdateHooks";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyAccount = () => {
  const { userInfo } = AuthUser();
  const [formData, setFormData] = useState({
    name: "",
    division: "Barishal",
    upazila: "",
    district: "",
    address: "",
    number: "",
  });
  const [location, setLocation] = useState([]);

  useEffect(() => {
    fetch("../location.json")
      .then((res) => res.json())
      .then((data) => setLocation(data));
  }, []);

  useEffect(() => {
    if (userInfo?.phone) {
      fetch(
        `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
      )
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
        });
    }
  }, [userInfo]);

  const division = location?.divisions?.filter(
    (data) => data?.name === formData?.division
  );
  const district = location?.districts?.filter(
    (data) => data?.division_id === division[0]?.id
  );
  const selectedDistrict = district?.filter(
    (data) => data?.name === formData?.district
  );
  const upazila = location?.upazilas?.filter(
    (data) => data?.district_id === selectedDistrict[0]?.id
  );

  const handleSubmit = async () => {
    if (userInfo?.phone) {
      await UpdateHooks(
        `${apiUrl}/user/updateByNum/${userInfo?.phone}`,
        formData
      );
      toast.success("Data updated!");
    } else {
      toast.error("Please login first");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="p-3">
      {formData?.address ? (
        <div className="bg-white mb-5 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between border-2 border-dotted border-primary">
          <img className="w-14" src={icon} alt="item" />
          <div className="flex justify-between w-full items-center">
            <div className="space-y-0">
              <p className="text-[14px]">{formData?.name}</p>
              <p className="text-[12px]">{formData?.phone}</p>
              <p className="text-[13px]">
                {formData?.division} - {formData?.district} - {formData?.upazila} - {formData?.address}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={100} />
      )}

      <div>
        <label className="text-sm">Full Name</label>
        {formData?.name ? (
          <input
            onChange={handleChange}
            name="name"
            required
            value={formData?.name}
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          />
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <div className="mt-1">
        <label className="text-sm">Number</label>
        {userInfo?.phone ? (
          <input
            disabled
            required
            value={userInfo?.phone}
            onChange={handleChange}
            name="number"
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          />
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <div className="mt-1">
        <label className="text-sm">Division</label>
        {formData?.division ? (
          <select
            name="division"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option disabled value="">
              Choose One
            </option>
            {location?.divisions?.map((d) => (
              <option key={d?.id} selected={d?.name === formData?.division} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <div className="mt-1">
        <label className="text-sm">District</label>
        {formData?.district ? (
          <select
            name="district"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option disabled value="">
              Choose One
            </option>
            {district?.map((d) => (
              <option key={d?.id} selected={d?.name === formData?.district} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <div className="mt-1">
        <label className="text-sm">Upazila</label>
        {formData?.upazila ? (
          <select
            name="upazila"
            required
            onChange={handleChange}
            className="w-full rounded-md focus:outline-none bg-white border-2 px-2 py-2.5"
          >
            <option disabled value="">
              Choose One
            </option>
            {upazila?.map((d) => (
              <option key={d?.id} selected={d?.name === formData?.upazila} value={d?.name}>
                {d?.name}
              </option>
            ))}
          </select>
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <div className="mt-1">
        <label className="text-sm">Address</label>
        {formData?.address ? (
          <input
            onChange={handleChange}
            value={formData?.address}
            name="address"
            required
            type="text"
            className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
          />
        ) : (
          <Skeleton height={36} />
        )}
      </div>

      <button
        onClick={handleSubmit}
        className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white font-semibold hover:scale-105 duration-300"
      >
        Update Profile!
      </button>
    </div>
  );
};

export default MyAccount;
