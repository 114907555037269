import React, { useContext, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import ItemsCard from "../../Shared/ItemsCard";
import LocationModal from "../../Shared/LocationModal";
import CheckOutModal from "../../Shared/CheckOutModal";
import MyContext from "../../Context/MyContext";
import axios from "axios";
import { toast } from "react-toastify";
import AuthUser from "../../Hooks/authUser";
import LoginRegisterModal from "../../Shared/LoginRegisterModal/LoginRegisterModal";
import { IoLocationSharp } from "react-icons/io5";
import DOMPurify from "dompurify";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const FloatingCart = ({ openCart, setOpenCart }) => {
  const { userInfo } = AuthUser();
  const [isOpen, setIsOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [u, setU] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { refetch } = useContext(MyContext);
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder")) || [];
    setItems(existingOrder);
  }, [isOpen, refetch]);

  useEffect(() => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder")) || [];
    setData(existingOrder);
    let totalPrice = existingOrder.reduce(
      (acc, item) => acc + item.onePrice * item.quantity,
      0
    );
    if (totalPrice) {
      setTotal(totalPrice);
    }
  }, [isOpen, refetch, refresh]);

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [isOpen, userInfo]);

  useEffect(() => {
    fetch(`${apiUrl}/shipping/getShippings`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setShipping(data?.data[0]);
        }
      });
  }, []);

  useEffect(() => {
    async function fetchCoupons() {
      try {
        const response = await axios.get(`${apiUrl}/coupon/getCoupons`);
        setCoupons(response?.data?.data);
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchCoupons();
  }, [isOpen]);

  const handelDiscount = () => {
    const sanitizedCoupon = DOMPurify.sanitize(coupon);
    const exist = coupons.find((c) => c.code === sanitizedCoupon);
    if (data.length) {
      let totalPrice = data.reduce(
        (acc, item) => acc + item.onePrice * item.quantity,
        0
      );

      if (Number(exist?.discount)) {
        setTotal(totalPrice - (totalPrice * Number(exist?.discount)) / 100);
        setDiscount((totalPrice * Number(exist?.discount)) / 100);
      }
    } else {
      toast.error("Medicine Add to cart!");
    }
  };

  const tax = ((total + discount) * shipping?.tax) / 100;
  const payableAmount =
    u?.division === "Dhaka"
      ? Number(shipping?.insideDhaka) + Number(total) + Number(tax)
      : Number(shipping?.outsideDhaka) + Number(total) + Number(tax);
  const formattedTotal = (total + discount).toFixed(2);

  const handleProceedToCheckout = () => {
    if (items.length === 0) {
      toast.error("Your cart is empty!");
      return;
    }
    if (userInfo?.phone) {
      setOpenCheckout(!openCheckout);
    } else {
      toast.error("Please login!");
      setOpenLogin(true);
    }
  };

  return (
    <div className="bg-[#FAFAFA] w-[365px] text-white text-lg z-50 fixed right-0 top-16 h-full shadow-2xl border-t rounded-l-lg overflow-hidden">
      <div className="bg-primary text-white flex justify-between items-center p-2">
        <h2 className="font-semibold">Cart Items</h2>
        <h2 onClick={() => setOpenCart(!openCart)}>
          <Icon
            className="text-2xl border rounded-full p-[3px] cursor-pointer"
            icon="octicon:x-12"
          />
        </h2>
      </div>

      <div className="h-[66%] overflow-auto main-navigation">
        {items.map((item) => (
          <div key={item._id}>
            <ItemsCard item={item} refresh={refresh} setRefresh={setRefresh} />
          </div>
        ))}

        <div className="p-2 flex items-center gap-3">
          <input
            onChange={(e) => setCoupon(e.target.value)}
            placeholder="enter coupon"
            className="text-sm px-2 py-1.5 border-2 border-primary border-dotted rounded-md w-full focus:outline-none text-black"
            type="text"
            name="coupon"
          />
          <button
            onClick={handelDiscount}
            className="text-white bg-primary h-fit text-sm py-1.5 rounded-md px-3 hover:scale-105 duration-300 hover:bg-primary/90"
          >
            Apply
          </button>
        </div>

        <div className="bg-white m-2 mt-3 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between border-2 border-dotted border-primary">
          <div className="space-y-0">
            <p className="text-[14px] -mb-1">Subtotal</p>
            <p className="text-[14px] -mb-1">Tax</p>
            <p className="text-[14px] -mb-1">Discount applied</p>
            <p className="text-[14px] -mb-1">1-5 Days delivery</p>
            <p className="text-[14px] -mb-1 text-primary font-semibold">
              Amount Payable
            </p>
          </div>

          <div className="space-y-0 text-primary font-semibold">
            <p className="text-[14px] -mb-1 text-green-500">
              ৳ +{formattedTotal}
            </p>
            <p className="text-[14px] -mb-1 text-green-500">৳ +{tax}</p>
            <p className="text-[14px] -mb-1 text-red-500">
              ৳ -{discount?.toFixed(2)}
            </p>
            <p className="text-[14px] -mb-1 text-green-500">
              ৳ +
              {u?.division === "Dhaka"
                ? shipping?.insideDhaka?.toFixed(2)
                : shipping?.outsideDhaka?.toFixed(2)}
            </p>
            <p className="text-[14px] -mb-1 text-green-500">
              ৳ {payableAmount?.toFixed(2)}
            </p>
          </div>
        </div>

        <div className="bg-white m-2 mt-3 rounded-md p-2 text-gray-700 flex items-center gap-4 justify-between border-2 border-dotted border-primary">
          <IoLocationSharp size={40} className=" text-red-500" />
          <div className="flex justify-between w-full items-center">
            <div className="space-y-0">
              <p className="text-[14px] -mb-1">{u?.name ? u?.name : "Name:"}</p>
              <p className="text-[12px] -mb-1">
                {u?.phone ? u?.phone : "Phone:"}
              </p>
              <p className="text-[12px] -mt-1">
                {u?.address ? u?.address : "Address:"}
              </p>
            </div>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white bg-primary h-fit text-sm py-1.5 rounded-md px-3 hover:scale-105 duration-300 hover:bg-primary/90"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="w-full p-4">
        <button
          onClick={handleProceedToCheckout}
          className="p-3 uppercase bg-primary rounded-full w-full text-sm font-semibold hover:scale-105 hover:bg-primary/90 duration-300"
        >
          Proceed to checkout
        </button>
      </div>
      <LocationModal setIsOpen={setIsOpen} isOpen={isOpen} />
      <CheckOutModal
        setOpenCheckout={setOpenCheckout}
        openCheckout={openCheckout}
        products={items}
        amount={payableAmount}
        user={u}
      />
      <LoginRegisterModal setOpenLogin={setOpenLogin} openLogin={openLogin} />
    </div>
  );
};

export default FloatingCart;
