import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import hiringcover from "../../Assets/hiring team.webp"
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Career = () => {
  const [show, setShow] = useState("All Departments");
  const [jobPosts, setJobPosts] = useState([]);

  useEffect(() => {
    const fetchJobPosts = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/jobPosts/getJobPosts`
        );
        setJobPosts(response.data.data || []);
      } catch (error) {
        console.error("Error fetching job posts:", error.message);
      }
    };

    fetchJobPosts();
  }, []);

  const departments = [...new Set(jobPosts.map((job) => job.department))];

  return (
    <div>
      <div className=" shadow-md p-5">
        <img
          className="lg:h-96 w-full"
          src={hiringcover}
          alt=""
        ></img>
      </div>

      <div className="flex md:gap-5 mt-8 md:m-20">
        <div className="md:w-1/4 px-2  border-r border-r-black">
          <h1 className="font-bold text-gray-600 mb-8">Departments</h1>
          <div className="flex flex-col gap-8 text-start font-semibold">
            <button
              onClick={() => setShow("All Departments")}
              className={`text-start focus:text-primary focus:font-extrabold  ${
                show === "All Departments" ? "text-primary font-extrabold" : ""
              }`}
            >
              All Departments ({jobPosts.length})
            </button>
            {departments.map((department) => (
              <button
                key={department}
                onClick={() => setShow(department)}
                className={`text-start focus:text-primary focus:font-extrabold ${
                  show === department ? "text-primary font-extrabold" : ""
                }`}
              >
                {department} (
                {jobPosts.filter((job) => job.department === department).length}
                )
              </button>
            ))}
          </div>
        </div>

        <div className="md:w-3/4 px-3">
          <h1 className="font-bold text-gray-600 mb-8">FEATURED ROLES</h1>
          {show === "All Departments" && (
            <div className="flex flex-col gap-8">
              {jobPosts.map((job) => (
                <div key={job._id}>
                  <Link to={`/job-details/${job._id}`}>
                    <h1 className="md:text-2xl text-primary">{job.jobTitle}</h1>
                  </Link>

                  <p className="my-[2px]">
                    {job.department} | {job.jobType}
                  </p>
                  <p>{job.location}</p>
                </div>
              ))}
            </div>
          )}

          {show !== "All Departments" && (
            <div>
              {jobPosts
                .filter((job) => job.department === show)
                .map((job) => (
                  <div key={job._id}>
                    <Link to={`/job-details/${job._id}`}>
                      <h1 className="md:text-2xl text-primary">
                        {job.jobTitle}
                      </h1>
                    </Link>{" "}
                    <p className="my-[2px]">
                      {job.department} | {job.jobType}
                    </p>
                    <p>{job.location}</p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Career;
