import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Faq = () => {
  const [isOpenElement1, setIsOpenElement1] = useState(false);
  const [isOpenElement2, setIsOpenElement2] = useState(false);
  const [isOpenElement3, setIsOpenElement3] = useState(false);
  const [isOpenElement5, setIsOpenElement5] = useState(false);
  const [isOpenElement6, setIsOpenElement6] = useState(false);
  const [isOpenElement7, setIsOpenElement7] = useState(false);

  const toggleElement1 = () => {
    setIsOpenElement1(!isOpenElement1);
  };

  const toggleElement2 = () => {
    setIsOpenElement2(!isOpenElement2);
  };

  const toggleElement3 = () => {
    setIsOpenElement3(!isOpenElement3);
  };

  const toggleElement5 = () => {
    setIsOpenElement5(!isOpenElement5);
  };

  const toggleElement6 = () => {
    setIsOpenElement6(!isOpenElement6);
  };

  const toggleElement7 = () => {
    setIsOpenElement7(!isOpenElement7);
  };

  return (
    <div className="mx-10">
      <h1 className="text-center text-4xl font-bold pb-2">FAQ</h1>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement1}>
            Medicine and Healthcare Orders
          </button>
          {isOpenElement1 && (
            <div>
              <h1 className="font-bold mt-8">When will I receive my order?</h1>
              <p>
                Your order will be delivered within estimated time shown to you
                while ordering.
              </p>

              <h1 className="font-bold mt-3">I have received damaged items.</h1>
              <p>
                We are sorry you had to experience this. Please do not accept
                the delivery of that order and let us know what happened
              </p>
              <h1 className="font-bold mt-3">
                Items are different from what I ordered.
              </h1>
              <p>
                We are sorry you have had to experience this. Please do not
                accept it from rider. Reject the order straightaway and call to
                Nirog Health + customer care
              </p>
              <h1 className="font-bold mt-3">
                What if Items are missing from my order.
              </h1>
              <p>
                In no circumstances, you should receive an order that is
                incomplete. Once rider reaches your destination, be sure to
                check expiry date of medicines and your all ordered items was
                delivered.
              </p>
              <h1 className="font-bold mt-3">How do I cancel my order?</h1>
              <p>
                Please call us with your order ID and we will cancel it for you.
                or you can cancel from my orders option as well.
              </p>
              <h1 className="font-bold mt-3">I want to modify my order.</h1>
              <p>
                Sorry, once your order is confirmed, it cannot be modified.
                Please place a fresh order with any modifications.
              </p>
              <h1 className="font-bold mt-3">
                What is the shelf life of medicines being provided?
              </h1>
              <p>
                We ensure that the shelf life of the medicines being supplied by
                our partner retailers is, at least, a minimum of 3 months from
                the date of delivery.
              </p>
            </div>
          )}
        </div>
        <div onClick={toggleElement1} className="text-3xl">
          {isOpenElement1 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement2}>
            Delivery
          </button>
          {isOpenElement2 && (
            <div>
              <h1 className="font-bold mt-8">When will I receive my order?</h1>
              <p>
                Your order will be delivered within the Estimated Delivery Date.
              </p>

              <h1 className="font-bold mt-3">
                Order status showing delivered but I have not received my order.
              </h1>
              <p>
                Sorry that you are experiencing this. Please call to connect
                with us immediately.
              </p>
              <h1 className="font-bold mt-3">
                Which cities do you operate in?
              </h1>
              <p>We provide healthcare services all over Bangladesh now</p>
              <h1 className="font-bold mt-3">
                How can I get my order delivered faster?
              </h1>
              <p>
                We have both Express delivery and Regular delivery option
                available now. If you need it urgently please select Express
                delivery.
              </p>
              <h1 className="font-bold mt-3">
                Can I modify my address after Order placement?
              </h1>
              <p>
                Sorry, once the order is placed, we are unable to modify the
                address.
              </p>
              <h1 className="font-bold mt-3">
                What is the meaning of Delivered status?
              </h1>
              <p>
                When rider reaches your destination and hand over the products
                to you the delivery status changes to 'Delivered'. Status
                'Delivered' also means Nirog Health + has already collected the
                payment from you via cash or online payment.
              </p>
            </div>
          )}
        </div>
        <div onClick={toggleElement2} className="text-3xl">
          {isOpenElement2 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement3}>
            Payments
          </button>
          {isOpenElement3 && (
            <div>
              <h1 className="font-bold mt-8">
                How do customers get discounts?
              </h1>
              <p>
                We deduct the value from every medicine and show it to you
                before order, so that you can see what you are really paying for
                each medicine.
              </p>
              <h1 className="font-bold mt-3">When will I get my refund?</h1>
              <p>
                Refund will be credited in Nirog Health + cash (3-5 business
                days)
              </p>
              <h1 className="font-bold mt-3">
                I did not receive cashback for my order.
              </h1>
              <p>
                Please read the T&C of the offer carefully for the eligibility
                of cashback.
              </p>
              <h1 className="font-bold mt-3">
                What are the payment modes at Nirog Health +?
              </h1>
              <p>
                Cash on Delivery (COD) and Online payment method Bkash, Nagad,
                Cards etc.
              </p>
            </div>
          )}
        </div>
        <div onClick={toggleElement3} className="text-3xl">
          {isOpenElement3 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement5}>
            Nirog Cash
          </button>
          {isOpenElement5 && (
            <div>
              <h1 className="font-bold mt-8">What is Nirog Health + cash?</h1>
              <p>
                This is a virtual wallet to store Nirog Health + Cash in your
                account.
              </p>

              <h1 className="font-bold mt-3">
                How do I check my Nirog Health + cash balance?
              </h1>
              <p>You can check your Nirog Health + cash in Account screen.</p>
              <h1 className="font-bold mt-3">
                When will the Nirog Health + money expire?
              </h1>
              <p>
                Any Nirog Health + Cash deposited in your Nirog Health + wallet
                through returns will never expire. At times, our marketing team
                may deposit promotional cash which will have an expiry that is
                communicated to you via an SMS.
              </p>
              <h1 className="font-bold mt-3">
                Can I add money to my Nirog Health + cash?
              </h1>
              <p>
                No, you are unable to transfer or add money to your Nirog Health
                + cash.
              </p>
              <h1 className="font-bold mt-3">
                How can I redeem my Nirog Health + cash?
              </h1>
              <p>
                If you have any money in your Nirog Health + cash, it will be
                automatically deducted from your next order amount and you will
                only have to pay for the balance amount (if any).
              </p>
              <h1 className="font-bold mt-3">
                Can I transfer money from my Nirog Health + cash to the bank
                account?
              </h1>
              <p>
                No, you are unable to transfer money from your Nirog Health +
                cash to the bank account.
              </p>
              <h1 className="font-bold mt-3">
                How much Nirog Health + money can I redeem in an order?
              </h1>
              <p>There is no limit for redemption of Nirog Health + cash</p>
            </div>
          )}
        </div>
        <div onClick={toggleElement5} className="text-3xl">
          {isOpenElement5 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement6}>
            Promotions
          </button>
          {isOpenElement6 && (
            <div>
              <h1 className="font-bold mt-8">
                How do I apply a coupon code on my order?
              </h1>
              <p>
                You can apply a coupon on the cart screen while placing an
                order. If you are getting a message that the coupon code has
                failed to apply, it may be because you are not eligible for the
                offer.
              </p>

              <h1 className="font-bold mt-3">
                Does Nirog Health + offer return of the medicine?
              </h1>
              <p>
                Yes, Nirog Health + does accept returns of the medicine from
                customers. We have a 7 days return policy for eligible items.
                Please check our return section for more details.
              </p>
            </div>
          )}
        </div>
        <div onClick={toggleElement6} className="text-3xl">
          {isOpenElement6 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>

      <div className="border flex justify-between p-5">
        <div>
          <button className="w-full text-start" onClick={toggleElement7}>
            Return
          </button>
          {isOpenElement7 && (
            <div>
              <h1 className="font-bold mt-8">
                How does Nirog Health +’s return policy work?
              </h1>
              <p>
                Nirog offers a flexible return policy for items ordered with us.
                Under this policy, unopened and unused items must be returned
                within 7 days from the date of delivery. The return window will
                be listed in the returns section of the order, once delivered.
                Items are not eligible for return under the following
                circumstances: - If items have been opened, partially used or
                disfigured. Please check the package carefully at the time of
                delivery before opening and using. - If the item’s packaging/box
                or seal has been tampered with. Do not accept the delivery if
                the package appears to be tampered with. - If it is mentioned on
                the product details page that the item is non-returnable. - If
                the return window for items in an order has expired. No items
                can be returned after 7 days from the the delivery date. - If
                any accessories supplied with the items are missing. - If the
                item does not have the original serial number/UPC number/barcode
                affixed, which was present at the time of delivery. - If there
                is any damage/defect which is not covered under the
                manufacturer's warranty. - If the item is damaged due to visible
                misuse. - Any refrigerated items like insulin or products that
                are heat sensitive are non-returnable. - Items related to baby
                care, food & nutrition, healthcare devices and sexual wellness
                such as but not limited to diapers, health drinks, health
                supplements, glucometers, glucometer strips/lancets, health
                monitors, condoms, pregnancy/fertility kits, etc.
              </p>

              <h1 className="font-bold mt-3">
                Do you sell medicine strips in full or it can be single units
                too?
              </h1>
              <p>
                We sell in single units to give customers flexibility in
                selecting specific amounts of medicine required. We provide
                single units of medicine as our pharmacist can cut strips.
              </p>
              <h1 className="font-bold mt-3">
                I have broken the seal, can I return it?
              </h1>
              <p>No, you can not return any items with a broken seal.</p>
              <h1 className="font-bold mt-3">
                Can I return medicine that is partially consumed?
              </h1>
              <p>
                No, you cannot return partially consumed items. Only unopened
                items that have not been used can be returned.
              </p>
              <h1 className="font-bold mt-3">
                Can I ask for a return if the strip is cut?
              </h1>
              <p>
                We provide customers with the option of purchasing medicines as
                single units. Even if ordering a single tablet of paracetamol,
                we can deliver that. It is common to have medicines in your
                order with some strips that are cut. If you want to get a full
                strip in your order, please order a full strip amount and you
                will get it accordingly. If you do not order a full strip, you
                will get cut pieces. If you have ordered 4 single units which
                are cut pieces and want to return, all 4 pieces must be
                returned. We do not allow partial return of 1 or 2 pieces.
              </p>
            </div>
          )}
        </div>
        <div onClick={toggleElement7} className="text-3xl">
          {isOpenElement7 ? (
            <MdKeyboardArrowUp />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </div>
      </div>
    </div>
  );
};

export default Faq;
