import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ManageStockModal = ({ isOpen, setIsOpen, product, refetch, setRefetch }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  let date = moment().format("YYYY-MM-D");
  const [formData, setFormData] = useState({
    sellerId: "",
    note: "",
    stock: 0,
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.get(
          `${apiUrl}/user/specific?fieldName=role&fieldValue=seller`
        );
        setUsers(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  useEffect(() => {
    async function fetchUserById() {
      if (formData?.sellerId) {
        try {
          const response = await axios.get(
            `${apiUrl}/user/getUsersById/${formData?.sellerId}`
          );
          setUser(response?.data?.data);
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (isOpen) {
      fetchUserById();
    }
  }, [formData?.sellerId, isOpen]);

  const handleSubmit = async () => {
    if (user?._id) {
      try {
        const stock = Number(product?.stock);
        if (stock >= formData?.stock) {
          const response = await fetch(`${apiUrl}/stock/addStocks`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...formData,
              date,
              product: product,
              seller: user,
            }),
          });

          if (response.ok) {
            const old = user?.stocks?.filter(
              (item) => item?.productId !== product?._id
            );
            const exist = user?.stocks?.find(
              (item) => item?.productId === product?._id
            );
            let oldStock = exist?.stock ? Number(exist?.stock) : 0;
            await UpdateHooks(`${apiUrl}/user/updateUsers/${user?._id}`, {
              stocks: [
                ...old,
                {
                  productId: product?._id,
                  stock: oldStock + Number(formData?.stock),
                  productName: product?.name,
                  seller: user?.name,
                },
              ],
            });
            await UpdateHooks(
              `${apiUrl}/product/updateProducts/${product?._id}`,
              { stock: Number(product?.stock) - Number(formData?.stock) }
            );
            setRefetch(!refetch);
            toast.success("Seller Stock Updated");
            setIsOpen(!isOpen);
          } else {
            toast.error("Something went wrong");
          }
        } else {
          toast.error("You don't have enough stock");
        }
      } catch (error) {
        console.error("Error creating order:", error.message);
      }
    } else {
      toast.error("Seller Not Found!");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Manage Stock</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="m-3 p-3">
                  <div className="">
                    <label className="text-sm">Product Name</label>
                    <input
                      disabled
                      value={product?.name}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="">
                    <label className="text-sm">Available stock</label>
                    <input
                      disabled
                      value={product?.stock}
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Seller</label>
                    <select
                      name="sellerId"
                      onChange={handleChange}
                      value={formData?.sellerId}
                      className="border p-2 w-full bg-white rounded-md"
                      required
                    >
                      <option className="font-thin" value="" disabled>
                        Select Seller
                      </option>
                      {users?.map((u) => (
                        <option key={u?._id} value={u?._id}>
                          {u?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-1">
                    <label className="text-sm">Stock</label>
                    <input
                      required
                      value={formData?.stock}
                      onChange={handleChange}
                      name="stock"
                      type="number"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></input>
                  </div>

                  <div className="mt-1">
                    <label className="text-sm">Note</label>
                    <textarea
                      onChange={handleChange}
                      value={formData?.note}
                      name="note"
                      type="text"
                      className="w-full rounded-md focus:outline-none bg-white border-2 p-2"
                    ></textarea>
                  </div>

                  <button
                    onClick={handleSubmit}
                    className="bg-primary w-fit mt-5 py-2 px-4 rounded-md text-white font-semibold hover:scale-105 duration-300"
                  >
                    Give Stock
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManageStockModal;
