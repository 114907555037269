import React, { useEffect, useState } from "react";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerAccountManage = () => {
  const { userInfo } = AuthUser();
  const [u, setU] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  return (
    <div>
      <div class=" ">
        <h1 class="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Seller Account Details
        </h1>
        <p class="text-lg text-gray-800 mb-8">
          Explore essential Account Information for Seller.
        </p>
      </div>
      <div className=" px-10 shadow-xl p-5 rounded-md text-sm">
        <form className="mt-5">
          <div>
            <label>Seller Name </label>
            <input
              className="p-5 border w-full border-gray-400 rounded hover:border-black duration-300 outline-none "
              type="text"
              name="name"
              value={u?.name}
              placeholder="Full Name"
              disabled
            ></input>
          </div>
          <div>
            <label>Seller Phone Number</label>
            <input
              className="p-5 border w-full border-gray-400 rounded  outline-none"
              type="text"
              disabled
              name="phone"
              value={u?.phone}
              placeholder="+880125452252"
            ></input>
            <p className="text-sm text-gray-400">
              Mobile number cannot be changed
            </p>
          </div>

          <div>
            <label>Seller Division</label>
            <input
              className="p-5 border  w-full border-gray-400  rounded hover:border-black duration-300  outline-none"
              type="text"
              value={u?.division}
              disabled
            ></input>
          </div>

          <div>
            <label>Seller District</label>
            <input
              className="p-5 border  w-full border-gray-400  rounded hover:border-black duration-300  outline-none"
              type="text"
              value={u?.district}
              disabled
            ></input>
          </div>
          <div>
            <label>Seller Upazila</label>
            <input
              className="p-5 border  w-full border-gray-400  rounded hover:border-black duration-300  outline-none"
              type="text"
              value={u?.upazila}
              disabled
            ></input>
          </div>
          <div>
            <label>Seller Address</label>
            <input
              className="p-5 border  w-full border-gray-400  rounded hover:border-black duration-300  outline-none"
              type="text"
              value={u?.address}
              disabled
            ></input>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SellerAccountManage;
