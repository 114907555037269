import React from 'react';
import MyOrders from './MyOrder/MyOrders';

const UserDashboardIndex = () => {
    return (
        <>
            <MyOrders></MyOrders>
        </>
    );
};

export default UserDashboardIndex;