import React, { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import Pagination from "../../../Shared/Pagination";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { Icon } from "@iconify/react";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AdminOrders = () => {
  const [nav, setNav] = useState("pending");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const { userInfo } = AuthUser();
  const [refetch, setRefetch] = useState(false);
  const [u, setU] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    const url = `${apiUrl}/order/specific?fieldName=${"status"}&&fieldValue=${nav}&&size=${size}&&page=${page}`;
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setFilterData(data?.data);
          setQuantity(data?.total);
        });
    } catch (err) {
      toast.error("something wrong");
    }
  }, [nav, refetch, u, page, size]);

  const handelUpdate = async (status, id) => {
    await UpdateHooks(`${apiUrl}/order/updateOrders/${id}`, {
      status: status,
    });
    toast.success(`Order status is ${status} `);
    setRefetch(!refetch);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const n = e.target.name.value;
    const fromDate = e.target.formDate.value;
    const toDate = e.target.toDate.value;

    const results = data?.filter((d) => {
      return (
        (d?.user?.name
          ? d?.user?.name.toLowerCase()?.includes(n?.toLowerCase())
          : true) &&
        (fromDate && fromDate
          ? moment(d?.date).isBetween(fromDate, toDate)
          : true)
      );
    });
    setFilterData(results);
  };

  const totalPendingOrderAmount = data
    .filter((item) => item.status === "pending")
    .reduce((acc, item) => acc + item.totalAmount, 0);

  const totalConfirmOrderAmount = data
    .filter((item) => item.status === "confirm")
    .reduce((acc, item) => acc + item.totalAmount, 0);

  const totalDeliveringOrderAmount = data
    .filter((item) => item.status === "delivering")
    .reduce((acc, item) => acc + item.totalAmount, 0);

  const totalDeliveredOrderAmount = data
    .filter((item) => item.status === "delivered")
    .reduce((acc, item) => acc + item.totalAmount, 0);

  const totalRejectOrderAmount = data
    .filter((item) => item.status === "reject")
    .reduce((acc, item) => acc + item.totalAmount, 0);

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 ">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-yellow-500">
            <Icon className="h-12 w-12 text-white" icon="carbon:product" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Pending Order</h3>
            <p className="text-sm">
              {data.filter((item) => item.status === "pending").length}
            </p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-500">
            <Icon className="h-12 w-12 text-white" icon="mdi:company" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider"> Confirm Order </h3>
            <p className="text-sm">
              {data.filter((item) => item.status === "confirm").length}
            </p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-blue-500">
            <Icon className="h-12 w-12 text-white" icon="mdi:company" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider"> Delivering Order </h3>
            <p className="text-sm">
              {data.filter((item) => item.status === "delivering").length}
            </p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-blue-800">
            <Icon className="h-12 w-12 text-white" icon="mdi:company" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider"> Delivered Order </h3>
            <p className="text-sm">
              {data.filter((item) => item.status === "delivered").length}
            </p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-red-500">
            <Icon className="h-12 w-12 text-white" icon="mdi:company" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider"> Reject Order </h3>
            <p className="text-sm">
              {data.filter((item) => item.status === "reject").length}
            </p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-yellow-500">
            <Icon className="h-12 w-12 text-white" icon="bxs:category-alt" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Pending Order Amount</h3>
            <p className="text-sm">{totalPendingOrderAmount.toFixed(2)} BDT</p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-green-500">
            <Icon
              className="h-12 w-12 text-white"
              icon="material-symbols:category-outline"
            />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Confirm Order Amount</h3>
            <p className="text-sm">{totalConfirmOrderAmount.toFixed(2)} BDT</p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-blue-500">
            <Icon
              className="h-12 w-12 text-white"
              icon="material-symbols:category-outline"
            />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Delivering Order Amount</h3>
            <p className="text-sm">
              {totalDeliveringOrderAmount.toFixed(2)} BDT
            </p>
          </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-blue-800">
            <Icon
              className="h-12 w-12 text-white"
              icon="material-symbols:category-outline"
            />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Delivered Order Amount</h3>
            <p className="text-sm">
              {totalDeliveredOrderAmount.toFixed(2)} BDT
            </p>
          </div>
        </div>

        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
          <div className="p-4 bg-red-500">
            <Icon
              className="h-12 w-12 text-white"
              icon="material-symbols:category-outline"
            />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-xs tracking-wider">Reject Order Amount</h3>
            <p className="text-sm">{totalRejectOrderAmount.toFixed(2)} BDT</p>
          </div>
        </div>
      </div>

      <div className="flex text-xs list-none gap-3 justify-center shadow rounded-lg px-2 py-4">
        <button
          onClick={() => setNav("pending")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "pending"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          PENDING
        </button>
        <button
          onClick={() => setNav("confirm")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "confirm"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          CONFIRMED
        </button>
        <button
          onClick={() => setNav("delivering")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "delivering"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          DELIVERING
        </button>
        <button
          onClick={() => setNav("delivered")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "delivered"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          DELIVERED
        </button>
        <button
          onClick={() => setNav("reject")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "reject"
              ? "bg-primary text-white duration-500 transition-all"
              : "bg-primary/10"
          }`}
        >
          REJECTED
        </button>
      </div>

      <div className="pt-5">
        <form
          onSubmit={handleSearch}
          className="flex flex-wrap items-center gap-3 mb-6"
        >
          <div>
            <label className="block mb-1">Form Date</label>
            <input
              name="formDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>
          <div>
            <label className="block mb-1">To Date</label>
            <input
              name="toDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>

          <div>
            <label className="block mb-1">Name</label>
            <div className="relative ">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <span className="text-xl text-textColor">
                  <CiSearch />
                </span>
              </div>
              <input
                type="text"
                name="name"
                className="bg-[#F0FDF4] border text-gray-900 text-sm rounded-lg block w-full pl-10 px-2.5 py-3 focus:outline-none"
                placeholder="Search by name"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="text-white bg-primary px-3 py-2 rounded-md mt-6"
          >
            Search
          </button>
        </form>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 border rounded-lg">
              <tr className="py-4 rounded-lg text-xs">
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  #
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  C.Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 font-medium capitalize"
                >
                Tracking-ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                 Items
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  font-medium capitalize"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filterData.length
                ? filterData.map((item, i) => (
                    <tr
                      key={item?._id}
                      item={item}
                      className="bg-white border-b border-[#D0D2DA]"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap"
                      >
                        {i + 1}
                      </th>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <p>{item?.user?.name}</p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <p>{item?.date}</p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <p>{item?.totalAmount?.toFixed(2)} BDT</p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <p>
                          {item?.pay ? "Online payment" : "Cash on Delivery"}
                        </p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap" onClick={() => {
                        navigator.clipboard.writeText(item?.trackingId)
                        toast.success("Copy  Successfully ")
                   
                      }} style={{cursor: "pointer", textDecoration: "underline", textDecorationColor: "#0066ff"}}>
                        <p>{item?.trackingId}</p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <p>{item?.products?.length}</p>
                      </td>
                      <td className="px-6 py-4 text-xs whitespace-nowrap">
                        <span className="flex items-center gap-3">
                          <Link
                            to={`/adminDashboard/orders/${item?._id}`}
                            title="view all products"
                            className="text-lg text-white bg-green-200 w-7 h-7 rounded-lg flex items-center justify-center"
                          >
                            <GrView />
                          </Link>
                          <select
                            onChange={(e) =>
                              handelUpdate(e?.target?.value, item?._id)
                            }
                            className="px-2 py-2 text-xs border focus:outline-none rounded-md"
                            defaultValue={item?.status}
                          >
                            <option value="pending">Pending</option>
                            <option value="confirm">Confirm</option>
                            <option value="delivering">Delivering</option>
                            <option value="delivered">Delivered</option>
                            <option value="reject">Reject</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                  ))
                : Array.from({ length: 10 }).map((_, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b border-[#D0D2DA]"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <Skeleton />
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AdminOrders;
