import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import Loading from "../../../Shared/Loading";
import Pagination from "../../../Shared/Pagination";
import SellerOrderTable from "./SellerOrderTable";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerOrders = () => {
  const [nav, setNav] = useState("pending");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const {userInfo}=AuthUser();
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [u, setU] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    setLoading(true);
    let url = `${apiUrl}/order/specific?fieldName=${"district"}&&fieldValue=${
      u?.district
    }&&fieldName2=${"division"}&&fieldValue2=${
      u?.division
    }&&fieldName3=${"upazila"}&&fieldValue3=${
      u?.upazila
    }&&fieldName4=${"status"}&&fieldValue4=${nav}&&fieldName5=${"sellerId"}&&fieldValue5=${
      u?._id
    }&&size=${size}&&page=${page}`;
    if (nav === "pending") {
      url = `${apiUrl}/order/specific?fieldName=${"district"}&&fieldValue=${
        u?.district
      }&&fieldName2=${"division"}&&fieldValue2=${
        u?.division
      }&&fieldName3=${"upazila"}&&fieldValue3=${
        u?.upazila
      }&&fieldName4=${"status"}&&fieldValue4=${nav}&&size=${size}&&page=${page}`;
    }
    try {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setFilterData(data?.data);
          setQuantity(data?.total);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error("something wrong");
    }
  }, [nav, refetch, u, page, size]);

  const handleSearch = (e) => {
    e.preventDefault();
    const n = e.target.name.value;
    const fromDate = e.target.formDate.value;
    const toDate = e.target.toDate.value;

    const results = data?.filter((d) => {
      return (
        (d?.user?.name
          ? d?.user?.name.toLowerCase()?.includes(n?.toLowerCase())
          : true) &&
        (fromDate && fromDate
          ? moment(d?.date).isBetween(fromDate, toDate)
          : true)
      );
    });
    setFilterData(results);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="">
      <div className=" flex text-xs list-none gap-3 justify-center  shadow rounded-lg px-2 py-4">
        <button
          onClick={() => setNav("pending")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "pending"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          PENDING
        </button>
        <button
          onClick={() => setNav("confirm")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "confirm"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          CONFIRMED
        </button>
        <button
          onClick={() => setNav("delivering")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "delivering"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          DELIVERING
        </button>
        <button
          onClick={() => setNav("delivered")}
          className={`cursor-pointer px-2 md:px-10 rounded-full py-2 ${
            nav === "delivered"
              ? "bg-primary text-white duration-500"
              : "bg-primary/10"
          }`}
        >
          DELIVERED
        </button>
      
      </div>

      <div className="pt-5">
        <form
          onSubmit={handleSearch}
          className="flex flex-wrap items-center   gap-3 mb-6"
        >
          <div>
            <label className="block mb-1">Form Date</label>
            <input
              name="formDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>
          <div>
            <label className="block mb-1">To Date</label>
            <input
              name="toDate"
              type="date"
              className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
            ></input>
          </div>

          <div>
            <label className="block mb-1">Name</label>
            <div className="relative ">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <span className="text-xl text-textColor">
                  <CiSearch />
                </span>
              </div>
              <input
                type="text"
                name="name"
                className="bg-[#F0FDF4] border text-gray-900 text-sm rounded-lg  block w-full pl-10 px-2.5 py-3  focus:outline-none"
                placeholder="Search by name"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="text-white bg-primary px-3 py-2 rounded-md mt-6"
          >
            Search
          </button>
        </form>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100   border  rounded-lg">
              <tr className="py-4 rounded-lg">
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Orderer Name
                </th>

                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Payment Type
                </th>

                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                 TR_ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  total Product
                </th>

                <th
                  scope="col"
                  className="px-6 py-3  text-[12px] font-medium capitalize"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filterData?.map((item, i) => (
               <SellerOrderTable i={i} item={item} u={u} refetch={refetch} setRefetch={setRefetch}/>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default SellerOrders;
