import React, { useContext, useState, useEffect } from "react";
import view from "../../Assets/card/recently_bought.webp";
import { MdFavorite } from "react-icons/md";
import { RiFileTextLine } from "react-icons/ri";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from "../../Context/MyContext";
import Skeleton from "react-loading-skeleton";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProductDetails = () => {
  const { id } = useParams();
  const { refetch, setRefetch } = useContext(MyContext);
  const [product, setProduct] = useState([]);
  const [exist, setExist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const [onePice, setOnePice] = useState(true);
  const [oneStrip, setOneStrip] = useState(false);
  const [oneBox, setOneBox] = useState(false);

  // control quantity
  const [pice, setPice] = useState(1);
  const [strip, setStrip] = useState(1);
  const [box, setBox] = useState(1);

  // without discount price
  const orgPicePrice = Number(product?.onePiecePrice) * pice;
  const orgStripPrice =
    Number(product?.oneStrip) * Number(product?.onePiecePrice) * strip;
  const orgBoxPrice =
    Number(product?.oneBox) *
    Number(product?.oneStrip) *
    box *
    Number(product?.onePiecePrice);

  // with discount price
  const picePrice =
    Number(product?.onePiecePrice) * pice -
    (Number(product?.onePiecePrice) * pice * product?.discount) / 100;
  const stripPrice =
    Number(product?.oneStrip) * Number(product?.onePiecePrice) * strip -
    (Number(product?.oneStrip) *
      Number(product?.onePiecePrice) *
      strip *
      product?.discount) /
      100;
  const boxPrice =
    Number(product?.oneBox) *
      Number(product?.oneStrip) *
      box *
      Number(product?.onePiecePrice) -
    (Number(product?.oneBox) *
      Number(product?.oneStrip) *
      box *
      Number(product?.onePiecePrice) *
      product?.discount) /
      100;

  // for cart
  const quantity = onePice ? pice : oneStrip ? strip : box;

  const onePrice =
    (onePice
      ? product?.onePiecePrice
      : oneStrip
      ? Number(product?.oneStrip) * Number(product?.onePiecePrice)
      : Number(product?.oneBox) *
        Number(product?.oneStrip) *
        Number(product?.onePiecePrice)) -
    ((onePice
      ? product?.onePiecePrice
      : oneStrip
      ? Number(product?.oneStrip) * Number(product?.onePiecePrice)
      : Number(product?.oneBox) *
        Number(product?.oneStrip) *
        Number(product?.onePiecePrice)) *
      product?.discount) /
      100;

  const type = onePice ? "Pice" : oneStrip ? "Strip" : "Box";

  useEffect(() => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder"));
    const exist = existingOrder?.find((item) => item._id === id);
    if (exist?._id) {
      setExist(exist);
    } else {
      setExist([]);
    }
  }, [id, refresh]);

  // load product data by id
  useEffect(() => {
    setLoading(true);
    try {
      fetch(`${apiUrl}/product/getProductsById/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setProduct(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      return <div>{err}</div>;
    }
  }, [id]);

  // product add to cart
  const addOrderInLocalStorage = () => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder"));

    if (existingOrder) {
      const exist = existingOrder.find((item) => item._id === id);
      if (exist) {
        const filterOrder = existingOrder.filter(
          (item) => item._id !== exist?._id
        );
        const newQuantity = exist?.quantity + quantity;
        exist.quantity = newQuantity;
        localStorage.setItem(
          "nirogOrder",
          JSON.stringify([...filterOrder, exist])
        );
        setRefresh(!refresh);
      } else {
        const newOrders = [
          ...existingOrder,
          { ...product, quantity: quantity, onePrice: onePrice, type },
        ];
        localStorage.setItem("nirogOrder", JSON.stringify(newOrders));
        setRefresh(!refresh);
      }
    } else {
      const order = JSON.stringify([
        { ...product, quantity: quantity, onePrice: onePrice, type },
      ]);
      localStorage.setItem("nirogOrder", order);
      setRefresh(!refresh);
    }
    toast.success("Medicine add into the cart");
    setRefetch(!refetch);
  };

  return (
    <div className="">
      <div className="flex justify-between mb-3 gap-2">
        <p
          onClick={handleBack}
          className="inline-flex items-center border border-indigo-300 px-5 cursor-pointer rounded-md text-indigo-500 hover:bg-indigo-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            ></path>
          </svg>
        </p>
      </div>
      <div className="grid px-5 py-7 border shadow rounded-lg grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <div>
            {loading ? (
              <Skeleton height={400} />
            ) : (
              <img
                className=" w-8 h-auto border-b-2 border-b-gray-400"
                src={product?.img}
                alt="img"
              ></img>
            )}

            {!loading && (
              <InnerImageZoom
                src={product?.img}
                zoomType="hover"
                zoomPreload={true}
                className=" w-72"
              />
            )}
          </div>
          <div className="mt-5">
            <button className="bg-primary py-1 text-center text-white font-bold text-sm tracking-widest px-6 rounded">
              Regular Delivery
            </button>
            <p className="text-xs text-center mt-5 text-primary">
              সারা বাংলাদেশ থেকে অর্ডার করা যাবে।
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-bold mb-4">
            {loading ? <Skeleton width={200} /> : product?.name}{" "}
            <small className="font-thin">
              {loading ? <Skeleton width={100} /> : product?.strength}
            </small>
          </h1>
          <div className="text-sm">
            {loading ? (
              <>
                <Skeleton width={150} />
                <Skeleton width={100} />
              </>
            ) : (
              <>
                <p>{product?.genericCategory}</p>
                <p>{product?.companyName}</p>
              </>
            )}
          </div>
          <div className="mt-5 border border-gray-400 rounded-xl">
            <div className="flex gap-3 rounded-tl-xl rounded-tr-xl bg-blue-100 p-3 items-center">
              <img className="w-5 h-5" src={view} alt=""></img>
              <p className="text-sm">Top-Selling Products.</p>
            </div>

            <div className="flex gap-5 px-5 p-2 items-center">
              <p className="text-sm font-bold font-sans">Best Price</p>
              <p className="text-xl font-bold">
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  <>
                    ৳{onePice && picePrice}
                    {oneStrip && stripPrice}
                    {oneBox && boxPrice}
                  </>
                )}
              </p>
            </div>

            {product?.discount ? (
              <div className="flex gap-5 px-5 text-xs p-2 items-center">
                <p>
                  MRP{" "}
                  <span className="line-through">
                    ৳{onePice && orgPicePrice}
                    {oneStrip && orgStripPrice}
                    {oneBox && orgBoxPrice}
                  </span>
                </p>
                <div className="bg-rose-400 px-3 py-1 text-white font-bold">
                  {product?.discount}% OFF
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="px-5">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-5">
                  <input
                    type="radio"
                    onClick={() => {
                      setOnePice(!onePice);
                      setOneStrip(false);
                      setOneBox(false);
                    }}
                    checked={onePice}
                    className="checked:outline-none checked:ring-blue-500"
                  ></input>
                  <h1 className="text-lg">1 PC</h1>
                </div>

                <div className="flex items-center">
                  <button
                    onClick={() => {
                      if (pice > 1) {
                        setPice(pice - 1);
                      }
                    }}
                    className="border rounded-md py-1 px-4 mr-2"
                  >
                    -
                  </button>
                  <span className="text-center w-8">{pice}</span>
                  <button
                    onClick={() => {
                      setPice(pice + 1);
                    }}
                    className="border rounded-md py-1 px-4 ml-2"
                  >
                    +
                  </button>
                </div>
              </div>

              {product?.medicineType === "tablet" && (
                <>
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex items-center gap-5">
                      <input
                        type="radio"
                        onClick={() => {
                          setOneStrip(!oneStrip);
                          setOnePice(false);
                          setOneBox(false);
                        }}
                        checked={oneStrip}
                        className="checked:outline-none checked:ring-blue-500"
                      ></input>
                      <h1 className="text-lg">
                        1 Strip = {product?.oneStrip} PCS
                      </h1>
                    </div>

                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          if (strip > 1) {
                            setStrip(strip - 1);
                          }
                        }}
                        className="border rounded-md py-1 px-4 mr-2"
                      >
                        -
                      </button>
                      <span className="text-center w-8">{strip}</span>
                      <button
                        onClick={() => {
                          setStrip(strip + 1);
                        }}
                        className="border rounded-md py-1 px-4 ml-2"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex items-center gap-5">
                      <input
                        type="radio"
                        onClick={() => {
                          setOneBox(!oneBox);
                          setOneStrip(false);
                          setOnePice(false);
                        }}
                        checked={oneBox}
                        className="checked:outline-none checked:ring-blue-500"
                      ></input>
                      <h1 className="text-lg">
                        1 Box = {product?.oneBox} Strips
                      </h1>
                    </div>

                    <div className="flex items-center">
                      <button
                        onClick={() => {
                          if (box > 1) {
                            setBox(box - 1);
                          }
                        }}
                        className="border rounded-md py-1 px-4 mr-2"
                      >
                        -
                      </button>
                      <span className="text-center w-8">{box}</span>
                      <button
                        onClick={() => {
                          setBox(box + 1);
                        }}
                        className="border rounded-md py-1 px-4 ml-2"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex px-5 gap-8 mt-4 pb-5 items-center">
              {exist?._id ? (
                <button
                  onClick={() => {
                    toast.success("go to the cart!");
                  }}
                  className="font-bold bg-green-500 text-white px-5 py-2 rounded-md"
                >
                  Already added
                </button>
              ) : (
                <button
                  onClick={addOrderInLocalStorage}
                  className="font-bold bg-primary text-white px-5 py-2 rounded-md"
                >
                  Add To Cart
                </button>
              )}
              <button className="font-bold shadow border hover:bg-slate-100 duration-300 text-slate-600 px-5 py-2 rounded-md">
                <MdFavorite className="text-2xl"></MdFavorite>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="my-10 shadow rounded-lg p-5">
        <div className="flex mb-5 gap-3 items-center">
          <RiFileTextLine className="text-3xl"></RiFileTextLine>
          <h1 className="text-xl font-semibold">Description</h1>
        </div>

        <div dangerouslySetInnerHTML={{ __html: product?.description }}></div>
      </div>

      <div className="my-16 rounded-lg p-5">
        <div className="flex mb-5 gap-3 items-center">
          <RiFileTextLine className="text-2xl"></RiFileTextLine>
          <h1 className="font-semibold text-red-300">Disclaimer</h1>
        </div>
        <p className="text-xs text-gray-400">
          The information provided on this website is accurate, updated, and
          complete according to the best practices of Nirog Health. However,
          this information should not be considered a substitute for
          professional medical consultation or advice. We do not guarantee the
          accuracy or completeness of the information provided. The absence of
          any specific information and/or warning about any drug should not be
          interpreted as an implied assurance by Nirog Health. We do not assume
          any responsibility for the consequences arising from the use of this
          information and strongly recommend consulting a healthcare
          professional for any medical queries or concerns.
        </p>
      </div>
    </div>
  );
};

export default ProductDetails;
