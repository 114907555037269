import React from "react";
// import pharmecy from "../../Assets/Pharmacry Register/pharmacy Resgister.png";

const PharmacyRegister = () => {
  return (
    <div className="lg:flex justify-center items-center gap-5 p-5">
      <div>
        {/* <img className=" w-96" src={pharmecy} alt="Pharmacy Register"></img> */}
      </div>

      <div className="">
        <div>
          <label>Full Name</label>
          <input
            placeholder="Full Name"
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          ></input>
        </div>
        <div>
          <label>Phone</label>
          <input
            disabled
            defaultValue="+8801875071998"
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          ></input>
        </div>
        <div>
          <label>Pharmacy Name</label>
          <input
            placeholder="Pharmacy Name"
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          ></input>
        </div>
        <div>
          <label>Drugs License Number</label>
          <input
            placeholder="Drugs License Number"
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          ></input>
        </div>
        <div>
          <div className=" ">
            <label>Upload License Photo</label>
            <div className="flex justify-center mb-1 mt-2">
              <div
                className="w-full relative border-2 py-3 px-6 border-gray-300 border-dashed rounded-lg"
                id="dropzone"
              >
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 z-50"
                />
                <div className="text-center">
                  <h3 className="text-sm font-medium text-gray-900">
                    <label htmlFor="file-upload" className="relative cursor-pointer">
                      <span>Drag and drop</span>
                      <span className="text-indigo-600"> or browse</span>
                      <span>to upload</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      ></input>
                    </label>
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    PNG, JPG, PDF up to 10MB
                  </p>
                </div>
                <img
                  src=""
                  className="mt-4 mx-auto max-h-40 hidden"
                  id="preview"
                  alt="Preview"
                ></img>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <button className="w-full rounded-md border border-[#e0e0e0] bg-primary text-white py-3 px-6 text-base font-medium outline-none focus:border-primary focus:shadow-md">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacyRegister;
