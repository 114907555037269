import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Notification = () => {
  const { userInfo } = AuthUser();
  const [u, setU] = useState([]);
  const [prescription, setPrescription] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    async function fetchOnlineOrder() {
      try {
        const response = await axios.get(
          `${apiUrl}/offlineOrders/specific?fieldName=user._id&&fieldValue=${encodeURIComponent(
            u?._id
          )}&&fieldName2=orderType&&fieldValue2=online`
        );

        setPrescription(response?.data?.data);
      } catch (err) {
        console.error(err.message);
      }
    }

    if (u?._id) {
      fetchOnlineOrder();
    }
  }, [u]);

  return (
    <div>
      <div>
        {prescription.length === 0
          ? Array(6)
              .fill()
              .map((_, index) => (
                <div key={index} className="shadow p-2 rounded-md mb-5">
                  <Skeleton height={20} width="60%" />
                  <Skeleton height={20} width="40%" />
                  <Skeleton height={20} width="100%" />
                  <Skeleton height={20} width="80%" />
                </div>
              ))
          : prescription.map((order) => (
              <Link to={`/userDashboard/print-invoice/${order?._id}`} key={order._id}>
                <div className="shadow p-2 rounded-md hover:shadow-primary lg:px-5 px-3 hover:cursor-pointer mb-5">
                  <div className="border-b pb-1 flex items-center gap-4 justify-between">
                    <div className="flex justify-between w-full items-center">
                      <p className="text-[13px]">{order?.user?.name}</p>
                    </div>
                  </div>
                  <div className="flex justify-between gap-5 py-3 md:text-base text-sm">
                    <div className="space-y-1">
                      <p>Invoice</p>
                      <p>Date</p>
                      <p>Status</p>
                      <p className="font-semibold">Amount Payable</p>
                    </div>
                    <div className="space-y-1">
                      <p className="font-semibold">#{order.invoiceId}</p>
                      <p>{order ? new Date(order.createdAt).toLocaleDateString() : null}</p>
                      <button className="bg-primary text-white px-3 py-1 rounded-lg">Accepted</button>
                      <p className="font-semibold">৳{order?.payableAmount} BDT</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </div>
  );
};

export default Notification;
