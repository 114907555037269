import React from 'react';
import { Link } from 'react-router-dom';

function Success() {
  return (
    <div className=' '>
      <div className="bg-green-500 py-8 px-4 sm:px-6 lg:px-8 ">
        <h1 className="text-3xl font-bold mb-4 text-white">Congratulations!</h1>
        <p className="text-lg text-white">Your payment was successful.</p>
        <p className="text-sm text-white">Thank you for choosing our services. Your order will be processed shortly.</p>


        <div className="flex items-center mt-5">
          <Link to="/">
            <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
              Continue Shopping
            </button>
          </Link>
          {/* You can add more buttons or links as needed */}
        </div>
      </div>
    </div>
  );
}

export default Success;
