import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import OfflineOrderWithDetails from "./OfflineOrderWithDetails";
import moment from "moment";
import axios from "axios";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const OfflineOrderHistory = () => {
  const { userInfo } = AuthUser();
  const [users, setUsers] = useState([]);
  const [offlineOrder, setOfflineOrder] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.get(`${apiUrl}/user/getUsers`);
        setUsers(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    async function fetchUserByNum() {
      try {
        await axios.get(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`);
        // Assuming you need to set the user information, but it's not used currently
        // setU(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchUserByNum();
  }, [userInfo]);

  useEffect(() => {
    async function fetchOfflineOrders() {
      try {
        const response = await axios.get(`${apiUrl}/offlineOrders/specific?fieldName=orderType&&fieldValue=offline`);
        setOfflineOrder(response?.data?.data);
        setFilteredOrders(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchOfflineOrders();
  }, [userInfo]);

  const handleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const fromDate = e.target.formDate.value;
    const toDate = e.target.toDate.value;
    const results = offlineOrder?.filter((d) => {
      return (
        (d?.seller?.name
          ? d?.seller?.name.toLowerCase()?.includes(name?.toLowerCase())
          : true) &&
        (fromDate && fromDate
          ? moment(d?.createdAt).isBetween(moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD"))
          : true)
      );
    });
    setFilteredOrders(results);
  };

  const totalOrders = filteredOrders.length;
  const totalPayableAmount = filteredOrders.reduce((total, order) => {
    const amount = parseFloat(order.payableAmount);
    return isNaN(amount) ? total : total + amount;
  }, 0);

  const currentMonth = new Date().getMonth() + 1;
  const currentMonthSales = filteredOrders.reduce((total, order) => {
    const orderMonth = new Date(order.createdAt).getMonth() + 1;
    if (orderMonth === currentMonth) {
      const amount = parseFloat(order.payableAmount);
      return isNaN(amount) ? total : total + amount;
    }
    return total;
  }, 0);

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Offline Order
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential All Offline Order for health. Act now to secure the latest items.
        </p>
      </div>
      <div className="flex justify-end mb-2">
        <Link to="/adminDashboard/create-offline-order">
          <button className="uppercase bg-green-500 px-4 py-1 text-white font-bold rounded-sm">
            Create Order
          </button>
        </Link>
      </div>

      <OfflineOrderWithDetails
        totalOrders={totalOrders}
        totalPayableAmount={totalPayableAmount}
        currentMonth={currentMonthSales}
      />

      <form onSubmit={handleSearch} className="flex flex-wrap items-center gap-3 p-3 my-3 rounded-lg border">
        <div>
          <label className="block mb-1">From Date</label>
          <input
            name="formDate"
            type="date"
            className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-1">To Date</label>
          <input
            name="toDate"
            type="date"
            className="border focus:outline-none bg-[#F0FDF4] px-3 py-2 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-1">Name</label>
          <select name="name" className="border focus:outline-none px-2 py-2.5 rounded-md bg-[#F0FDF4]">
            <option selected disabled value="">
              select user
            </option>
            {users?.map((u) => (
              <option key={u._id} value={u?.name}>
                {u?.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="text-white bg-primary px-3 py-2 rounded-md mt-6">
          Search
        </button>
      </form>

      <div className="mb-3">
        <span className="text-gray-700">Showing {filteredOrders.length} Results</span>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                #
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Order Date
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Seller Name
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Invoice
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Item Name
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Discount
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Payable Amount
              </th>
              <th scope="col" className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Action
              </th>
            </tr>
            {filteredOrders
              .slice()
              .reverse()
              .map((order, index) => (
                <tr key={order._id} className="shadow">
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {index + 1}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {new Date(order.createdAt).toLocaleString()}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {order?.seller?.name}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 whitespace-nowrap">
                    {order?.invoiceId}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {order?.items.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <p>{item.itemName}</p>
                      </div>
                    ))}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {order?.discount} %
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {order?.payableAmount} BDT
                  </td>
                  <td className="h-16 px-6 transition duration-300 border-slate-200 text-secondary text-lg flex gap-2 items-center cursor-pointer">
                    <Link to={`/adminDashboard/offline-order-view/${order._id}`}>
                      <div className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
                        <Icon icon="grommet-icons:view" />
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfflineOrderHistory;
