import React, { useContext, useEffect, useState } from 'react';
import cart from '../../Assets/cart.png';
import MyContext from '../../Context/MyContext';

const Cart = ({ openCart, setOpenCart }) => {
  const { refetch } = useContext(MyContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const existingOrder = JSON.parse(localStorage.getItem("nirogOrder")) || [];
    setItems(existingOrder);
  }, [refetch]);

  const totalPrice = items?.reduce((acc, item) => acc + item?.onePrice * item?.quantity, 0) || 0;

  return (
    <div onClick={() => setOpenCart(!openCart)} className='z-50 fixed right-0 top-[50%] shadow-2xl border border-primary rounded-l-md overflow-hidden cursor-pointer'>
      <div className='bg-primary w-16 text-white px-2 py-1 hover:bg-primary/90 duration-500'>
        <img className='w-[50%] mx-auto' src={cart} alt='img' />
        <p className='whitespace-nowrap text-sm font-semibold'>{items?.length} items</p>
      </div>
      <div className='w-full bg-red-600 '>
        <p className='text-center text-white text-sm font-semibold'>
          <span className="font-bold text-xs">৳</span> {totalPrice.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default Cart;
