import AdminDashboardIndex from "../Components/AdminDashboard/AdminDashboardIndex";
import AdminOrders from "../Components/AdminDashboard/AdminOrders/AdminOrders";
import UserOrders from "../Components/UserDashboard/MyOrder/UserOrders/UserOrders";
import AddProducts from "../Pages/AdminDashboard/AddProduct/AddProducts";
import AdminAllBlog from "../Pages/AdminDashboard/AdminBlog/AdminAllBlog";
import AdminBlog from "../Pages/AdminDashboard/AdminBlog/AdminBlog";
import AddCategory from "../Pages/AdminDashboard/AdminCategory/AddCategory";
import AllCategory from "../Pages/AdminDashboard/AdminCategory/AllCategory";
import UpdateCategory from "../Pages/AdminDashboard/AdminCategory/UpdateCategory";
import AdminDashboardSliderImg from "../Pages/AdminDashboard/AdminDashboardSliderImg/AdminDashboardSliderImg";
import AdminFooter from "../Pages/AdminDashboard/AdminFooter/AdminFooter";
import AdminHowToOrder from "../Pages/AdminDashboard/AdminHowToOrder/AdminHowToOrder";
import CreateOfflineOrder from "../Pages/AdminDashboard/AdminOfflineOrder/CreateOfflineOrder";
import OfflineOrderHistory from "../Pages/AdminDashboard/AdminOfflineOrder/OfflineOrderHistory";
import OfflineOrderView from "../Pages/AdminDashboard/AdminOfflineOrder/OfflineOrderView";
import SellerPendingPrescription from "../Pages/AdminDashboard/AdminSellerPendingPrescription/SellerPendingPrescription";
import SellerPrescriptionHistory from "../Pages/AdminDashboard/AdminSellerPendingPrescription/SellerPrescriptionHistory";
import SellerViewPrescription from "../Pages/AdminDashboard/AdminSellerPendingPrescription/SellerViewPrescription";
import AdminShipping from "../Pages/AdminDashboard/AdminShipping/AdminShipping";
import AdminUpdateProduct from "../Pages/AdminDashboard/AdminUpdateProduct/AdminUpdateProduct";
import AllProduct from "../Pages/AdminDashboard/AllProduct/AllProduct";
import AllUsers from "../Pages/AdminDashboard/AllUsers/AllUsers";
import ViewUserProfile from "../Pages/AdminDashboard/AllUsers/ViewUserProfile";

import AddJobPost from "../Pages/AdminDashboard/Career/AddJobPost";
import AllApplications from "../Pages/AdminDashboard/Career/AllApplications";
import AllJobTable from "../Pages/AdminDashboard/Career/AllJobTable";
import JobEdit from "../Pages/AdminDashboard/Career/JobEdit";
import ViewApplication from "../Pages/AdminDashboard/Career/ViewApplication";
import AddCoupon from "../Pages/AdminDashboard/Coupon/AddCoupon";
import AllCoupon from "../Pages/AdminDashboard/Coupon/AllCoupon";
import Dashboard from "../Pages/AdminDashboard/Dashboard/Dashboard";
import ProductAddSeller from "../Pages/AdminDashboard/Inventory/ProductAddSeller";
import SellerProductHistory from "../Pages/AdminDashboard/Inventory/SellerProductHistory";
import RequestMedicineTable from "../Pages/AdminDashboard/RequestMedicine/RequestMedicineTable";
import AddSeller from "../Pages/AdminDashboard/Seller/AddSeller";
import AllSeller from "../Pages/AdminDashboard/Seller/AllSeller";
import SellerHistory from "../Pages/AdminDashboard/Seller/SellerHistory";
import SellerStock from "../Pages/AdminDashboard/Seller/SellerStock";
import SellerVIewProduct from "../Pages/AdminDashboard/Seller/SellerVIewProduct";
import UpdateCoupon from "../Pages/AdminDashboard/UpdateCoupon/UpdateCoupon";

const AdminRoutes = [
  { path: "", Component: AdminDashboardIndex },
  { path: "addProduct", Component: AddProducts },
  { path: "updateProducts/:id", Component: AdminUpdateProduct },

  { path: "addCoupon", Component: AddCoupon },
  { path: "allCoupon", Component: AllCoupon },
  { path: "updateCoupons/:id", Component: UpdateCoupon },

  { path: "allProduct", Component: AllProduct },
  {path: 'allUsers', Component: AllUsers},
  {path: 'view-user-profile/:id', Component: ViewUserProfile},

  {path: 'dashboard', Component: Dashboard},

  { path: "addCategory", Component: AddCategory },
  { path: "allCategory", Component: AllCategory },
  { path: "updateCategorys/:id", Component: UpdateCategory },

  { path: "updateShipping", Component: AdminShipping },
  { path: "request-medicine", Component: RequestMedicineTable },

  { path: "orders", Component: AdminOrders },
  {path: "orders/:id", Component: UserOrders},

  { path: "add-seller", Component: AddSeller },
  { path: "all-seller", Component: AllSeller },
  { path: "seller-history/:id", Component: SellerHistory },
  { path: "seller-stock/:id", Component: SellerStock },
  { path: "seller-view-product/:id", Component: SellerVIewProduct },

  
  { path: "product-add-seller", Component: ProductAddSeller },
  { path: "seller-product-history", Component: SellerProductHistory },

  { path: "add-job-post", Component: AddJobPost },
  { path: "all-job-table", Component: AllJobTable },
  { path: "job-edit/:id", Component: JobEdit },

  { path: "allApplication", Component: AllApplications },
  { path: "viewApplication/:id", Component: ViewApplication },





  { path: "create-offline-order", Component: CreateOfflineOrder },
  { path: "offline-order-history", Component: OfflineOrderHistory },
  { path: "offline-order-view/:id", Component: OfflineOrderView },


  { path: "pending-prescription", Component: SellerPendingPrescription },
  { path: "prescription-history", Component: SellerPrescriptionHistory },
  { path: "view-prescription/:id", Component: SellerViewPrescription },

  { path: "AddBlog", Component: AdminBlog },
  { path: "allBlogs", Component: AdminAllBlog },
  { path: "updateBlog/:id", Component: AdminBlog },

  { path: "updateBanner", Component: AdminDashboardSliderImg },
  { path: "updateHowToOrder", Component: AdminHowToOrder },
  { path: "updateFooter", Component: AdminFooter },



];

export default AdminRoutes;
