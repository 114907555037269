import React, { useState } from 'react';
import ManageStockModal from './ManageStockModal';

const StockProductCard = ({product,refetch,setRefetch}) => {
    const [isOpen,setIsOpen]=useState(false);
    return (
        <>
        <div onClick={()=>setIsOpen(!isOpen)} className="border mt-1 p-2 cursor-pointer">
        <div className="flex  flex-wrap gap-2 items-start">
          <div>
            <img
              className="lg:w-12  w-12"
              src={product.img}
              alt={product.name}
            />
          </div>

          <div>
            <h1> {product.name}</h1>

            <p className="text-xs text-gray-500">
              {product.strength}
            </p>
            <p className="text-xs text-gray-500">
              {product.companyName} 11
            </p>
          </div>
        </div>
      </div>
       <ManageStockModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} setRefetch={setRefetch} product={product}/>
       </>
    );
};

export default StockProductCard;