import React from "react";
import Skeleton from "react-loading-skeleton";

const Benefits = ({ product }) => {
  if (!product) {
    return (
      <div>
        <h2 className="text-xl font-semibold mb-2">
          <Skeleton width={200} />
        </h2>
        <div>
          <Skeleton count={5} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Benefits</h2>
      <div dangerouslySetInnerHTML={{ __html: product.benefits }} />
    </div>
  );
};

export default Benefits;
