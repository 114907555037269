import React, { memo, lazy, Suspense, useState, useEffect } from "react";
import LinearLoader from "../../Components/Loader/LinearLoader";

const Slider = lazy(() => import("../../Components/Home/Slider/Slider"));
const HowToOrder = lazy(() => import("../../Components/HowToOrder/HowToOrder"));
const OrderOptions = lazy(() => import("../../Components/Home/OrderOptions/OrderOptions"));
const NewHomePageProduct = lazy(() => import("./NewHomePageProduct"));

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1000); // Simulating loading time

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="">
      {loading && <LinearLoader />}
      <Suspense fallback={<LinearLoader />}>
        <Slider />
        <OrderOptions />
        <HowToOrder />
        <NewHomePageProduct />
      </Suspense>
    </div>
  );
};

export default memo(Home);
