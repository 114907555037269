import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Icon } from "@iconify/react";
import Loading from "../../../Shared/Loading";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerVIewProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    // Fetch the product details based on the id
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/product/getProductsById/${id}`
        );
        // Assuming the response.data contains the product details
        setProduct(response.data.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  if (!product) {
    return (
      <div>
        <Loading></Loading>
      </div>
    ); // or handle loading state as needed
  }

  // Render your pharmaceutical details using the product object
  return (
    <div className="min-h-screen">
      <div className=" flex gap-2 items-center text-2xl text-gray-900">
        <Icon icon="mdi:drugs"></Icon>
        <h1>{product?.name}</h1>
      </div>

      <div className=" text-gray-500 text-md">
        <p>{product?.genericName}</p>
        <p>{product?.strength}</p>
        <p>{product?.companyName}</p>
      </div>

      <div className="mt-5">
        <div className="bg-gray-300 text-black p-2">
          <h1>OverView</h1>
        </div>

        <div className="text-gray-500 ">
          <p> Price: {product?.onePiecePrice} BDT</p>
          <p> One Strip Quantity: {product?.oneStrip} </p>
          <p> One Box Quantity: {product?.oneBox} </p>
          <p> Discount: {product?.discount} %</p>

          <p> Generic Categories: {product?.genericCategory}</p>
          <p> General Category: {product?.category}</p>
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-gray-300 text-black p-2">
          <h1>Dosage From</h1>
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: product?.dosageForm }} />
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-gray-300 text-black p-2">
          <h1>Description</h1>
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: product?.description }} />
        </div>
      </div>
    </div>
  );
};

export default SellerVIewProduct;
