import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Shared/Loading";
import ApplyJobModal from "../../Shared/ApplyJobModal";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const JobDetails = ({ match }) => {
  const [job, setJob] = useState(null);
  const [isOpen,setIsOpen]=useState(false);
  const { id } = useParams();
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/jobPosts/getJobPostsById/${id}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch job details");
        }

        const data = await response.json();
        setJob(data.data); // Assuming the response data is a JSON object representing the job details
      } catch (error) {
        console.error("Error fetching job details:", error.message);
      }
    };

    fetchJobDetails();
  }, [id]);


  return (
    <div className="container mx-auto lg:p-8">
      <div className="  bg-white lg:shadow-md rounded-md overflow-hidden">
        <div className="lg:p-6 sm:p-1">
          {job ? (
            <>
              <h1 className="text-3xl font-bold mb-4">{job.jobTitle}</h1>
              <p className="text-sm text-gray-600 mb-2">
                Job Type: {job.jobType}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Job Location: {job.jobLocation}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Company: {job.company}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Apply Last Date:{" "}
                {new Date(job.applyLastDate).toLocaleDateString()}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Address: {job.address}
              </p>
              <div className="mb-4">
                <h2 className="text-sm font-medium text-gray-600 mb-2">
                  Job Description:
                </h2>
                <p
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: job.jobDescription }}
                />
              </div>
            </>
          ) : (
            <p className="text-center">
              <Loading></Loading>
            </p>
          )}

          <button onClick={()=>setIsOpen(!isOpen)} className=" bg-primary px-7 py-2 text-white rounded-sm text-xl" >Apply</button>
        </div>


      </div>
      <ApplyJobModal isOpen={isOpen} setIsOpen={setIsOpen} data={job}/>
    </div>
  );
};

export default JobDetails;
