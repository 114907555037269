const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const singleImageUpload = async (formData, setMyImageUrl) => {
  const imageBaseUrl = `${apiUrl}/upload/single-image-upload`;

  // console.log("LE", formData);
  // console.log("LE 2", setMyImageUrl);

  fetch(imageBaseUrl, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log("Data: ", data);
      if (data.status === "success") {
        // console.log("Response:", data);
        setMyImageUrl(data.url);
      }
    });
};
