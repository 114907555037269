import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import OfflineOrderWithDetails from "./OfflineOrderWithDetails";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const OfflineOrderHistory = () => {
  const { userInfo } = AuthUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [offlineOrder, setOfflineOrder] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        // No need to store user data in state if it's not used elsewhere
      });
  }, [userInfo]);

  useEffect(() => {
    fetch(`${apiUrl}/offlineOrders/getOfflineOrders`)
      .then((res) => res.json())
      .then((data) => {
        const userPhone = userInfo?.phone;
        const filteredData = data?.data.filter(
          (order) =>
            order?.seller &&
            order?.seller?.phone === userPhone &&
            order?.orderType === "offline"
        );

        setOfflineOrder(filteredData);
        setFilteredOrders(filteredData); // Initialize filteredOrders with all orders
      });
  }, [userInfo]);

  const handleSearch = (input) => {
    setSearchTerm(input);

    // Filter based on customerName, invoiceId, or payableAmount
    const filteredData = offlineOrder.filter(
      (order) =>
        order?.customerName?.toLowerCase().includes(input.toLowerCase()) ||
        order?.invoiceId?.toLowerCase().includes(input.toLowerCase()) ||
        order?.payableAmount?.toString()?.includes(input.toLowerCase())
    );

    setFilteredOrders(filteredData);
  };

  // Calculate total number of orders and total payable amount
  const totalOrders = filteredOrders.length;
  const totalPayableAmount = filteredOrders.reduce((total, order) => {
    const amount = parseFloat(order.payableAmount);
    return isNaN(amount) ? total : total + amount;
  }, 0);

  // Calculate current month's sales
  const currentMonth = new Date().getMonth() + 1; // Months are zero-based
  const currentMonthSales = filteredOrders.reduce((total, order) => {
    const orderMonth = new Date(order.createdAt).getMonth() + 1;
    if (orderMonth === currentMonth) {
      const amount = parseFloat(order.payableAmount);
      return isNaN(amount) ? total : total + amount;
    }
    return total;
  }, 0);

  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Offline Order
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential All Offline Order for health. Act now to secure the
          latest items
        </p>
      </div>
      <div className="flex justify-end mb-2">
        <Link to="/sellerDashboard/create-offline-order">
          <button className="uppercase bg-green-500 px-4 py-1 text-white font-bold rounded-sm">
            Create Order
          </button>
        </Link>
      </div>

      <OfflineOrderWithDetails
        totalOrders={totalOrders}
        totalPayableAmount={totalPayableAmount}
        currentMonthSales={currentMonthSales}
      />

      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter Invoice, Payable Amount and "
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={() => handleSearch(searchTerm)}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Reset</span>
        </button>
      </div>

      <div className="mb-3">
        <span className="text-gray-700">
          Showing {filteredOrders.length} Results
        </span>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                #
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Order Date
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Invoice
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Item Name
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Discount
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Payable Amount
              </th>
              <th className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100">
                Action
              </th>
            </tr>
            {/* Map through the filtered orders instead of all orders */}
            {filteredOrders
              .slice()
              .reverse()
              .map((order, index) => (
                <tr key={order._id} className="shadow">
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {index + 1}
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {new Date(order.createdAt).toLocaleString()}
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {order?.invoiceId}
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {order?.items.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <p>{item.itemName}</p>
                      </div>
                    ))}
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {order?.discount} %
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {order?.payableAmount} BDT
                  </td>
                  <td className="h-16 px-6 text-lg text-secondary flex gap-2 items-center cursor-pointer">
                    <Link to={`/sellerDashboard/offline-order-view/${order._id}`}>
                      <div className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
                        <Icon icon="grommet-icons:view" />
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfflineOrderHistory;
