import { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CategoryItem = memo(({ category }) => (
  <Link to={`/product/${category?.name}`} key={category._id}>
    <div className="border p-4 rounded-md cursor-pointer ring-1 ring-primary w-full h-full">
      <div className="flex flex-col gap-1 items-center">
        <img
          className="w-10 h-10 object-contain"
          src={category.icon}
          alt={`${category.name} icon`}
          loading="lazy"
        />
        <h1 className="text-center">{category.name}</h1>
      </div>
    </div>
  </Link>
));

const fetchCategoriesFromAPI = async () => {
  const response = await fetch(`${apiUrl}/category/getCategorys`);
  const data = await response.json();
  return data?.data || [];
};

export default function Category() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const fetchedCategories = await fetchCategoriesFromAPI();
        setCategories(fetchedCategories);
      } catch (err) {
        setError("Failed to fetch categories.");
      } finally {
        setLoading(false);
      }
    }

    fetchCategories();
  }, []);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 p-4">
      {loading
        ? Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className="border p-4 rounded-md ring-1 ring-primary w-full h-full"
            >
              <div className="flex flex-col gap-1 items-center">
                <Skeleton className="w-10 h-10 object-contain" circle={true} />
                <Skeleton width={60} />
              </div>
            </div>
          ))
        : categories.map((category) => (
            <CategoryItem category={category} key={category._id} />
          ))}
    </div>
  );
}
