import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../Shared/Loading";
import saveAs from "file-saver";
import { Icon } from "@iconify/react";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SellerViewPrescription = () => {
  const {userInfo}=AuthUser();
  const [u, setU] = useState([]);
  const {id}=useParams(); 
  const [loading,setLoading]=useState(false);
  const [data,setData]=useState([]);


    const orderType = "online"
  const generateInvoiceId = () => {
    const timestamp = Date.now();
    return `INV-${timestamp}`;
  };
  useEffect(() => {
    setLoading(true);
    try {
      fetch(`${apiUrl}/prescription/getPrescriptionsById/${id}`)  
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error("something wrong")
    }
  }, [id]);
  useEffect(() => {
    fetch(
      `${apiUrl}/user/getUsersByNum/${userInfo?.phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);
  const [form, setForm] = useState({
    items: [{ itemName: "", itemPrice: "", quantity: "" }],
    orderDescription: "",
    entryDetails: u,
    discount: 0, // new field for discount
    prescriptionDetails:data,
    orderType:orderType,
  });

  const [invoiceId, setInvoiceId] = useState(generateInvoiceId());
  const handleChange = (e, index) => {
    if (index !== undefined) {
      // Handle items
      const newItems = [...form.items];
      newItems[index][e.target.name] = e.target.value;
      setForm({
        ...form,
        items: newItems,
      });
    } else {
      // Handle customer name and discount
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddItem = () => {
    setForm({
      ...form,
      items: [...form.items, { itemName: "", itemPrice: "", quantity: "" }],
    });
  };

  const handleRemoveItem = (index) => {
    const newItems = [...form.items];
    newItems.splice(index, 1);
    setForm({
      ...form,
      items: newItems,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      items: form.items.map((item) => ({
        ...item,
        totalPrice: calculateTotal(item),
      })), 
      orderDescription: form.orderDescription,
      invoiceId: invoiceId,
      discount: form.discount, // include discount in form data
      prescriptionDetails:data,
      orderType:orderType,
      totalOrderPrice: calculateTotalOrderPrice(),
      payableAmount: calculatePayableAmount(),user:data?.user,seller:u};

    try {
      const response = await fetch(
        `${apiUrl}/offlineOrders/addOfflineOrder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        await UpdateHooks(
          `${apiUrl}/prescription/updatePrescriptions/${id}`,
          {status:"accepted"}
      )
        toast.success("Order created successfully");
      } else {
        // Handle errors if the request was not successful
        toast.error("Failed to create order");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error creating order:", error.message);
    }

    setForm({
      items: [{ itemName: "", itemPrice: "", quantity: "" }],
      phoneNumber: "",
      customerName: "",
      orderDescription: "",
      entryDetails: u,
      invoiceId: invoiceId,
      prescriptionDetails:data,
      orderType:orderType,
      discount: 0, // reset discount to default value
    });
    setInvoiceId(generateInvoiceId());
  };

  const calculateTotal = (item) => {
    const quantity = parseInt(item.quantity) || 0;
    const itemPrice = parseFloat(item.itemPrice) || 0;
    return quantity * itemPrice;
  };

  const calculateDiscountAmount = () => {
    const totalOrderPrice = calculateTotalOrderPrice();
    const discountPercentage = form.discount || 0;
    return ((discountPercentage / 100) * totalOrderPrice).toFixed(2);
  };

  const calculatePayableAmount = () => {
    const totalOrderPrice = calculateTotalOrderPrice();
    const discountAmount = parseFloat(calculateDiscountAmount()) || 0;
    return (totalOrderPrice - discountAmount).toFixed(2);
  };
  const calculateTotalOrderPrice = () => {
    return form.items.reduce((total, item) => total + calculateTotal(item), 0);
  };

  const renderInvoiceDetails = () => {
    const discountAmount = calculateDiscountAmount();
    const payableAmount = calculatePayableAmount(); // Calculate payable amount

    return (
      <div className="invoice-container">
        <h2 className="text-xl uppercase">Invoice - {invoiceId}</h2>
        <div className="flex-grow border-t mt-2 border-gray-200" />
        <div className="mt-5">
          {form.items.map((item, index) => (
            <div key={index} className="mb-4">
              <p>
                <span className="text-black">Item Name:</span>{" "}
                {item.itemName || "Not provided"}
              </p>
              <p>
                <span className="text-black">Price:</span> ৳{" "}
                {item.itemPrice || "Not provided"}
              </p>
              <p>
                <span className="text-black">Quantity:</span>{" "}
                {item.quantity || "Not provided"}
              </p>
              <p>
                <span className="text-black">Total Price:</span> ৳{" "}
                {calculateTotal(item)}
              </p>
              <button
                type="button"
                className=" bg-red-500 uppercase text-white px-3 rounded-sm py-1 text-xs "
                onClick={() => handleRemoveItem(index)}
              >
                - Remove Item
              </button>
            </div>
          ))}
          <p>
            <span className="text-black">Total Order Price:</span> ৳{" "}
            {calculateTotalOrderPrice()} BDT
          </p>
          <p>
            <span className="text-black">Discount Percentage:</span> (
            {form.discount} %)
          </p>
          <p>
            <span className="text-black">Discount Amount:</span>{" "}
            {discountAmount} BDT
          </p>
          <div className="flex-grow border-t mt-2 border-gray-300" />
          <p>
            <span className="text-black">Payable Amount:</span> ৳{" "}
            {payableAmount} BDT
          </p>
        </div>
      </div>
    );
  };

  const quantityOptions = Array.from({ length: 100 }, (_, index) => index + 1);
  const discountOptions = Array.from({ length: 21 }, (_, index) => index); // Assuming you want values from 0 to 20
  if(loading){
    return <Loading/>
          }
  return (
    <div className=" min-h-screen">

      <div className=" flex justify-end  mb-2">
        {" "}
        <Link to="/adminDashboard/offline-order-history" >
          <button className=" uppercase bg-green-500 px-4 py-1 text-white font-bold rounded-sm">
            {" "}
            Hsitory
          </button>{" "}
        </Link>
      </div>

      <div className="w-fit">
        <h2 className="text-2xl font-semibold mb-1">Prescription Details</h2>
        <div className="w-[50%] h-1 bg-primary rounded-full"></div>
      </div>

      <div className="grid grid-cols-1 gap-5 mt-5">
       <div className="relative">
       <button  onClick={() => saveAs(data?.prescription)} className="absolute top-2 right-4 "><Icon className="bg-white/70 rounded-full p-2 text-6xl text-green-500 " icon="line-md:download-outline-loop" ></Icon></button>
       <img className="w-full rounded-lg" src={data?.prescription} alt="prescription"></img>
       </div>
        <div>
            <p><span className="font-semibold text-lg">Customer Name:</span> {data?.user?.name}</p>
            <p><span className="font-semibold text-lg">Customer Phone:</span> {data?.user?.phone}</p>
            <p><span className="font-semibold text-lg">Date:</span> {data?.date}</p>
            <p><span className="font-semibold text-lg">Address:</span>   {data?.user?.division} - {data?.user?.district} - {data?.user?.upazila} - {data?.user?.address}</p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        <div className="rounded-lg bg-gray-50 lg:col-span-2">
          <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Create Order</h1>
            <form onSubmit={handleSubmit}>
              {form.items.map((item, index) => (
                <div key={index} className="mb-2">
                  <label
                    htmlFor={`itemName${index}`}
                    className="block mb-2  font-thin"
                  >
                    Item Name
                  </label>
                  <input
                    type="text"
                    id={`itemName${index}`}
                    name="itemName"
                    value={item.itemName}
                    onChange={(e) => handleChange(e, index)}
                    className="border p-2 w-full"
                    required
                  />

                  <label
                    htmlFor={`itemPrice${index}`}
                    className="block mb-2 font-thin"
                  >
                    Item Price BDT
                  </label>
                  <input
                    type="number"
                    id={`itemPrice${index}`}
                    name="itemPrice"
                    value={item.itemPrice}
                    onChange={(e) => handleChange(e, index)}
                    className="border p-2 w-full"
                    required
                  />

                  <label
                    htmlFor={`quantity${index}`}
                    className="block mb-2 font-thin"
                  >
                    Quantity
                  </label>
                  <select
                    id={`quantity${index}`}
                    name="quantity"
                    value={item.quantity}
                    onChange={(e) => handleChange(e, index)}
                    className="border p-2 w-full bg-white"
                    required
                  >
                    <option className="font-thin" value="" disabled>
                      Select Quantity
                    </option>
                    {quantityOptions.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className=" mb-2 ">
                <button
                  className=" bg-green-500 uppercase text-white px-3 rounded-sm py-1 text-xs "
                  type="button"
                  onClick={handleAddItem}
                >
                  Add Item +
                </button>
              </div>
              <div className="mb-4">
                <label htmlFor="customerName" className="block mb-2 font-thin">
                  Customer Name
             
                </label>
                <input
                  type="text"
                  id="customerName"
                  name="customerName"
                  value={data?.user?.name}
                  disabled
                
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phoneNumber" className="block mb-2 font-thin">
                  Customer Phone
                
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  disabled
                  value={data?.user?.phone}
                
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="orderDescription"
                  className="block mb-2 font-thin"
                >
                  Order Description{" "}
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <textarea
                  id="orderDescription"
                  name="orderDescription"
                  value={form.orderDescription}
                  onChange={(e) =>
                    setForm({ ...form, orderDescription: e.target.value })
                  }
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="discount" className="block mb-2 font-thin">
                  Discount (%) <span className="text-gray-500">(Optional)</span>
                </label>
                <select
                  id="discount"
                  name="discount"
                  value={form.discount}
                  onChange={(e) => handleChange(e)}
                  className="border p-2 w-full bg-white"
                >
                  <option value="" disabled>
                    Select Discount
                  </option>
                  {discountOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}%
                    </option>
                  ))}
                </select>
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Order
              </button>
            </form>
          </div>
        </div>
        <div className="rounded-lg bg-gray-50 p-5">
          {renderInvoiceDetails()}
        </div>
      </div>
    </div>
  );
};

export default SellerViewPrescription;
