import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  FaWhatsapp,
  FaFacebookMessenger,
  FaTimes,
  FaComment,
} from "react-icons/fa";

const Bot = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && isModalOpen) {
        setIsModalOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className="fixed bottom-5 right-4 z-50 cursor-pointer text-white text-4xl flex items-center justify-center rounded-full bg-primary p-3 animate-bounce lg:block hidden"
        onClick={isModalOpen ? closeModal : openModal}
      >
        <FaComment />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal-content fixed bottom-5 right-4 bg-white p-6 rounded-lg shadow-lg"
        overlayClassName="modal-overlay"
      >
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <span className="whitespace-nowrap py-0.5 text-md text-red-500">
                Live
              </span>
              <div className="rounded-full h-2 w-2 bg-red-500 animate-ping"></div>
            </div>
            <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
              <FaTimes className="text-xl" />
            </button>
          </div>
          <div className="">
            <p className="text-xl font-semibold mb-1">Hi there!👋</p>
            <p className="text-sm font-semibold mb-4 text-gray-500">
              Let us know if we can help you with anything at all.
            </p>
            <div className="flex justify-start space-x-4">
              <a
                href="https://wa.me/+8801884442022"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="text-4xl text-green-500 animate-bounce" />
              </a>
              <a
                href="https://m.me/yourPage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookMessenger className="text-4xl text-blue-500 animate-bounce" />
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <style>{`
        .modal-overlay {
          position: static;
          background: none;
        }
        .modal-content {
          position: fixed;
          bottom: 5rem;
          right: 4rem;
          background: white;
          padding: 1.5rem;
          border-radius: 0.5rem;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </div>
  );
};

export default Bot;
