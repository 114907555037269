import {  FaFacebookMessenger } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import whatsapp from "../../Assets/whatsapp.png";
import prescriptions from "../../Assets/Messenger.png";
export default function Chat() {
  return (
    <div>
      <div className="pt-5 flex flex-col gap-4 sm:flex-row justify-center w-full">
        <a
          href="https://wa.me/8801884442022"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-between items-center border border-main rounded-md border-solid p-3 gap-5 cursor-pointer"
        >
          <div className="flex items-center gap-4">
            <img src={whatsapp} alt="whatsapp" className="rounded-md" />
            <div className="flex flex-col">
              <div>
                <p className="font-semibold">Send Message</p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="opacity-50">Online</p>
                <div>
                  <div className="flex justify-start items-center">
                    <p className="text-[0.75rem] border px-[0.75rem] py-[0.125rem] font-bold rounded-md text-primary">
                      +880 1884-442022
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-5">
            <div className="w-[0.1rem] h-[3rem] bg-primary"></div>
            <IoCall size={40} className="rounded-md" />
          </div>
        </a>

        <div className="flex justify-between items-center border border-main rounded-md border-solid p-3 gap-5 cursor-pointer">
          <div className="flex items-center gap-4">
            <img
              src={prescriptions}
              alt="prescriptions"
              className="rounded-md"
            />
            <div className="flex flex-col">
              <div>
                <p className="font-semibold">Send Message</p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="opacity-50">Online</p>
                <div>
                  <div className="flex justify-start items-center">
                    <p className="text-[0.75rem] border px-[0.75rem] py-[0.125rem] font-bold rounded-md text-primary">
                      Facebook
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-5">
            <div className="w-[0.1rem] h-[3rem] bg-primary"></div>
            <FaFacebookMessenger size={40} className="rounded-md" />
          </div>
        </div>
      </div>
    </div>
  );
}
