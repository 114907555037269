import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import PostHooks from "../../../Hooks/PostHooks";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AdminFooter = () => {
  const [formData, setFormData] = useState({
    location: "",
    license: "",
    hotlineNumber: "",
    whatsAppNumber: "",
    email: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    messengerUrl: "",
    playStoreUrl: "",
    appStoreUrl: "",
  });

  //   load data
  useEffect(() => {
    fetch(`${apiUrl}/footer/getFooters`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setFormData(data?.data[0]);
        }
      });
  }, []);

  // update and post data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `${apiUrl}/footer/updateFooters/${formData?._id}`,
          formData
        );
        toast?.success(`Footer section Updated !`);
      } else {
        await PostHooks(
          `${apiUrl}/footer/addFooters`,
          formData,
          `Footer data posted`
        );
      }
    } catch (error) {
      toast?.success(error);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update Footer Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto "
      >
        <div className="grid lg:grid-cols-2  gap-5">
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Hotline Number (including +880)
          </label>
          <input
            type="text"
            name="hotlineNumber"
            required
            onChange={handleChange}
            value={formData.hotlineNumber}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Whatsapp Number (including +880)
          </label>
          <input
            type="text"
            name="whatsAppNumber"
            required
            onChange={handleChange}
            value={formData.whatsAppNumber}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Trade License
          </label>
          <input
            type="text"
            name="license"
            required
            onChange={handleChange}
            value={formData.license}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            onChange={handleChange}
            value={formData.email}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Facebook
          </label>
          <input
            type="text"
            name="facebook"
            required
            onChange={handleChange}
            value={formData.facebook}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Instagram
          </label>
          <input
            type="text"
            name="instagram"
            required
            onChange={handleChange}
            value={formData.instagram}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Linkedin
          </label>
          <input
            type="text"
            name="linkedin"
            required
            onChange={handleChange}
            value={formData.linkedin}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Messenger Url
          </label>
          <input
            type="text"
            name="messengerUrl"
            required
            onChange={handleChange}
            value={formData.messengerUrl}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            PlayStore Url
          </label>
          <input
            type="text"
            name="playStoreUrl"
            required
            onChange={handleChange}
            value={formData.playStoreUrl}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            AppStore Url
          </label>
          <input
            type="text"
            name="appStoreUrl"
            required
            onChange={handleChange}
            value={formData.appStoreUrl}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-600">
            Location:
          </label>
          <textarea
            name="location"
            required
            onChange={handleChange}
            value={formData.location}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          ></textarea>
        </div>
      </div>
        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update Footer !
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminFooter;
