import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import Loading from "../../../Shared/Loading";
import StockProductCard from "./StockProductCard";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function ProductAddSeller() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [uniqueCompanies, setUniqueCompanies] = useState([]);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/product/getProducts`);
      setProducts(response.data.data);
      setFilteredProducts(response.data.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, refetch]);

  const handleSearch = useCallback(() => {
    if (searchTerm === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products?.filter(
          (product) =>
            product?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product?.category?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, products]);

  const filteredCompanies = uniqueCompanies.filter((company) =>
    selectedLetter ? company.companyName.startsWith(selectedLetter) : true
  );

  const handleBrowseAlphabetically = (letter) => {
    setSelectedLetter(letter);
    setSelectedCompany(null);
    const filteredCompanies = uniqueCompanies.filter((company) =>
      company?.companyName?.startsWith(letter)
    );
    setFilteredProducts(
      filteredCompanies?.flatMap((company) => company?.products)
    );
  };

  const handleSelectCompany = (companyName) => {
    setSelectedCompany(companyName);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm, selectedLetter, handleSearch]);

  useEffect(() => {
    const companies = [
      ...new Set(products.map((product) => product?.companyName)),
    ];
    const companyLengths = companies.map((company) => ({
      companyName: company,
      products: products.filter((product) => product?.companyName === company),
    }));
    setUniqueCompanies(companyLengths);
  }, [products]);

  if (loading) {
    return <Loading />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen">
      <div className=" ">
        <h1 className="lg:text-2xl text-xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          List of Pharmaceutical Companies - Add Seller
        </h1>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center mb-2">
          <Icon icon="flat-color-icons:alphabetical-sorting-az"></Icon>
          <h1 className="ml-2">Browse Alphabetically</h1>
        </div>

        <div className="flex">
          <ul className=" flex  flex-wrap gap-2 ">
            {alphabet?.split("").map((letter, index) => (
              <li key={index} className="font-semibold">
                <button
                  onClick={() => handleBrowseAlphabetically(letter)}
                  className={`hover:text-white hover:bg-primary rounded-full border p-3 w-7 h-7 flex items-center justify-center ${
                    selectedLetter === letter ? "bg-primary text-white" : ""
                  }`}
                  style={{ borderRadius: "50%" }}
                >
                  {letter}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="lg:flex flex-wrap items-center gap-2 mt-5">
        {filteredCompanies?.map((company, index) => (
          <div
            key={index}
            className="max-w-xs px-2 py-1 rounded overflow-hidden shadow-lg flex items-center"
          >
            <div className="px-6 py-4">
              <p
                className={`text-gray-900 text-base cursor-pointer ${
                  selectedCompany === company?.companyName ? "font-bold" : ""
                }`}
                onClick={() => handleSelectCompany(company?.companyName)}
              >
                {company?.companyName}
              </p>
              <div className="flex gap-2 mt-2">
                <span className="rounded-full text-xs text-gray-500">
                  {company?.products?.length} brand names
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedCompany && (
        <div className="mt-4">
          <h2 className="lg:text-xl text-md font-semibold mb-2">
            Products for {selectedCompany}
          </h2>

          <div className="flex relative rounded-md w-full mt-3 mb-3">
            <input
              type="text"
              placeholder="search medicine name "
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
            />

            <button
              onClick={handleSearch}
              className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
            >
              <span>Search</span>
              <span className="hidden md:block">
                <Icon icon="material-symbols:search" />
              </span>
            </button>
          </div>

          <ul className="lg:flex flex-wrap gap-2 ">
            {filteredProducts
              .filter((product) => product?.companyName === selectedCompany)
              .map((product) => (
                <StockProductCard
                  key={product?._id}
                  product={product}
                  refetch={refetch}
                  setRefetch={setRefetch}
                />
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProductAddSeller;
