import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import Loading from "../../../Shared/Loading";
import AllProductWithDetails from "../../AdminDashboard/AllProduct/AllProductWithDetails";
import AuthUser from "../../../Hooks/authUser";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function SellerStockHistory() {
  const { userInfo } = AuthUser();
  const [u, setU] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [uniqueCompanies, setUniqueCompanies] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueGenericCategories, setUniqueGenericCategories] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minStock, setMinStock] = useState("");
  const [maxStock, setMaxStock] = useState("");

  useEffect(() => {
    fetch(`${apiUrl}/user/getUsersByNum/${userInfo?.phone}`)
      .then((res) => res.json())
      .then((data) => {
        setU(data?.data);
      });
  }, [userInfo]);

  useEffect(() => {
    setLoading(true);
    async function fetchProducts() {
      try {
        const response = await axios.get(
          `${apiUrl}/stock/specific?fieldName=sellerId&&fieldValue=${encodeURIComponent(
            u?._id
          )}`
        );
        setProducts(response?.data?.data);
        setFilteredProducts(response?.data?.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    if (u?._id) {
      fetchProducts();
    }
  }, [u]);

  const handleSearch = useCallback(() => {
    if (
      searchTerm === "" &&
      startDate === "" &&
      endDate === "" &&
      minStock === "" &&
      maxStock === ""
    ) {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products
          ?.filter(
            (product) =>
              product?.product?.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              product?.product?.category
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              product?.product?.companyName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
          .filter(
            (product) =>
              (!startDate ||
                new Date(product.createdAt) >= new Date(startDate)) &&
              (!endDate || new Date(product.createdAt) <= new Date(endDate)) &&
              (!minStock || product.stock >= minStock) &&
              (!maxStock || product.stock <= maxStock)
          )
      );
    }
  }, [searchTerm, startDate, endDate, minStock, maxStock, products]);

  const handleReset = () => {
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setMinStock("");
    setMaxStock("");
    setFilteredProducts(products);
  };

  useEffect(() => {
    // Extract unique company names and their lengths
    const companies = [
      ...new Set(products.map((product) => product?.product?.companyName)),
    ];
    const companyLengths = companies.map((company) => ({
      companyName: company,
      length: products.filter(
        (product) => product?.product?.companyName === company
      ).length,
    }));
    const categories = [
      ...new Set(products.map((product) => product?.product?.category)),
    ];
    const categoryLengths = categories.map((category) => ({
      categoryName: category,
      length: products.filter((product) => product?.product?.category === category)
        .length,
    }));
    // Extract unique generic categories and their lengths
    const genericCategories = [
      ...new Set(products.map((product) => product?.product?.genericCategory)),
    ];
    const genericCategoryLengths = genericCategories.map((genericCategory) => ({
      genericCategoryName: genericCategory,
      length: products.filter(
        (product) => product?.product?.genericCategory === genericCategory
      ).length,
    }));
    setUniqueCompanies(companyLengths);
    setUniqueCategories(categoryLengths);
    setUniqueGenericCategories(genericCategoryLengths);
  }, [products]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  if (loading) {
    return <Loading />;
  }

  if (error) return <div>Error: {error}</div>;
  return (
    <div>
      <div className=" ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Product Stock History
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          Explore essential product stock for health. Act now to secure the latest items.
        </p>
      </div>

      <div>
        <AllProductWithDetails
          length={products.length}
          uniqueCompanies={uniqueCompanies}
          uniqueCategories={uniqueCategories}
          uniqueGenericCategories={uniqueGenericCategories}
        />
      </div>

      <div className="lg:flex relative rounded-md w-full mt-3 mb-3 gap-3">
        {/* Date range filter */}
        <input
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="w-1/4 p-3 rounded-md border border-gray-300 focus:outline-none"
        />
        <input
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="w-1/4 p-3 rounded-md border border-gray-300 focus:outline-none"
        />

        {/* Stock range filter */}
        <input
          type="number"
          placeholder="Min Stock"
          value={minStock}
          onChange={(e) => setMinStock(e.target.value)}
          className="w-1/4 p-3 rounded-md border border-gray-300 focus:outline-none"
        />
        <input
          type="number"
          placeholder="Max Stock"
          value={maxStock}
          onChange={(e) => setMaxStock(e.target.value)}
          className="w-1/4 p-3 rounded-md border border-gray-300 focus:outline-none"
        />

        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
        <button
          onClick={handleReset}
          className="inline-flex items-center gap-2 bg-gray-300 text-gray-700 text-lg font-semibold py-3 px-6 hover:bg-gray-400 rounded-l-md"
        >
          <span>Reset</span>
          <span className="hidden md:block">
            <Icon icon="bi:arrow-counterclockwise" />
          </span>
        </button>
      </div>

      <div className="flex relative rounded-md w-full mt-3 mb-3">
        <input
          type="text"
          placeholder="Enter product name or category"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />

        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>
      <div className="mb-3">
        <span className="text-gray-700">
          Showing {filteredProducts.length} Results
        </span>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-full text-left rounded" cellSpacing="0">
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                No
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                Medicine Name
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                Current Stock
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                Date
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                Category
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium text-slate-700 bg-slate-100"
              >
                Company Name
              </th>
            </tr>
            {/* Map through the filtered products instead of all products */}
            {filteredProducts
              .slice()
              .reverse()
              .map((product, index) => (
                <tr key={product._id} className="shadow">
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {index + 1}
                  </td>

                  <td className="h-16 px-6 text-sm text-slate-500">
                    {product?.product?.name}
                  </td>

                  <td className="h-16 px-6 text-sm text-slate-500">
                    {product?.stock}
                  </td>
                  <td className="h-16 px-6 text-sm text-slate-500">
                    {product?.createdAt
                      ? new Date(product.createdAt).toLocaleDateString()
                      : ""}
                  </td>

                  <td className="h-16 px-6 text-sm text-slate-500">
                    {product?.product?.category}
                  </td>

                  <td className="h-16 px-6 text-sm text-slate-500">
                    {product?.product?.companyName}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SellerStockHistory;
